import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllCountries,
  EditCountry
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");

const Countries = () => {

    const profile=useSelector(getProfile)

    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [countries, setcountries] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
  
    const [name, setname] = useState("");
    const [currency, setcurrency] = useState("");
    const [imageName, setimageName] = useState("");
    const [feature, setfeature] = useState("");
    const [creators, setCreators] = useState({});
    const [toggle, settoggle] = useState(false);
    const [load, setload] = useState(false);

    const [seoTitle, setseoTitle] = useState("");
    const [seoTitleDescription, setseoTitleDescription] = useState("");
    const [description, setdescription] = useState("");
    const [longDescription, setlongDescription] = useState("");
    const [keywords, setkeywords] = useState("");
  
    const viewModal = async (item, type, feature) => {
      setIsOpen(true);
      console.log("feature", feature);
      console.log("type", type);
  
      if (type == "userDetail" && feature == "add") {
        setId();
        setname();
        setimageName();
        setfeature("add")
      }
      if (type == "userDetail" && feature == "edit") {
        setfeature("edit")
        setUserDetail(item);
        setId(item?._id);
        setseoTitle(item?.seoTitle)
        setseoTitleDescription(item?.seoTitleDescription)
        setdescription(item?.description)
        setlongDescription(item?.longDescription)
        setkeywords(item?.keywords)
      }
      if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    };
   
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const countryDelete = async (id) => {
      try {
        await dispatch((id)).unwrap();
        setIsOpen(false);
        try {
          Countries();
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const Countries = async () => {
      try {
        setload(true);
        setcountries(null);
        setFilteredData(null);
        const response = await dispatch(getAllCountries()).unwrap();
        console.log('responsessss', response?.data)
        setcountries(response?.data);
        setFilteredData(response?.data);
        setload(false);
  
       
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    useEffect(() => {
      Countries();
    }, []);
  
  
  
    const addandEditCountry = async (e) => {
      e.preventDefault();
      try {
        const data = new FormData();
        data.append("id", id);
        data.append("countryImage", imageName);
        data.append("seoTitle", seoTitle);
        data.append("seoTitleDescription", seoTitleDescription);
        data.append("description", description);
        data.append("longDescription", longDescription);
        data.append("keywords", keywords);
          await dispatch(EditCountry(data)).unwrap();
          setIsOpen(false);
          Countries();
          setId("") 
          setseoTitle("")
          setseoTitleDescription("")
          setdescription("")
          setlongDescription("")
          setkeywords("")
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
  
  
  
  
  console.log('Countries', countries)

  const [nameFilter, setnameFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = countries?.filter(item => {
      const category = item?.name.toLowerCase();

      return (
        (nameFilter === '' || category.includes(nameFilter.toLowerCase()))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };

  const resetFilter = () => {
    setFilteredData(countries)
    setnameFilter('')
    settoggle(false);
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ];

  const handleChange = (html) => {
    setlongDescription(html);
  };


  return (
    <>
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show cw-modal cw-modal-30`}
>

  <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
  {modalType == "userDetail"  && feature == "add" ? (
    <>
    </> ) : modalType == "userDetail" && feature == "edit" ? ( 
      <>
      <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Edit Country</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
              <div class="form-floating cw-input mb-3">
                <input type="text" disabled  onChange={(e)=>setname(e.target.value)} value={userDetail?.name} class="form-control" id="att-name" placeholder="Country Name" />
                <label for="att-name">Country Name</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="Country Flag" />
                <label for="att-file">Country Flag</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="text"  onChange={(e)=>setseoTitle(e.target.value)} value={seoTitle} class="form-control" id="att-name" placeholder="SEO Title" />
                <label for="att-name">SEO Title</label>
              </div>
              <div class="form-floating mb-3 cw-textarea">
                  <textarea class="form-control" placeholder="description" id="seo-description" onChange={(e) => setseoTitleDescription(e.target.value)} value={seoTitleDescription}
                    style={{ height: '100px' }}></textarea>
                  <label for="seo-description">SEO Description</label>
                </div>
              <div class="form-floating mb-3 cw-textarea">
                  <textarea class="form-control" placeholder="description" id="description" onChange={(e) => setdescription(e.target.value)} value={description}
                    style={{ height: '120px' }}></textarea>
                  <label for="description">Description</label>
                </div>
                <div class="mb-3 pb-5 cw-html-text-editor">
                  <p class="font-size-16 fw-semibold black-color mb-2">Long Description</p>
                  <ReactQuill
                    theme="snow"
                    value={longDescription}
                    onChange={handleChange}
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    style={{ height: '300px', marginBottom: '45px' }}
                  />
                </div>
                  <div class="form-floating mb-3 mt-3 cw-textarea">
                  <textarea class="form-control" placeholder="keywords" id="keywords" onChange={(e) => setkeywords(e.target.value)} maxLength={220} value={keywords}
                    style={{ height: '100px' }}></textarea>
                  <label for="keywords">Keywords</label>
                </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          
          <button onClick={addandEditCountry} class="btn cw-btn cw-yellow-btn py-2 px-4">Update</button>
          </div>
    </div>

      </>
      ): modalType == "delete" ? ( 
        <>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>
<div className="cw-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Countries</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center">
      <div className="col-md-3 col-6 align-self-center order-1">
        <h2 className="font-size-28 fw-bold mb-0">Countries</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown cw-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating cw-input mb-3">
              <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Country Name" autoComplete="off" />
              <label for="att-name">Country Name</label>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
              <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
    <p className="font-size-20 pink-color">Countries : {countries?.length}</p>
    <div class="table-responsive">
    <table class="table cw-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Country Name</th>
      <th scope="col">Icon</th>
      <th scope="col">Currency</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {load ? new Array(8).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item,i)=> (
    <tr key={i}>
      <td>{i+1}</td>
      <td>{item?.name}</td>
      <td><img src={`${process.env.REACT_APP_IMGURL}/${item?.flag}`} width={30} alt="" /></td>
      <td>{item?.currency}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            <div onClick={() => viewModal(item, "userDetail","edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>  
    </>
  )
}

export default Countries