import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { Camera, Check, ChevronsDown, Trash2, X } from "lucide-react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { EditSpaceDetail, SpaceDetail, addCityArea, addSpace, editSpace, getAllCountries, getAllSpaces, getCity, getCityArea, getCountry, getCountryCity, getCoworkingTypes, getFeatures, getRoomDeskTypes } from "../../store/slices/userSlice";
import Spinner from "../../components/Spinner";

const AddLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const l = useLocation();
  const path = l?.state;

  const [load, setload] = useState(false);
  const [singleLocation, setsingleLocation] = useState(true);
  const [countries, setcountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [areas, setareas] = useState([]);
  const [features, setfeatures] = useState(null);
  const [Types, setTypes] = useState(null);
  const [coworkingTypes, setcoworkingTypes] = useState(null);
  const [editLocation, seteditLocation] = useState(null);

  const [name, setname] = useState("");
  const [slug, setslug] = useState("");
  const [businessname, setbusinessname] = useState("");
  const [value, setvalue] = useState("");
  const [overview, setoverview] = useState("");
  const [type, settype] = useState("");

  const [contact_person, setcontact_person] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [website, setwebsite] = useState("");
  const [postCode, setpostCode] = useState("");
  const [address, setaddress] = useState("");
  const [currency, setcurrency] = useState("");
  const [country, setcountry] = useState("");
  const [mycities, setmycities] = useState("");
  const [area, setarea] = useState("");
  const [cityCountry, setcityCountry] = useState("");
  const [location, setlocation] = useState("");
  const [lat, setlat] = useState(0);
  const [long, setlong] = useState(0);
  const [spaceStartTime, setspaceStartTime] = useState("");
  const [spaceEndTime, setspaceEndTime] = useState("");
  const [operationDays, setoperationDays] = useState([]);
  const [is24Hours, setis24Hours] = useState("");
  const [daysType, setdaysType] = useState("");
  const [isTimeDisabled, setisTimeDisabled] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [instagram, setinstagram] = useState("");
  const [youtube, setyoutube] = useState("");
  const [tiktok, settiktok] = useState("")

  const [dp, setdp] = useState("")
  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);
  const [editImages, setEditImages] = useState([]);

  const [geocountry, setgeocountry] = useState("");
  const [geocity, setgeocity] = useState("");
  const [geoarea, setgeoarea] = useState("");
  const [geopostcode, setgeopostcode] = useState("");


  if (value) {
    // console.log('valueee', value);
    geocodeByPlaceId(value?.value?.place_id)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];
        const country = addressComponents.find(component => component.types.includes('country'))?.long_name;
        const city = addressComponents.find(component => component.types.includes('locality'))?.long_name;
        const area = addressComponents.find(component => component.types.includes('neighborhood'))?.long_name
          || addressComponents.find(component => component.types.includes('route'))?.long_name
          || addressComponents.find(component => component.types.includes('street_address'))?.long_name;
        const postcode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name;

        setgeocountry(country || "");
        setgeocity(city || "");
        setgeoarea(area || "");
        setgeopostcode(postcode || "");
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        // console.log('lat', lat);
        setlat(lat);
        setlong(lng);
        setlocation(value?.label);
      })
      .catch((error) => console.error(error));
  }


  const CoworkingType = async () => {
    try {
      setcoworkingTypes(null);
      const response = await dispatch(getCoworkingTypes()).unwrap();
      setcoworkingTypes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Cities = async (countryId) => {
    try {
      setcities(null);
      const response = await dispatch(getCountryCity(countryId)).unwrap();
      setcities(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Areas = async (cityId) => {
    try {
      setareas(null);
      const response = await dispatch(getCityArea(cityId)).unwrap();
      setareas(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (country) {
      Cities(country?.id);
    }
  }, [country]);

  useEffect(() => {
    if (mycities) {
      Areas(mycities);
    }
  }, [mycities]);

  const Type = async () => {
    try {
      setTypes(null);
      const response = await dispatch(getRoomDeskTypes()).unwrap();
      setTypes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleImageUpload = (event) => {
    const selectedImages = event.target.files;
    const imagesArray = [];
    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    setImages(images.concat(imagesArray));
  };

  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const mergeImages = () => {
    const mergedImages = [...prev_images, ...images];
    setEditImages(mergedImages);
  };

  const handleCheckboxChange = (text) => {
    const updatedCheckboxes = operationDays.includes(text)
      ? operationDays.filter((item) => item !== text)
      : [...operationDays, text];
      setdaysType("")
    setoperationDays(updatedCheckboxes);
  };

  const handleGroupSelection = (e) => {
    const selectedValue = e.target.value;
    let updatedDays = [];
    if (selectedValue === 'weekday') {
      updatedDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
      setdaysType("weekday")
    } else if (selectedValue === 'full') {
      updatedDays = [...daysOfWeek];
      setdaysType("full")
    }
    setoperationDays(updatedDays);
  };

  // const check24Hours = (e) => {
  //   e.preventDefault()
  //   if (is24Hours == 0) {
  //     setspaceStartTime("00:00")
  //     setspaceEndTime("23:59")
  //     setis24Hours(true)
  //     setisTimeDisabled(true);
  //   } else {
  //     setisTimeDisabled(false);
  //     setis24Hours(0)
  //     setspaceStartTime("")
  //     setspaceEndTime("")
  //   }
  // };

  const handleTimeSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === '24-hours') {
      setspaceStartTime("00:00");
      setspaceEndTime("23:59");
      setis24Hours('24-hours');
      setisTimeDisabled(true);
    } else if (selectedValue === '9-to-5-hours') {
      setspaceStartTime("09:00");
      setspaceEndTime("17:00");
      setis24Hours('9-to-5-hours');
      setisTimeDisabled(true);
    } else if (selectedValue === '9-to-9-hours') {
      setspaceStartTime("09:00");
      setspaceEndTime("21:00");
      setis24Hours('9-to-9-hours');
      setisTimeDisabled(true);
    } else if (selectedValue === '9-to-8-hours') {
      setspaceStartTime("09:00");
      setspaceEndTime("20:00");
      setis24Hours('9-to-8-hours');
      setisTimeDisabled(true);
    }else if (selectedValue === '9-to-6-hours') {
      setspaceStartTime("09:00");
      setspaceEndTime("18:00");
      setis24Hours('9-to-6-hours');
      setisTimeDisabled(true);
    }else if (selectedValue === '8-to-5-hours') {
      setspaceStartTime("08:00");
      setspaceEndTime("17:00");
      setis24Hours('8-to-5-hours');
      setisTimeDisabled(true);
    }else if (selectedValue === '8-to-6-hours') {
      setspaceStartTime("09:00");
      setspaceEndTime("18:00");
      setis24Hours('8-to-6-hours');
      setisTimeDisabled(true);
    } else {
      setspaceStartTime("");
      setspaceEndTime("");
      setis24Hours('');
      setisTimeDisabled(false);
    }
  };

  console.log(is24Hours, "is24Hours");
  
  

  const handleCheckboxChange2 = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setSelectedAmenities(prevSelectedAmenities => [...prevSelectedAmenities, id]);
    } else {
      setSelectedAmenities(prevSelectedAmenities => prevSelectedAmenities.filter(item => item !== id));
    }
  };

  const handleCheckboxChange3 = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setSelectedTypes(prevSelectedTypes => [...prevSelectedTypes, id]);
    } else {
      setSelectedTypes(prevSelectedTypes => prevSelectedTypes.filter(item => item !== id));
    }
  };

  const Features = async () => {
    try {
      setload(true);
      setfeatures(null);
      const response = await dispatch(getFeatures()).unwrap();
      setfeatures(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Countries();
    CoworkingType();
    Features();
    Type();
  }, []);

  const social = { "faceBook": facebook, "twitter": twitter, "instagram": instagram, "youtube": youtube, "tiktok": tiktok, "linkedIn": linkedin }

  const handleImageSelection = (selectedImage) => {
    if (!dp.includes(selectedImage)) {
      setdp(prevDP => [...prevDP, selectedImage]); // Add the selected image to the dp array
    } else {
      setdp(prevDP => prevDP.filter(image => image !== selectedImage)); // Remove the selected image from the dp array
    }
  };

  const saveBranch = async (e) => {
    try {
      setload(true);
      if (email) {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (!isValidEmail) {
          toast.error('Please enter a valid email address!');
          setload(false);
          return;
        }
      }
      if (!editLocation) {
        if (!businessname) {
          toast.error('Business Name is required');
          setload(false);
          return;
        }
        if (!type) {
          toast.error('Business Type is required');
          setload(false);
          return;
        }
      }
      if (!name) {
        toast.error('Space Name is required');
        setload(false);
        return;
      }
      if (!spaceStartTime) {
        toast.error('Space Start Time is required');
        setload(false);
        return;
      }
      if (!spaceEndTime) {
        toast.error('Space End Time is required');
        setload(false);
        return;
      }
      if (!operationDays.length == 1) {
        toast.error('Operation Days is required');
        setload(false);
        return;
      }
      if (!address) {
        toast.error('Address is required');
        setload(false);
        return;
      }
      if (!mycities) {
        toast.error('City is required');
        setload(false);
        return;
      }
      if (!country) {
        toast.error('Country is required');
        setload(false);
        return;
      }
      if (!area) {
        toast.error('Area is required');
        setload(false);
        return;
      }
      if (!phone) {
        toast.error('Phone is required');
        setload(false);
        return;
      }
      if (!selectedAmenities?.length == 1) {
        toast.error('Ameneties/Facilities is required');
        setload(false);
        return;
      }
      if (selectedTypes?.length === 0) {
        toast.error('Types is required');
        setload(false);
        return;
      }
      e.preventDefault();
      const data = new FormData();
      if (!editLocation) {
        if (path?._id) {
          data.append("businessId", path?._id);
        } else {
          data.append("businessName", businessname);
          data.append("businessType", type);
          data.append("currency", currency);
        }
      } else {
        data.append("spaceId", spaceId);
      }
      data.append("description", overview);
      data.append("name", name);
      data.append("slug", slug);
      data.append("spaceStartTime", spaceStartTime);
      data.append("spaceEndTime", spaceEndTime);
      data.append("days", JSON.stringify(operationDays));
      data.append("types", JSON.stringify(selectedTypes));
      data.append("facilities", JSON.stringify(selectedAmenities));
      data.append("social", JSON.stringify(social));
      data.append("address", address);
      data.append("lat", lat);
      data.append("long", long);
      data.append("spaceManagerName", contact_person);
      data.append("spacePhone", phone);
      data.append("spaceEmail", email);
      data.append("spaceMobile", whatsapp);
      data.append("website", website);
      data.append("postCode", postCode);
      data.append("country", country?._id);
      data.append("city", mycities);
      data.append("area", area);
      data.append("featureIndex", dp);
      data.append("prevImages", JSON.stringify(prev_images));
      data.append("singleLocation", singleLocation);
      images.forEach((item) => {
        data.append("spaceImage", item);
      });
      console.log('Form Data:', Object.fromEntries(data.entries()));
      if (editLocation) {
        await dispatch(editSpace(data)).unwrap();
        setload(false);
        navigate('/spaces')
      } else {
        await dispatch(addSpace(data)).unwrap();
        setload(false);
        navigate('/spaces')
      }
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    setcountry(countries?.find(user => user.name === geocountry) || '');
    setSearchValue(countries?.find(user => user.name === geocountry)?.name || '');
    setpostCode(geopostcode || '');
    setaddress(location || "");
  }, [geocountry, geocity, geoarea, geopostcode]);

  useEffect(() => {
    if (geocity) {
      const FindCity = cities?.find(user => user.name === geocity)
      setmycities(FindCity?._id)
      setSearchValue1(FindCity?.name)
      setcityCountry(FindCity)
    }
  }, [country, cities]);

  useEffect(() => {
    if (singleLocation) {
      setname(businessname)
    } else if (editLocation) {
      setname(editLocation?.name)
    } else {
      setname("")
    }
  }, [singleLocation, businessname, editLocation]);

  const generateSlug = (text) => {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters except hyphens
      .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ''); // Remove hyphens from the start and end
  };

  useEffect(() => {
    if (name) {
      const slugifiedName = generateSlug(name);
      // const slugifiedName = name.replace(/[^\w\s’']/gi, '-').replace(/’s\s*/gi, '').replace(/['"]/g, '').replace(/\s+/g, '-').toLowerCase();
      setslug(slugifiedName);
    }
  }, [name]);

  useEffect(() => {
    if (country) {
      const findCurrency = countries?.find(user => user._id === country?._id)?.currency;
      setcurrency(findCurrency);
    }
  }, [country]);

  useEffect(() => {
    if (images && images.length > 0) {
        setdp(0);
    }
  }, [images]);

  useEffect(() => {
    if (path) {
      setbusinessname(path.name);
      settype(path.type);
      setsingleLocation(false)
    }
  }, [path]);


  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];


  // console.log(cities, "cities");
  // console.log(operationDays, "operationDays");
  // console.log(name, "name");
  // console.log(selectedAmenities, "selectedAmenities");
  // console.log(whatsapp, "whatsapp");
  // console.log(dp, "dp");
  // console.log(images, "images");
  // console.log(prev_images, "prev_images");
  // console.log(editImages, "merge images");

  // console.log(path, "location state");
  // console.log(editLocation, "edit location");


  // console.log(country, "country");
  // console.log(mycities, "mycities");
  // console.log(slug, "slug");

  const handlePhoneChange = (e) => {
    let input = e.target.value;

    if (!input) {
      setphone('');
      return;
    }
    input = input.replace(/[^0-9]/g, '');
    const formattedPhone = input.length > 0 ? `+${input}` : '+';
    setphone(formattedPhone);
  };

  const urlParts = window.location.pathname.split('/');
  const spaceId = urlParts[urlParts.length - 1];


  const EditLocation = async () => {
    try {
      setload(true)
      seteditLocation(null);
      const response = await dispatch(EditSpaceDetail(spaceId)).unwrap();
      seteditLocation(response?.data[0]);
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    if (spaceId !== "add-space") {
      EditLocation();
    }
  }, [spaceId]);

  useEffect(() => {
    if (editLocation) {
      setbusinessname(editLocation?.businesses?.name);
      settype(editLocation?.businesses?.type);
      setname(editLocation?.name);
      setslug(editLocation?.slug);
      setoperationDays(editLocation?.days)
      setspaceStartTime(editLocation?.spaceStartTime)
      setspaceEndTime(editLocation?.spaceEndTime)
      setoverview(editLocation?.description)
      setaddress(editLocation?.location?.address)
      setarea(editLocation?.areas?._id)
      setSearchValue2(editLocation?.areas?.name)
      setmycities(editLocation?.cities?._id)
      setSearchValue1(editLocation?.cities?.name)
      setcityCountry(editLocation?.cities)
      setcountry(editLocation?.countries)
      setSearchValue(editLocation?.countries?.name)
      setpostCode(editLocation?.location?.postCode)
      setcontact_person(editLocation?.spaceManagerName)
      setwebsite(editLocation?.website)
      setemail(editLocation?.spaceEmail)
      setphone(editLocation?.spacePhone)
      setfacebook(editLocation?.social?.faceBook)
      settwitter(editLocation?.social?.twitter)
      setlinkedin(editLocation?.social?.linkedIn)
      setinstagram(editLocation?.social?.instagram)
      setyoutube(editLocation?.social?.youtube)
      settiktok(editLocation?.social?.tiktok)
      setSelectedAmenities(editLocation?.facilities)
      setSelectedTypes(editLocation?.types)
      setprev_images(editLocation?.image)
      setdp(editLocation?.featureImage)
      setsingleLocation(false)
      mergeImages();
    }
  }, [editLocation]);



  useEffect(() => {
    if (editLocation?.featureImage) {
      const foundIndex = prev_images?.findIndex(image => image === editLocation?.featureImage);
      if (foundIndex !== -1) {
        setdp(foundIndex);
      }
    }
  }, [editLocation, prev_images]);  

  useEffect(() => {
    if (editLocation) {
      document.title = editLocation?.name
    }
  }, [editLocation, location.pathname]);


  // console.log(countries, "countries");
  // console.log(country, "country selected");
  // console.log(type, "type");


  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const [showDropdown1, setShowDropdown1] = useState(false);
  const [searchValue1, setSearchValue1] = useState('');
  const [highlightedIndex1, setHighlightedIndex1] = useState(-1);

  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [searchValue2, setSearchValue2] = useState('');
  const [highlightedIndex2, setHighlightedIndex2] = useState(-1);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    setcountry(data);
    setShowDropdown(false);
  };

  const handleInputChange1 = (e) => {
    const value = e.target.value;
    setSearchValue1(value);
    if (value.length > 0) {
      setShowDropdown1(true);
    } else {
      setShowDropdown1(false);
    }
  };
  const handleOptionClick1 = (data) => {
    setcityCountry(data)
    setSearchValue1(data?.name);
    setmycities(data?._id);
    setShowDropdown1(false);
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setSearchValue2(value);
    if (value.length > 0) {
      setShowDropdown2(true);
    } else {
      setShowDropdown2(false);
    }
  };
  const handleOptionClick2 = (data) => {
    setSearchValue2(data?.name);
    setarea(data?._id);
    setShowDropdown2(false);
    setShowAdd(true)
  };

  const filteredCountries = countries?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const filteredCities = cities?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue1?.toLowerCase())
  );

  const filteredAreas = areas?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue2?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };

  const handleKeyDown1 = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex1((prevIndex) =>
        prevIndex < filteredCities.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex1((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex1 !== -1) {
        handleOptionClick1(filteredCities[highlightedIndex1]);
      }
    }
  };

  const handleKeyDown2 = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex2((prevIndex) =>
        prevIndex < filteredAreas.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex2((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex2 !== -1) {
        handleOptionClick1(filteredAreas[highlightedIndex2]);
      }
    }
  };

  useEffect(() => {
    const fetchLatLong = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyDq47rN8ypjHfiSdhcm9uG4ADKS_Vsvrug`
        );
        const data = await response.json();

        if (data.status === 'OK') {
          const location = data.results[0].geometry.location;
          setlat(location.lat);
          setlong(location.lng);
        } else {
          console.error('Geocoding API error:', data.status);
        }
      } catch (error) {
        console.error('Error fetching geocoding data:', error);
      }
    };

    if (address && lat === 0 && long === 0) {
      fetchLatLong();
    }
  }, [address]);

  console.log(lat, long, "lat-long");
  console.log(country, "country");
  console.log(area, "selectedarea");
  console.log(areas, "areas");
  console.log(cityCountry, "cityCountry");
  console.log(filteredAreas, "filteredAreas");
  console.log(type, "Coworkingtype");
  console.log(slug, "slug");


  useEffect(() => {
    if (country?.name !== cityCountry?.country_name) {
      setcityCountry("");
      setSearchValue1("");
      setmycities("");
      setarea("");
      setSearchValue2("")
    } else{
      
    }
  }, [country, cityCountry]);

  useEffect(() => {
    if (!editLocation && coworkingTypes) {
      const CoworkingName = "Coworking Office";
      const matchingCoworkingType = coworkingTypes?.find(type => type?.name === CoworkingName)?._id;
      settype(matchingCoworkingType);
    }
  }, [coworkingTypes]);

  const [compulsary, setcompulsary] = useState(false);

  useEffect(() => {
    if (compulsary) {
      const typesArray = ["Dedicated Desks", "Meeting Room", "Private Office"]
      const featureArray = ["Collaboration areas", "Lounge areas", "Ergonomic chairs", "Standing desks", "Wi-Fi", "Printing", "Scanning", "Charging stations", "Tech support services", "Cleaning services", "Air conditioning", "On-site cafe or kitchenette", "Receptionist or front desk services"]

      const selectedTypeIds = typesArray?.map(typeName => {
        const matchingType = Types?.find(type => type?.name === typeName)
        return matchingType ? matchingType._id : null
      })
  
      const selectedFeatureIds = featureArray.map(featureName => {
        const matchingFeature = features?.find(feature => feature?.name === featureName)
        return matchingFeature ? matchingFeature._id : null
      })

      // console.log(selectedTypeIds, "selectedTypeIds");
      // console.log(selectedFeatureIds, "selectedFeatureIds");
      
  
      setSelectedTypes(selectedTypeIds)
      setSelectedAmenities(selectedFeatureIds)
    }
  }, [compulsary]);

  const addArea = async () => {
    try {
      const payload = {
        name: searchValue2,
        cityId: mycities,
      }
      const response = await dispatch(addCityArea(payload)).unwrap();
      setSearchValue2(response?.data?.name)
      setarea(response?.data?._id)
      setShowDropdown2(false);
      setShowAdd(true)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
    <>
      {load && <Spinner />}
      <div className="cw-attributes-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Spaces</a></li>
            <li class="breadcrumb-item active" aria-current="page">{editLocation ? "Edit Space" : "Add Space"}</li>
          </ol>
        </nav>
        <h2 className="font-size-28 fw-bold mb-3">{editLocation ? "Edit Space" : "Add Space"}</h2>
        <div className="cw-radius-20 white-bg cw-shadow p-3 mb-4">
          <div className="row gy-3 mb-3">
            <div className="col-md-12">
              <div class="form-floating cw-input">
                <input type="text" onChange={(e) => { setbusinessname(e.target.value); }} disabled={path || editLocation} value={businessname} maxLength={50} class="form-control" id="business-name" placeholder="Business Name" required />
                <label for="business-name">Business Name</label>
              </div>
            </div>
            {/* <div className={`col-md-12 ${!path?.type ? "" : "d-none"} ${editLocation ? "d-none" : ""}`}>
              <p class="font-size-16 fw-semibold black-color mb-2">Type</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {coworkingTypes?.map((s) => (
                  <>
                    <input class="btn-check" name="types-of-service" type="radio" checked={type?.includes(s?._id)} onChange={() => settype(s?._id)} id={s?._id} value={s?._id} />
                    <label class="btn cw-select-btn" for={s?._id}>{s?.name}</label>
                  </>
                ))}
              </div>
            </div>  */}
              <div className={`col-md-12 ${!path?.type ? "" : "d-none"} ${editLocation ? "d-none" : ""}`}>
                <p class="font-size-16 fw-semibold black-color mb-2">Locations</p>
                <div className="d-flex flex-wrap gap-2 mb-3">
                  <input class="btn-check" name="locations-" type="radio" onChange={() => setsingleLocation(true)} id="single-location" value="single-location" />
                  <label class="btn cw-select-btn" for="single-location">Single Location</label>

                  <input class="btn-check" name="locations-" type="radio" onChange={() => setsingleLocation(false)} checked={singleLocation === false} id="Multiple-location" value="Multiple-location" />
                  <label class="btn cw-select-btn" for="Multiple-location">Multiple Location</label>
                </div>
              </div> 
            {!singleLocation && <div className="col-md-12">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" id="location-name" value={name} onChange={(e) => setname(e.target.value)} placeholder="location Name" />
                <label for="location-name">Location Name</label>
              </div>
            </div>}

            <div className="col-md-12">
              <p class="font-size-16 fw-semibold black-color mb-2">Opening Timings</p>
              <div className="row">
                <div className="col-md-5">
                  <div className="d-flex flex-wrap gap-2 mb-3">
                    {daysOfWeek.map((data) => (
                      <>
                        <input class="btn-check" name="opening-days" checked={operationDays?.includes(data)} onChange={() => handleCheckboxChange(data)} type="checkbox" id={data} value={data} />
                        <label class="btn cw-select-btn" for={data}>{data}</label>
                      </>
                    ))}
                    <input class="btn-check" name="opening-days" checked={daysType === "weekday"} onChange={handleGroupSelection} type="checkbox" id="weekday" value="weekday" />
                    <label class="btn cw-select-btn" for="weekday">Weekday</label>
                    <input class="btn-check" name="opening-days" checked={daysType === "full"} onChange={handleGroupSelection} type="checkbox" id="fullweek" value="full" />
                    <label class="btn cw-select-btn" for="fullweek">Full Week</label>
                  </div>
                </div>
                <div className="col-md-3 d-flex gap-1">
                  <div class="form-floating cw-input flex-grow-1">
                    <input type="time" class="form-control" id="start-time" value={spaceStartTime} onChange={(e) => setspaceStartTime(e.target.value)} disabled={isTimeDisabled} placeholder="Start Time" />
                    <label for="start-time">Start Time</label>
                  </div>
                  <div class="form-floating cw-input flex-grow-1">
                    <input type="time" class="form-control" id="End-time" disabled={isTimeDisabled} onChange={(e) => setspaceEndTime(e.target.value)} value={spaceEndTime} placeholder="End Time" />
                    <label for="End-time">End Time</label>
                  </div>
                </div>
                <div className="col-md-4 align-self-center">
                  <div className="form-check form-check-inline cw-checkbox">
                    <input className="form-check-input" type="radio" name="time-select" id="24-hours" checked={is24Hours === '24-hours'} onChange={handleTimeSelectChange} value="24-hours"/>
                    <label className="form-check-label" htmlFor="24-hours">24 Hours</label>
                  </div>
                  <div className="form-check form-check-inline cw-checkbox">
                    <input className="form-check-input" type="radio" name="time-select" id="8-to-5-hours" checked={is24Hours === '8-to-5-hours'} onChange={handleTimeSelectChange} value="8-to-5-hours"/>
                    <label className="form-check-label" htmlFor="8-to-5-hours">8 to 5</label>
                  </div>
                  <div className="form-check form-check-inline cw-checkbox">
                    <input className="form-check-input" type="radio" name="time-select" id="8-to-6-hours" checked={is24Hours === '8-to-6-hours'} onChange={handleTimeSelectChange} value="8-to-6-hours"/>
                    <label className="form-check-label" htmlFor="8-to-6-hours">8 to 6</label>
                  </div>
                  <div className="form-check form-check-inline cw-checkbox">
                    <input className="form-check-input" type="radio" name="time-select" id="9-to-5-hours" checked={is24Hours === '9-to-5-hours'} onChange={handleTimeSelectChange} value="9-to-5-hours"/>
                    <label className="form-check-label" htmlFor="9-to-5-hours">9 to 5</label>
                  </div>
                  <div className="form-check form-check-inline cw-checkbox">
                    <input className="form-check-input" type="radio" name="time-select" id="9-to-6-hours" checked={is24Hours === '9-to-6-hours'} onChange={handleTimeSelectChange} value="9-to-6-hours"/>
                    <label className="form-check-label" htmlFor="9-to-6-hours">9 to 6</label>
                  </div>
                  <div className="form-check form-check-inline cw-checkbox">
                    <input className="form-check-input" type="radio" name="time-select" id="9-to-8-hours" checked={is24Hours === '9-to-8-hours'} onChange={handleTimeSelectChange} value="9-to-8-hours"/>
                    <label className="form-check-label" htmlFor="9-to-8-hours">9 to 8</label>
                  </div>
                  <div className="form-check form-check-inline cw-checkbox">
                    <input className="form-check-input" type="radio" name="time-select" id="9-to-9-hours" checked={is24Hours === '9-to-9-hours'} onChange={handleTimeSelectChange} value="9-to-9-hours"/>
                    <label className="form-check-label" htmlFor="9-to-9-hours">9 to 9</label>
                  </div>
                  <div className="form-check form-check-inline cw-checkbox">
                    <input className="form-check-input" type="radio" name="time-select" id="none" checked={is24Hours === ''} onChange={handleTimeSelectChange} value="none"/>
                    <label className="form-check-label" htmlFor="none">None</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div class="form-floating cw-textarea">
                <textarea class="form-control" placeholder="Overview" id="Overview" onChange={(e) => setoverview(e.target.value.replace(/\r?\n/g, '<br>'))} value={overview} style={{ height: '200px' }}></textarea>
                <label for="Overview">Overview</label>
              </div>
            </div>
          </div>

          <p class="font-size-16 black-color fw-semibold mb-2">Address</p>
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="cw-shadow rounded  mb-3">
                <GooglePlacesAutocomplete apiKey={process.env.REACT_GOOGLE_MAPS_API_KEY}
                  selectProps={{ value, onChange: setvalue, placeholder: editLocation ? address : "Enter your location", }} />
              </div>
              <div class="form-floating mb-3 cw-textarea">
                <textarea class="form-control" placeholder="Address" id="Address" onChange={(e) => setaddress(e.target.value)} maxLength={220} value={address}
                  style={{ height: '158px' }}></textarea>
                <label for="Address">Address</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="">
                <div class="form-floating cw-input">
                  <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue} id="country" placeholder="country" autoComplete="off" />
                  <label for="country">Country</label>
                </div>
                {showDropdown && (
                  <div className="dropdown cw-select-dropdown">
                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                      {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                        <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                      ))}
                      {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                        <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              {country && (
                <div className="">
                  <div class="form-floating cw-input mt-3">
                    <input type="text" class="form-control" onKeyDown={handleKeyDown1} maxLength={120} onChange={(e) => handleInputChange1(e)} value={searchValue1} id="city" placeholder="city" autoComplete="off" />
                    <label for="city">City</label>
                  </div>
                  {showDropdown1 && (
                    <div className="dropdown cw-select-dropdown">
                      <ul className="dropdown-menu show" style={{ width: '100%' }}>
                        {cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.map((data, index) => (
                          <li key={index} onClick={() => handleOptionClick1(data)} className={highlightedIndex1 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                        ))}
                        {cities?.length > 0 && cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.length === 0 && (
                          <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {mycities && (
                <div className="mt-3">
                  <div className="d-flex gap-1">
                  <div class="form-floating cw-input flex-grow-1">
                    <input type="text" class="form-control" onKeyDown={handleKeyDown2} maxLength={120} onChange={(e) => handleInputChange2(e)} value={searchValue2} id="area" placeholder="area" autoComplete="off" />
                    <label for="area">Area</label>
                  </div>
                  {/* {!showAdd && !area && filteredAreas?.length > 0 && (
                  <button className="btn cw-btn cw-yellow-btn" onClick={addArea}>Add</button>
                  )} */}
                  {!showAdd && !area && filteredAreas?.length === 0 && (
                    <button className="btn cw-btn cw-yellow-btn" onClick={addArea}>Add</button>
                  )}
                  </div>
                  {showDropdown2 && (
                    <div className="dropdown cw-select-dropdown">
                      <ul className="dropdown-menu show" style={{ width: '100%' }}>
                        {areas?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.map((data, index) => (
                          <li key={index} onClick={() => handleOptionClick2(data)} className={highlightedIndex2 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                        ))}
                        {areas?.length > 0 && areas?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.length === 0 && (
                          <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              <div class="form-floating cw-input mt-3">
                <input type="text" class="form-control" onChange={(e) => setpostCode(e.target.value)} value={postCode} maxLength={9} id="postcode" placeholder="PostCode" />
                <label for="postcode">Postcode</label>
              </div>
            </div>

          </div>

          <p class="font-size-16 black-color fw-semibold mb-2">Contact Details</p>
          <div className="row gy-3 mb-3">
            <div className="col-md-3">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" id="Contact-Person" onChange={(e) => setcontact_person(e.target.value)} maxLength={40} value={contact_person} placeholder="Contact Person" />
                <label for="Contact-Person">Contact Person</label>
              </div>
            </div>
            <div className="col-md-3">
              <div class="form-floating cw-input">
                <input type="url" class="form-control" id="Website" placeholder="Website" value={website}  onChange={(e) => setwebsite(e.target.value)} />
                <label for="Website">Website</label>
              </div>
            </div>
            <div className="col-md-3">
              <div class="form-floating cw-input">
                <input type="email" class="form-control" id="Email" placeholder="Email" onChange={(e) => setemail(e.target.value)} maxLength={320} value={email} />
                <label for="Email">Email</label>
              </div>
            </div>
            <div className="col-md-3 d-flex gap-2 align-items-center">
              <div class="form-floating cw-input w-100">
                <input type="tel" class="form-control" id="phone" placeholder="phone" value={phone} onChange={handlePhoneChange} />
                <label for="phone">Phone</label>
              </div>
              <div class="form-check form-check-inline cw-checkbox">
                <input onChange={(e) => setwhatsapp(e.target.checked ? phone : "")} class="form-check-input" type="checkbox" id="whatsapp" value="whatsapp" />
                <label class="form-check-label" for="whatsapp">
                  Whatsapp
                </label>
              </div>
            </div>

          </div>

          <p class="font-size-16 black-color fw-semibold mb-2">Amenities / Facilities</p>
          {!editLocation && <div className="mb-3">
          <div className="form-check form-check-inline cw-checkbox">
            <input className="form-check-input" type="checkbox" name="check-compusary" id="select-compusary-fields" checked={compulsary} onChange={()=> setcompulsary(!compulsary)}/>
            <label className="form-check-label" htmlFor="select-compusary-fields">Select Compulsary</label>
          </div>
          </div>}
          <p className="font-size-16 fw-semibold black-color mb-2">Facilities</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {Types?.map((data, i) => (
                  <>
                    <input className="btn-check" name="ameneties" type="checkbox" value={data?._id} checked={selectedTypes?.includes(data?._id)} onChange={handleCheckboxChange3} id={data?._id} />
                    <label className="btn cw-select-btn" htmlFor={data?._id}>{data?.name}</label>
                  </>
                ))}
              </div>
          {[...new Set(features?.map(option => option?.featureCategoryId?.name))]?.map(category => (
            <>
              <p className="font-size-16 fw-semibold black-color mb-2">{category}</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {features?.filter(option => option.featureCategoryId?.name === category)?.map(option => (
                  <React.Fragment key={option?.id}>
                    <input className="btn-check" name="ameneties" type="checkbox" value={option?._id} checked={selectedAmenities?.includes(option?._id)} onChange={handleCheckboxChange2} id={option?._id} />
                    <label className="btn cw-select-btn" htmlFor={option?._id}>{option?.name}</label>
                  </React.Fragment>
                ))}
              </div>
            </>
          ))}


          <p class="font-size-16 black-color fw-semibold mb-2">Social Network</p>
          <div className="row gy-3 mb-3">
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" id="Facebook" placeholder="Facebook" defaultValue={facebook} maxLength={50} onChange={(e) => setfacebook(e.target.value)} />
                <label for="Facebook">Facebook</label>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" id="X" placeholder="X" defaultValue={twitter} maxLength={50} onChange={(e) => settwitter(e.target.value)} />
                <label for="X">X</label>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" id="LinkedIn" placeholder="LinkedIn" defaultValue={linkedin} maxLength={50} onChange={(e) => setlinkedin(e.target.value)} />
                <label for="LinkedIn">LinkedIn</label>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" id="Instagram" placeholder="Instagram" defaultValue={instagram} maxLength={50} onChange={(e) => setinstagram(e.target.value)} />
                <label for="Instagram">Instagram</label>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" id="Youtube" placeholder="Youtube" defaultValue={youtube} maxLength={50} onChange={(e) => setyoutube(e.target.value)} />
                <label for="Youtube">Youtube</label>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" id="tiktok" placeholder="tiktok" defaultValue={tiktok} maxLength={50} onChange={(e) => settiktok(e.target.value)} />
                <label for="tiktok">Tiktok</label>
              </div>
            </div>
          </div>



          <p class="font-size-16 black-color fw-semibold mb-2">Photos & Videos</p>

          <div className="upload__img-wrap mb-3">
            {prev_images?.length > 0 && prev_images.map((image, index) => (
              <div className="upload__img-box" key={index}>
                <div className="img-bg">
                  <img src={`https://res.cloudinary.com/dayfv4et9/image/upload/w_500/q_auto/f_auto/${image}`} alt={`Store Image`} className="img-bg-size" />
                  <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                  <div className="upload__img-profile">
                    <div class="dropdown">
                      <a class="">
                        <ChevronsDown />
                      </a>
                      <ul class="dropdown-menu">
                        <div class="form-check form-check-inline cw-checkbox">
                          <input checked={dp === index} onChange={() => setdp(index)} class="form-check-input" type="radio" name="dp" id="dp" />
                          <label class="form-check-label" for="dp">Featured Image</label>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {images?.length > 0 && images.map((image, index) => (
              <div className="upload__img-box" key={index}>
                <div className="img-bg">
                  <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                  <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                  <div className="upload__img-profile">
                    <div class="dropdown">
                      <a class="">
                        <ChevronsDown />
                      </a>
                      <ul class="dropdown-menu">
                        <div class="form-check form-check-inline cw-checkbox">
                          <input checked={dp === index + prev_images.length} onChange={() => setdp(index + prev_images.length)} class="form-check-input" type="radio" name="dp" id="dp" />
                          <label class="form-check-label" for="dp">Featured Image</label>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <input type="file" class="btn-check" id="btn-check" multiple data-max_length="20" onChange={handleImageUpload} accept=".png, .jpg, .jpeg, .webp" />
            <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> Add Photos</label>
          </div>


        </div>

        <div class="d-flex justify-content-center gap-2">
          <button onClick={() => navigate("/spaces")} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
          <button onClick={saveBranch} class="btn cw-btn cw-yellow-btn py-2 px-4" disabled={load}>Submit</button>
        </div>

      </div>
    </>
  )
}

export default AddLocation