import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllUsers,
  deleteAccount,
  getCustomerUsers
} from "../../store/slices/userSlice";
import moment from "moment"
import Modal from "react-modal";
import { Eye, Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const UserCustomers = () => {
  const profile=useSelector(getProfile)


  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [users, setusers] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [load, setload] = useState(false);
  // const [name, setname] = useState("");
  // const [imageName, setimageName] = useState("");


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const userDelete = async (id) => {
    try {
      await dispatch(deleteAccount({id: id})).unwrap();
      setIsOpen(false);
      try {
        Users();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Users = async () => {
    try {
      setload(true);
      setusers(null);
      setFilteredData(null);
      const response = await dispatch(getCustomerUsers()).unwrap();
      console.log('responsessss', response?.data)
      // setusers(response?.data);
      // setFilteredData(response?.data);
      if (response?.data) {
        // Assuming the data has a 'createdAt' field to sort by
        const sortedData = response?.data?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
        setusers(sortedData);
        setFilteredData(sortedData);
    }
      setload(false);
     
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Users();
    }
    return () => {
      mount = false;
    };
  }, []);


  const [nameFilter, setnameFilter] = useState('');
  const [emailFilter, setemailFilter] = useState('');
  const [googleSignupCheck, setgoogleSignupCheck] = useState(false);

  const applyFilter = () => {
    const filteredResult = users?.filter(item => {

      const name = item?.name?.toLowerCase();
      const email = item?.email?.toLowerCase();

      return (
        (nameFilter === '' || name?.toLowerCase()?.includes(nameFilter?.toLowerCase())) && 
        (emailFilter === '' || email?.toLowerCase()?.includes(emailFilter?.toLowerCase())) &&
        (!googleSignupCheck || item?.user_social_type === "google.com")
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };


  const resetFilter = () => {
    setemailFilter('');
    setnameFilter('');
    setgoogleSignupCheck(false)
    setFilteredData(users)
    settoggle(false);
  };


  return (
    <>

<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
className={`modal fade show cw-modal cw-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
        {modalType == "userDetail" ? (
    <>
            <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Customer Detail</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
              <p class="font-size-16 black-color fw-bold mb-0">Name</p>
                <p class="font-size-16 black-color">{userDetail?.name}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Email</p>
                <p class="font-size-16 black-color">{userDetail?.email}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Phone</p>
                <p class="font-size-16 black-color">{userDetail?.phone}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Role</p>
                <p class="font-size-16 black-color">{userDetail?.role}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Status</p>
                <p class="font-size-16 black-color">{userDetail?.status}</p>
              </div>
            </div>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete User</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this User?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn-2 cw-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
                <button onClick={() => userDelete(id)} class="btn cw-btn-2 cw-pink-btn py-2 px-4 rounded-2">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>

<div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Customer Users</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Customer Users</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown cw-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating cw-input mb-3">
              <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="user-name" placeholder="user Name" />
              <label for="user-name">Name</label>
            </div>
          <div class="form-floating cw-input mb-3">
              <input type="email" onChange={(e)=>setemailFilter(e.target.value)} value={emailFilter} class="form-control" id="user-email" placeholder="User Email" />
              <label for="user-email">Email</label>
            </div>
            <div class="mb-3 sp-input-filter">
              <p class="font-size-20 fw-semibold mb-2">Check Filter</p>
              <div class="d-flex gap-2 flex-wrap">
                <input type="checkbox" class="btn-check" id="google-check" checked={googleSignupCheck} onChange={() => setgoogleSignupCheck(!googleSignupCheck)} />
                <label class="btn cw-select-btn" for="google-check">Google</label>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
              <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Customer Users : {filteredData?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Role</th>
              <th style={{ width: '140px' }} scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.email?.substring(0,30)}</td>
                <td>{item?.phone ? `+${item?.phone}` : ""}</td>
                <td>{item?.role}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                  <div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons`}><Eye width="18" /></div>
                    {profile?.system_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons`}><Trash2 width="18" /></div>)}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
  )
}

export default UserCustomers