import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import {  Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLogout, getProfile, signOut } from "../../store/slices/userSlice"
import Modal from 'react-modal';
import { ChevronDown } from 'lucide-react';
import ChangePassword from '../ChangePassword';

const customStyles = {
  content: {
    display: "block",
    '--bs-modal-width': '30%',
  },
};
Modal.setAppElement('#root');


const Nav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  
  if (isSidebarOpen) {
    document.body.classList.remove('active');
  } else {
    document.body.classList.add('active');
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleLogout=async()=>{
    try {
        await dispatch(signOut()).unwrap()
        navigate("/")
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError)
      }
  }

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
    <header className={`${isScrolled ? 'cw-navbar white-bg' : 'cw-navbar'}`}>
        <div className="row px-2 gy-4">
          <div className="col-md-7 d-md-flex align-items-center gap-5">
          <img onClick={() => navigate("/dashboard")} className="cw-logo cursor-pointer" width="300" src={`../assets/images/logo.svg`} />
            <p className="font-size-24 black-color mb-0 fw-medium">Greetings, <span className="cw-text-highlight-navbar fw-bold">{profile?.name}</span></p>
          </div>
          <div className="col-md-5 d-flex align-items-center justify-content-md-end justify-content-center" style={{ "gap": "25px" }}>
            <Link to="/chat"><img class="" width="25" alt="messages" src={`${process.env.REACT_APP_FRONTURL}/assets/images/notification-icon-cw.svg`} /></Link>
            <div class="d-flex align-items-center gap-3 cw-profile-img">
              <img class="rounded-circle cw-shadow" alt="avatar" src={profile?.imageName?`${process.env.REACT_APP_IMGURL}/${profile?.imageName}`:"/logo192.png"} />
              <div>
                <p className="font-size-16 mb-0 fw-semibold">{profile?.name}</p>
              <p className="font-size-12 mb-0 fw-semibold text-capitalize">{profile?.role}</p>
              </div>
            </div>
            <div class="dropdown cw-nav-dropdown-link">
              <a class="yellow-color" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <ChevronDown />
              </a>
              <ul class="dropdown-menu">
                <li><a onClick={openModal} class="dropdown-item">Change Password</a></li>
                <li><a onClick={handleLogout} class="dropdown-item">Logout</a></li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Change Password"
        className="modal fade show cw-modal cw-modal-30"
      >
        <ChangePassword closeModal={closeModal} />
      </Modal>

      </>
  )
}

export default Nav