import React, { useMemo, useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import { addBlogs, editBlogCategory, editBlogs, getAllCountries, getBlogCategory, getBlogs } from "../../store/slices/userSlice";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setload] = useState(false);


  const [id, setId] = useState("");
  const [categories, setcategories] = useState(null);
  const [blogcategories, setblogcategories] = useState(null);
  const [countries, setcountries] = useState([]);
  const [publishdate, setpublishdate] = useState("");
  const [title, settitle] = useState("");
  const [excerpt, setexcerpt] = useState("");
  const [blogcategory, setblogcategory] = useState("");
  const [author, setauthor] = useState("The Coworking Spaces");
  const [image, setimage] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [description, setdescription] = useState("");
  const [slug, setslug] = useState("");
  const [country, setcountry] = useState(null);
  const [abc, setabc] = useState(false);

  const handleChange = (html) => {
    setdescription(html);
  };



  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const BlogCategories = async () => {
    try {
      setblogcategories(null);
      const response = await dispatch(getBlogCategory()).unwrap();
      setblogcategories(response?.data);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  // const setData=async()=>{
  //   if(location?.state?._id){

  //     console.log('location.state._id', location.state._id)

  //     const data= await dispatch(({id: location?.state?._id})).unwrap();


  //     setId(data?.data?._id)
  //     settitle(data?.data?.title)
  //     setpublishdate(data?.data?.publishdate)
  //     setblogcategory(data?.data?.blog_category_id?._id)
  //     setexcerpt(data?.data?.excerpt)
  //     setauthor(data?.data?.author)
  //     setdescription(data?.data?.description)
  //     setimage(data?.data?.image)
  //   }
  //   else{
  //     setId("")
  //     settitle("")
  //     setpublishdate("")
  //     setblogcategory("")
  //     setexcerpt("")
  //     setauthor("")
  //     setdescription("")
  //     setimage("")
  //   }
  // }

  useEffect(() => {
    let mount = true;
    if (mount) {
      BlogCategories();
      Countries();
    }
    // setData()
    return () => {
      mount = false;
    };
  }, []);

  //     const { QuillImageDropAndPaste } = require('quill-image-drop-and-paste');
  // Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

  const generateSlug = (text) => {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters except hyphens
      .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ''); // Remove hyphens from the start and end
  };

  useEffect(() => {
    if (title && !editBlog) {
      const slugifiedName = generateSlug(title);
      setslug(slugifiedName);
    }
  }, [title]);


  // console.log('slug', slug)
  // console.log('blogcategories', blogcategories)
  // console.log('blogcategory', blogcategory)

  const addandEditBlog = async (e) => {
    e.preventDefault();

    try {
      setload(true);
      if (!title) {
        toast.error('Blog Title is required');
        setload(false);
        return;
      }
      if (!excerpt) {
        toast.error('Excerpt is required');
        setload(false);
        return;
      }
      if (!blogcategory) {
        toast.error('Category is required');
        setload(false);
        return;
      }
      if (!image) {
        toast.error('Feature Image is required');
        setload(false);
        return;
      }
      if (!description) {
        toast.error('Blog Content is required');
        setload(false);
        return;
      }
      const formData = new FormData();
      formData.append("title", title)
      formData.append("excerpt", excerpt)
      formData.append("categoryId", blogcategory)
      formData.append("publishdate", publishdate)
      formData.append("author", author ? author : "The CoWorking Spaces")
      formData.append("blogImage", image)
      formData.append("description", description)
      formData.append("slug", slug)
      if(country){
        formData.append("countryId", country)
      }

      if (editBlog) {
        formData.append("id", blogId)
        await dispatch(editBlogs(formData)).unwrap();
        setload(false);
        navigate('/blogs')

      } else {
        await dispatch(addBlogs(formData)).unwrap();
        setload(false);
        navigate('/blogs')
      }
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log(description, "description");

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ];

  const [editBlog, seteditBlog] = useState(null);


  const urlParts = window.location.pathname.split('/');
  const blogId = urlParts[urlParts.length - 1];

  const EditBlog = async () => {
    try {
      setload(true);
      seteditBlog(null);
      const response = await dispatch(getBlogs()).unwrap();
      const blog = response?.data.find(blog => blog?._id === blogId);
      seteditBlog(blog)
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };




  useEffect(() => {
    if(blogId !== "add-blog"){
      EditBlog();
    }
  }, [])

  useEffect(() => {
    if (editBlog) {
      settitle(editBlog?.title);
      setpublishdate(editBlog?.publishdate);
      setblogcategory(editBlog?.categoryId?._id);
      setexcerpt(editBlog?.excerpt);
      setauthor(editBlog?.author);
      setdescription(editBlog?.description);
      setimage(editBlog?.image);
      setslug(editBlog?.slug);
      setcountry(editBlog?.countryId)
      if(editBlog?.countryId){
        setSelectedOption("No")
        const FindCountry = countries?.find(item => item?._id === editBlog?.countryId)
        setSearchValue2(FindCountry?.name)
      }
    }
  }, [editBlog, location.pathname]);


  useEffect(() => {
    if (editBlog) {
      document.title = editBlog?.title
    }

  }, [editBlog, location.pathname]);

  console.log(editBlog, "editBlog");
  console.log(blogId, "blogId");
  // console.log(slug, "slug");

  const [showDropdown2, setShowDropdown2] = useState(false);
  const [searchValue2, setSearchValue2] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue2(value);
    if (value.length > 0) {
      setShowDropdown2(true);
    } else {
      setShowDropdown2(false);
    }
  };
  const handleOptionClick = (data) => {
    setSearchValue2(data?.name);
    setcountry(data?._id);
    setShowDropdown2(false);
  };

  const filteredCountries = countries?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };


  const [selectedOption, setSelectedOption] = useState('Yes');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`You selected: ${selectedOption}`);
  };

  console.log(selectedOption)

  return (
    <>
      {load && <Spinner />}
      <div className="cw-attributes-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Blogs</a></li>
            <li class="breadcrumb-item active" aria-current="page">{editBlog ? "Edit" : "Add"} Blog</li>
          </ol>
        </nav>
        <h2 className="font-size-28 fw-bold mb-3">{editBlog ? "Edit" : "Add"} Blog</h2>
        <div className="cw-radius-20 white-bg cw-shadow p-3 mb-4">
          <div class="form-floating cw-input mb-3">
            <input type="text" value={title} onChange={(e) => settitle(e.target.value)} class="form-control" id="BLOG-NAME" placeholder="Blog Title" />
            <label for="BLOG-NAME">Blog Title</label>
          </div>
          {editBlog &&
          <div class="form-floating cw-input mb-3">
            <input type="text" value={slug} onChange={(e) => setslug(e.target.value)} class="form-control" id="blog-slug" placeholder="Blog Slug" />
            <label for="blog-slug">Blog Slug</label>
          </div>}
          <div class="form-floating mb-3 cw-textarea">
            <textarea class="form-control" placeholder="Excerpt" id="Excerpt" defaultValue={excerpt} onChange={(e) => setexcerpt(e.target.value)} style={{ height: '100px' }}></textarea>
            <label for="Excerpt">Excerpt</label>
          </div>
          <div className="row gy-3 mb-3">
            <div className="col-md-3">
              <div class="dropdown cw-select-dropdown">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {blogcategory ? blogcategories?.find(user => user?._id === blogcategory)?.name : "Blog Category"}
                </a>
                <ul class="dropdown-menu">
                  {blogcategories?.map((c, i) => (
                    <li key={i}><a onClick={(e) => setblogcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div class="form-floating cw-input">
                <input type="file" onChange={(e) => { setimage(e.target.files[0]); setabc(true); }} class="form-control" id="BLOG-image" placeholder="Featured Image" />
                <label for="BLOG-image">Featured Image</label>
              </div>
            </div>
            <div className="col-md-3">
              <div class="form-floating cw-input">
                <input type="date" min={getCurrentDate()} value={publishdate} onChange={(e) => setpublishdate(e.target.value)} class="form-control" id="published-date" placeholder="Publish Date" />
                <label for="published-date">Publish Date</label>
              </div>
            </div>
            <div className="col-md-3">
            <select value={selectedOption} onChange={handleOptionChange} className="form-select py-3 cw-select-field" style={{ paddingLeft: '10px', height:'fit-content' }}>
            <option  value="">Want to make Blog Global ?</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
            </div>
            
            {selectedOption == "No" && <div className="col-md-3">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue2} id="country" placeholder="country" autoComplete="off" />
                <label for="country">Country</label>
              </div>
              {showDropdown2 && (
                <div className="dropdown cw-select-dropdown">
                  <ul className="dropdown-menu show" style={{ width: '100%' }}>
                    {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.map((data, index) => (
                      <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                    ))}
                    {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.length === 0 && (
                      <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                    )}
                  </ul>
                </div>
              )}
            </div>}
          </div>

          <div class="mb-3 cw-html-text-editor">
            <p class="font-size-16 fw-semibold black-color mb-2">Blog Content</p>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={handleChange}
              modules={{
                toolbar: toolbarOptions,
              }}
              style={{ height: '300px', marginBottom: '45px' }}
            />
          </div>
          {id && (<p className="font-size-16 fw-semibold black-color mb-0">Last Updated: {moment(location.state._id.updatedAt).format("DD-MM-YYYY")}</p>)}
        </div>

        <div class="d-flex justify-content-center gap-2">
          <button onClick={() => navigate("/blogs")} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
          <button onClick={addandEditBlog} class="btn cw-btn cw-yellow-btn py-2 px-4" disabled={load}>Submit</button>
        </div>
      </div>
    </>
  )
}

export default AddBlog