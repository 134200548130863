import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FeaturedSpace,
  Trending2Space,
  addReviewSpace,
  deleteSpace,
  getAllAdmins,
  getAllCountries,
  getAllSpaces,
  getCountryCity,
  getProfile,
} from "../../store/slices/userSlice";
import moment from "moment"
import Modal from "react-modal";
import { BarChart2, ExternalLink, Eye, Facebook, Instagram, Link2, Linkedin, Mail, MousePointerClick, Pencil, Phone, Search, Star, ThumbsUp, Trash2, Twitter, X, Youtube, } from "lucide-react";
const customStyles = {
  content: {
    display: "block"
  },
};
Modal.setAppElement("#root");

const Locations = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const profile=useSelector(getProfile)
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [date, setdate] = useState("");
  const [review, setreview] = useState("");
  const [locations, setlocations] = useState(null);
  const [countries, setcountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [isReset, setisReset] = useState(false);
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [load, setload] = useState(false);

  const [search, setsearch] = useState('');
  const [totalCount, settotalCount] = useState('');
  const [country, setcountry] = useState('');
  const [nameFilter, setnameFilter] = useState('');
  const [emailFilter, setemailFilter] = useState('');
  const [countryFilter, setcountryFilter] = useState('');
  const [cityFilter, setcityFilter] = useState('');
  const [dateFilter, setdateFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState("");
  const [isTrendingFilter, setIsTrendingFilter] = useState("");
  const [isFeaturedFilter, setIsFeaturedFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "add-review") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "analytics") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("");
    setname("");
    setdescription("");
    setdate("");
    setreview("");
    setUserDetail("")
  }
  

  const locationDelete = async (id) => {
    try {
      await dispatch(deleteSpace(id)).unwrap();
      setIsOpen(false);
      try {
        Locations();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Locations = async () => {
    try {
      setload(true);
      setlocations(null);
      const response = await dispatch(getAllSpaces({city:cityFilter, country:countryFilter, adminId:selectedUsers, page:currentPage, search:nameFilter, isTrending:isTrendingFilter, isFeatured:isFeaturedFilter, date:dateFilter, emailSearch:emailFilter})).unwrap();
      setlocations(response?.data);
      settotalCount(response?.totalCount)
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    } finally{
      setload(false)
    }
  };

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Cities = async (countryId) => {
    try {
      setcities(null);
      const response = await dispatch(getCountryCity(countryId)).unwrap();
      setcities(response?.data);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Admins = async () => {
    try {
      // setload(true);
      setUniqueCreators(null);
      const response = await dispatch(getAllAdmins()).unwrap();
      setUniqueCreators(response?.data);
      // setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (country) {
      Cities(country);
    }
  }, [country]);

  useEffect(() => {
    let mount = true;
    if (mount) {
      Countries();
      Admins();
    }
    return () => {
      mount = false;
    };
  }, [])

  useEffect(() => {
    Locations();
  }, [currentPage])




  const applyFilter = () => {
    Locations();
    navigate(`${location.pathname}`);
    settoggle(false);
  };

  console.log('selectedUsers', selectedUsers)


  const resetFilter = () => {
    setcountryFilter('');
    setcityFilter('');
    setdateFilter('');
    setnameFilter('');
    setemailFilter('');
    setselectedUsers([]);
    setIsFeaturedFilter("")
    setIsTrendingFilter("")
    settoggle(false);
    setisReset(true);
  };

  useEffect(() => {
    if (isReset) {
      Locations();
      setisReset(false);
    }
  }, [isReset]);

  console.log(locations, "locations");
  console.log(countryFilter, "countryFilter");

  const addReview = async (id) => {
    try {
      const payload ={
        spaceId: id,
        name: name,
        description: description,
        date: date,
        rating: review,
      }      
      console.log(payload, "addReview");
      await dispatch(addReviewSpace(payload)).unwrap();     
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  const renderPaginationLinks = () => {
    const pages = [];
    const maxPagesToShow = 5; 
  
    if (totalPages <= maxPagesToShow) {
  
      for (let page = 1; page <= totalPages; page++) {
        pages.push(renderPageLink(page));
      }
    } else {
     
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    
      
      if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }
  
     
      pages.push(renderPageLink(1));
  
      
      if (startPage > 2) {
        pages.push(renderEllipsis());
      }
  
      
      for (let page = startPage === 1 ? 2 : startPage; page <= endPage; page++) {
        pages.push(renderPageLink(page));
      }
  
      
      if (endPage < totalPages - 1) {
        pages.push(renderEllipsis());
      }
  
    
      if (endPage !== totalPages) {
        pages.push(renderPageLink(totalPages));
      }
    }
  
    return pages;
  };
  
  
  const renderPageLink = (page) => (
    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
      <a className="page-link" onClick={() => handlePageChange(page)}>
        {page}
      </a>
    </li>
  );
  
  const renderEllipsis = () => (
    <li key="ellipsis" className="page-item disabled">
      <span className="page-link">...</span>
    </li>
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const [showDropdown1, setShowDropdown1] = useState(false);
  const [searchValue1, setSearchValue1] = useState('');
  const [highlightedIndex1, setHighlightedIndex1] = useState(-1);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  
  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    setcountry(data?.id); 
    setcountryFilter(data?._id)
    setShowDropdown(false);
  };

  const handleInputChange1 = (e) => {
    const value = e.target.value;
    setSearchValue1(value);
    if (value.length > 0) {
      setShowDropdown1(true);
    } else {
      setShowDropdown1(false);
    }
  };
  const handleOptionClick1 = (data) => {
    setSearchValue1(data?.name);
    setcityFilter(data?._id);
    setShowDropdown1(false);
  };

  const filteredCountries = countries?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );
  const filteredCities = cities?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue1?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };

  const FeatureSpace = async (id) => {
    try {
        const spaceIndex = locations.findIndex(space => space?._id === id);
        if (spaceIndex !== -1) {
            const updatedSpaces = [...locations];
            updatedSpaces[spaceIndex].isFeatured = updatedSpaces[spaceIndex].isFeatured === 1 ? 0 : 1;
            setlocations(updatedSpaces);
        }
        await dispatch(FeaturedSpace(id)).unwrap();
    } catch (error) {
        console.log(error);
    }
};
  const TrendingSpace = async (id) => {
    try {
        const spaceIndex = locations.findIndex(space => space?._id === id);
        if (spaceIndex !== -1) {
            const updatedSpaces = [...locations];
            updatedSpaces[spaceIndex].isTrending = updatedSpaces[spaceIndex].isTrending === 1 ? 0 : 1;
            setlocations(updatedSpaces);
        }
        await dispatch(Trending2Space(id)).unwrap();
    } catch (error) {
        console.log(error);
    }
};

  const handleKeyDown1 = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex1((prevIndex) =>
        prevIndex < filteredCities.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex1((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick1(filteredCities[highlightedIndex1]);
      }
    }
  };


  return (
    <>
    <Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show cw-modal ${modalType === "analytics" ? "" : "cw-modal-30"}`}>

        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
        {modalType == "add-review" ? (
    <>
          <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Add Review {userDetail?.name}</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
            <div class="form-floating cw-input mb-3">
                <input type="text" class="form-control" id="name-re" placeholder="Name" onChange={(e) => setname(e.target.value)}/>
                <label for="name-re">Name</label>
              </div>
              <div class="form-floating cw-textarea mb-3">
                <textarea class="form-control" placeholder="Description" id="Description" onChange={(e) => setdescription(e.target.value)} style={{ height: '200px' }}></textarea>
                <label for="Description">Description</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="date" class="form-control" id="Date-re" placeholder="Date" onChange={(e) => setdate(e.target.value)}/>
                <label for="Date-re">Date</label>
              </div>
              <div>
              <p className="font-size-14 fw-semibold mb-1">Review</p>
              <div className="star-rating">
                {[1, 2, 3, 4, 5].map((index) => (
                  <span
                    key={index}
                    onClick={() => setreview(index)}
                    className={index <= review ? 'star-filled' : 'star-empty'}
                  >
                    {index <= review ? (
                      <Star size={24} stroke="var(--color3)" fill="var(--color3)" />
                    ) : (
                      <Star size={24} stroke="var(--color3)" />
                    )}
                  </span>
                ))}
              </div>
              </div>
              {/* <div class="form-floating cw-input">
                <input type="number" class="form-control" id="Review-re" placeholder="Review" onChange={(e) => setreview(e.target.value)}/>
                <label for="Review-re">Review</label>
              </div> */}
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={() => addReview(id)}>Submit</button>
            </div>
        </div>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Space</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Space?</p>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button onClick={() => locationDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
            </div>
        </div>
        </>
        ) : modalType == "analytics" ? ( 
          <>
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Analytics of {userDetail?.name}</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <div className="row gy-3 gx-3">
                {userDetail?.social?.faceBook && <div className="col-md-4">
                    <div className="border p-3">
                    <Facebook size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">Facebook</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.facebook} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.social?.instagram &&<div className="col-md-4">
                    <div className="border p-3">
                    <Instagram size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">Instagram</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.instagram} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.social?.twitter &&<div className="col-md-4">
                    <div className="border p-3">
                    <Twitter size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">X / Twitter</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.twitter} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.social?.linkedIn &&<div className="col-md-4">
                    <div className="border p-3">
                    <Linkedin size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">LinkedIn</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.linkedin} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.social?.tiktok &&<div className="col-md-4">
                    <div className="border p-3">
                    <Linkedin size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">Tiktok</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.tiktok} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.social?.youtube &&<div className="col-md-4">
                    <div className="border p-3">
                    <Youtube size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">Youtube</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.youtube} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.spaceEmail &&<div className="col-md-4">
                    <div className="border p-3">
                    <Mail size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">Email</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.email} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.spacePhone &&<div className="col-md-4">
                    <div className="border p-3">
                    <Phone size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">Phone</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.phone} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.spacePhone &&<div className="col-md-4">
                    <div className="border p-3">
                    <img src="https://www.thecoworkingspaces.com/assets/images/whatsapp-icon.svg" height={34} alt="WhatsApp Icon" className="mb-1"/>
                    <p className="font-size-20 fw-bold mb-0">WhatsApp</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.whatsapp} Clicks</p>
                    </div>
                  </div>}
                  {userDetail?.website &&<div className="col-md-4">
                    <div className="border p-3">
                    <Link2 size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">Website</p>
                    <p className="font-size-16 mb-0">{userDetail?.analytics?.website} Clicks</p>
                    </div>
                  </div>}
                  <div className="col-md-4">
                    <div className="border p-3">
                    <MousePointerClick size={34} stroke="var(--color3)" className="mb-1" />
                    <p className="font-size-20 fw-bold mb-0">Space</p>
                    <p className="font-size-16 mb-0">{userDetail?.spaceopen} Clicks</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          </>
          ) : (
        <></>
      )}
        </div>
 
</Modal>

<div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Spaces</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Spaces</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown cw-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating cw-input mb-3">
              <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="space-name" placeholder="Space Name" />
              <label for="space-name">Space Name</label>
            </div>
          <div class="form-floating cw-input mb-3">
              <input type="email" onChange={(e)=>setemailFilter(e.target.value)} value={emailFilter} class="form-control" id="space-Email" placeholder="Space Email" />
              <label for="space-Email">Space Email</label>
            </div>
            <div class="form-floating cw-input mb-3">
                <input type="date" class="form-control" id="date-filter" placeholder="Date" onChange={(e) => setdateFilter(e.target.value)} value={dateFilter}/>
                <label for="date-filter">Date</label>
              </div>
              <div className="mb-3">
              <div class="form-floating cw-input">
              <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue} id="country" placeholder="country" autoComplete="off"/>
              <label for="country">Select Country</label>
              </div>
              {showDropdown && (
                <div className="dropdown cw-select-dropdown">
                  <ul className="dropdown-menu show" style={{ width: '100%' }}>
                    {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                      <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                    ))}
                    {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                      <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                    )}
                  </ul>
                </div>
              )}
              </div>
              {country && (
                <div className="mb-3">
                  <div class="form-floating cw-input">
                    <input type="text" class="form-control" onKeyDown={handleKeyDown1} maxLength={120} onChange={(e) => handleInputChange1(e)} value={searchValue1} id="city" placeholder="city" autoComplete="off" />
                    <label for="city">City</label>
                  </div>
                  {showDropdown1 && (
                    <div className="dropdown cw-select-dropdown">
                      <ul className="dropdown-menu show" style={{ width: '100%' }}>
                        {cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.map((data, index) => (
                          <li key={index} onClick={() => handleOptionClick1(data)} className={highlightedIndex1 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                        ))}
                        {cities?.length > 0 && cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.length === 0 && (
                          <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            <div class="mb-3 sp-input-filter">
              <p class="font-size-20 fw-semibold mb-2">Author</p>
              <div class="d-flex gap-2 flex-wrap">
                {uniqueCreators?.map((data, i) => (
                  <>
                    <input type="radio" class="btn-check" checked={selectedUsers.includes(data?._id)} onChange={() => setselectedUsers(data?._id)} id={data?._id} value={data?.name} />
                    <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
                  </>
                ))}
                <input type="radio" class="btn-check" checked={selectedUsers.includes(profile?._id)} onChange={() => setselectedUsers(profile?._id)} id={profile?._id} value={profile?.name} />
                <label class="btn cw-select-btn" for={profile?._id}>{profile?.name}</label>

              </div>
            </div>
            <div class="mb-3 sp-input-filter">
              <p class="font-size-20 fw-semibold mb-2">Trending / Featured</p>
              <div class="d-flex gap-2 flex-wrap">
                <input type="checkbox" class="btn-check" id="trending" checked={isTrendingFilter === 1} onChange={() => setIsTrendingFilter(1)} />
                <label class="btn cw-select-btn" for="trending">Trending</label>

                <input type="checkbox" class="btn-check" id="featured" checked={isFeaturedFilter === 1} onChange={() => setIsFeaturedFilter(1)} />
                <label class="btn cw-select-btn" for="featured">Featured</label>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
              <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn cw-btn cw-yellow-btn px-3" onClick={()=>navigate("/download-data")}>Download CSV</button>
        <button class="btn cw-btn cw-yellow-btn px-3" onClick={()=>navigate("/add-space")}>Add Space</button>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Total Spaces: {totalCount}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Business</th>
              <th scope="col">Location</th>
              {/* <th scope="col">Capacity</th> */}
              <th scope="col">Email</th>
              {/* <th scope="col">Phone</th> */}
              <th scope="col">Add</th>
              {/* <th scope="col">Package</th>
              <th scope="col">Manage</th> */}
              <th scope="col">Author</th>
              <th scope="col">Date</th>
              <th scope="col">Feature</th>
              <th scope="col">Trending</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>

            {load ? new Array(50).fill(null).map((_, index) => (<tr key={index}><td colSpan={13}><div style={{ height: '83px' }} className='w-100 shimmer'></div></td></tr>)) : locations?.map((item, i) => (
              <tr key={i}>
                <td style={{verticalAlign:"middle"}}>{i + startIndex + 1}</td>
                <td style={{verticalAlign:"middle"}}>{item?.name}</td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="d-flex gap-2 align-items-center">
                  {item?.businesses?.name}
                  </div>
                </td>
                <td style={{verticalAlign:"middle"}}>{item?.cities?.name}, {item?.countries?.name}</td>
                {/* <td style={{verticalAlign:"middle"}}><div className="d-flex gap-2 justify-content-between">
                  <div className="cw-count">
                    <p className="font-size-12 mb-0">{item?.totalDesk}</p>
                    <p className="font-size-10 mb-0">Desk</p>
                  </div>
                  <div className="cw-count">
                    <p className="font-size-12 mb-0">{item?.totalRooms}</p>
                    <p className="font-size-10 mb-0">Room</p>
                  </div>
                  </div></td> */}
                <td style={{verticalAlign:"middle"}}>{item?.spaceEmail}</td>
                {/* <td style={{verticalAlign:"middle"}}>{item?.spacePhone}</td> */}
                <td style={{verticalAlign:"middle"}}>
                <button class="btn cw-btn cw-yellow-btn px-2 py-1 font-size-12 mb-1 d-block" onClick={()=>navigate("/add-space", {state: item.businesses,})}>Space</button>
                <button class="btn cw-btn cw-yellow-btn px-2 py-1 font-size-12" onClick={()=>navigate("/add-space-type", {state: item,})}>Room/Desk</button></td>
                {/* <td style={{verticalAlign:"middle"}}><span style={{backgroundColor: `${item?.isAdmin === 1 ? "var(--color3)" : "var(--color2)"}`, color: `${item?.isAdmin === 1 ? "var(--color2)" : "var(--color1)"}`}} class="btn cw-btn px-2 py-1 font-size-12 text-capitalize">{item?.isAdmin === 1 ? "Free Package" : "Premium Package"}</span></td>
                <td style={{verticalAlign:"middle"}}> <span style={{backgroundColor: `${item?.isAdmin === 1 ? "var(--color2)" : "var(--color3)"}`, color: `${item?.isAdmin === 1 ? "var(--color1)" : "var(--color2)"}`}} class="btn cw-btn px-2 py-1 font-size-12 text-capitalize">{item?.isAdmin === 1 ? "Unmanaged" : "Managed"}</span> </td> */}
                <td className="text-capitalize" style={{verticalAlign:"middle"}}>{item?.adminDetail[0]?.name}</td>
                <td className="text-capitalize" style={{verticalAlign:"middle"}}>{item?.formatedDate}</td>
                {/* <td className="text-capitalize" style={{verticalAlign:"middle"}}>{moment(item?.createdAt).format("DD-MM-YYYY")}</td> */}
                <td style={{verticalAlign:"middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    <div class="form-check form-switch cw-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="business-aproved" checked={item?.isFeatured === 1} onChange={()=> FeatureSpace(item?._id)}/>
                      <label class="form-check-label" for="business-aproved"></label>
                    </div>
                  </div>
                </td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    <div class="form-check form-switch cw-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="trending-spaces" checked={item?.isTrending === 1} onChange={()=> TrendingSpace(item?._id)}/>
                      <label class="form-check-label" for="trending-spaces"></label>
                    </div>
                  </div>
                </td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    <div onClick={() => viewModal(item, "analytics")} className={`cw-action-icons l-blue-bg`}><BarChart2 width="18" /></div>
                    <Link target="blank" to={`https://www.thecoworkingspaces.com/space/${item?.slug}`} className={`cw-action-icons l-blue-bg`}><ExternalLink width="18" /></Link>
                    <Link to={`/space-details/${item?._id}`} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></Link>
                    <Link to={`/edit-space/${item?._id}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                    <div onClick={() => viewModal(item, "add-review")} className={`cw-action-icons l-blue-bg`}>{item?.totalReviews === 0 ? <><Star width="18" /></> : <><Star width="18" fill="var(--color2)" /></>}</div>
                    {profile?.space_access.includes("delete") && <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
      {totalCount > 50 &&
      <div className="cw-pagination" aria-label="Page navigation example">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
          <a className="page-link" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {renderPaginationLinks()}
        <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
          <a className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </div>}
    </div>
  </div>
    </>
  )
}

export default Locations