import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";

import { extraReducers } from "../reducer/userReducer"

axios.defaults.baseURL = process.env.REACT_APP_APIURL
const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
const sidebar = JSON.parse(localStorage.getItem('sidebar')) ? JSON.parse(localStorage.getItem('sidebar')) : null
const userRight = JSON.parse(localStorage.getItem('userRight')) ? JSON.parse(localStorage.getItem('userRight')) : null
axios.defaults.headers.common['Authorization'] = user?.token;
 
const initialState = {
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    user: user,
    role: user?.role,
    sidebarConfig: sidebar,
    userRight: userRight,
    business: null,
    TcPp: null,
    dashboard: null,
    lineChart: null,
    areaChart: null,
    memes: null
}
export const signinAdmin = createAsyncThunk('/admin/login', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/login`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const signOut = createAsyncThunk("/admin/sign-out", async (bodyData = null, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/admin/sign-out`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const addAdmin = createAsyncThunk('/admin/add-sub-admin', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-sub-admin`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editAdmin = createAsyncThunk('/admin/edit-sub-admin', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-sub-admin`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteAdmin = createAsyncThunk('/admin/delete-admin', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-admin`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllAdmins = createAsyncThunk('/admin/list-of-admin', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-admin`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getPaymentCycle = createAsyncThunk('/admin/get-payment-cycle', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-payment-cycle`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllCountries = createAsyncThunk('/admin/list-of-country', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-country`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const EditCountry = createAsyncThunk('/admin/edit-country', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-country`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const DeleteCountry = createAsyncThunk('/admin/delete-country/', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-country/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getFeatureCategories = createAsyncThunk('/admin/list-of-feature-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-feature-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addFeatureCategory = createAsyncThunk('/admin/add-feature-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-feature-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editFeatureCategory = createAsyncThunk('/admin/edit-feature-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-feature-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteFeatureCategory = createAsyncThunk('/admin/delete-feature-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-feature-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getFeatures = createAsyncThunk('/admin/list-of-feature', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-feature`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addFeature = createAsyncThunk('/admin/add-feature', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-feature`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editFeature = createAsyncThunk('/admin/edit-feature', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-feature`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteFeature = createAsyncThunk('/admin/delete-feature', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-feature`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteQuotation = createAsyncThunk('/admin/delete-requested-quote', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-requested-quote?id=${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getDeskTypes = createAsyncThunk('/admin/list-of-room-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-room-desk-type?type=${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getRoomTypes = createAsyncThunk('/admin/list-of-room-desk-type?type=Room', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-room-desk-type?type=Room`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getRoomDeskTypes = createAsyncThunk('/admin/get-both-room-and-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-both-room-and-desk-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addDeskRoomType = createAsyncThunk('/admin/add-room-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-room-desk-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editDeskRoomType = createAsyncThunk('/admin/edit-room-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-room-desk-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteDeskRoomType = createAsyncThunk('/admin/delete-room-desk-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-room-desk-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
// export const getCountryCity = createAsyncThunk('/admin/list-of-country-for-dropdown', async (bodyData, { rejectWithValue }) => {
//     try {
//         const response = await axios.get(`/admin/list-of-country-for-dropdown`, bodyData)
//         return response.data
//     } catch (error) {
//         return rejectWithValue(error.response.data)
//     }
// })
export const getCountryCity = createAsyncThunk('/admin/list-of-country-for-dropdown', async (countryId, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/admin/list-of-country-for-dropdown/${countryId}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
});
export const getCityArea = createAsyncThunk('/admin/list-of-area', async (cityId, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/admin/list-of-area?cityId=${cityId}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
});
export const addCityArea = createAsyncThunk('/admin/add-area', async (bodyData, { rejectWithValue }) => {
      try {
        const response = await axios.post(`/admin/add-area`, bodyData);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
});
export const getCountryCitySearch = createAsyncThunk('/admin/list-of-country-for-dropdown-admin', async ({countryId, page, search}, { rejectWithValue }) => {
      try {
        const response = await axios.get(`/admin/list-of-country-for-dropdown-admin?countryId=${countryId}&page=${page}&search=${search}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
});
export const getCity = createAsyncThunk('/admin/list-of-city', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-city`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addCity = createAsyncThunk('/admin/add-city', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-city`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editCity = createAsyncThunk('/admin/edit-city', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-city`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteCity = createAsyncThunk('/admin/delete-city', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-city/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addSpace = createAsyncThunk('/admin/add-space', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-space`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editSpace = createAsyncThunk('/admin/edit-space', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-space`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const SpaceDetail = createAsyncThunk('/admin/space-detail', async (spaceId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/space-detail?id=${spaceId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
});
export const EditSpaceDetail = createAsyncThunk('/admin/space-detail-for-edit', async (spaceId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/space-detail-for-edit?id=${spaceId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
});
export const addReviewSpace = createAsyncThunk('/admin/add-review', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-review`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editReviewSpace = createAsyncThunk('/admin/edit-review', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-review`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getSpaceReviews = createAsyncThunk('/admin/list-of-reviews', async (spaceId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/list-of-reviews?spaceId=${spaceId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
});
export const deleteReviews = createAsyncThunk('/admin/delete-review', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-review/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteSpace = createAsyncThunk('/admin/delete-space', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-space/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllSpaces = createAsyncThunk('/admin/list-of-space', async ({ city, country, adminId, page, search, isTrending, isFeatured, date, emailSearch }, { rejectWithValue }) => {
    try {
        // console.log(bodyData)
        const response = await axios.get(`/admin/list-of-space?city=${city}&country=${country}&adminId=${adminId}&search=${search}&page=${page}&isTrending=${isTrending}&isFeatured=${isFeatured}&date=${date}&emailSearch=${emailSearch}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllSpacesByBusinesses = createAsyncThunk('/admin/list-of-space-created-by-owner', async ({ city, country, adminId, page, search, isTrending, isFeatured, date }, { rejectWithValue }) => {
    try {
        // console.log(bodyData)
        const response = await axios.get(`/admin/list-of-space-created-by-owner?city=${city}&country=${country}&adminId=${adminId}&search=${search}&page=${page}&isTrending=${isTrending}&isFeatured=${isFeatured}&date=${date}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBlogCategory = createAsyncThunk('/admin/list-of-blog-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-blog-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getContactUsList = createAsyncThunk('/admin/contact-us', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/contact-us`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addBlogCategory = createAsyncThunk('/admin/add-blog-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-blog-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editBlogCategory = createAsyncThunk('/admin/edit-blog-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-blog-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteBlogCategory = createAsyncThunk('/admin/delete-blog-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-blog-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBlogs = createAsyncThunk('/admin/list-of-blogs', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-blogs`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addBlogs = createAsyncThunk('/admin/add-blog', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-blog`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editBlogs = createAsyncThunk('/admin/edit-blog', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-blog`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteBlogs = createAsyncThunk('/admin/delete-blog', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-blog/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getInclusions = createAsyncThunk('/admin/list-of-inclusion', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-inclusion`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addInclusions = createAsyncThunk('/admin/add-inclusion', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-inclusion`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editInclusions = createAsyncThunk('/admin/edit-inclusion', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-inclusion`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteInclusions = createAsyncThunk('/admin/delete-inclusion', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-inclusion`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getCoworkingTypes = createAsyncThunk('/admin/get-business-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-business-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addCoworkingTypes = createAsyncThunk('/admin/add-business-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-business-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editCoworkingTypes = createAsyncThunk('/admin/edit-business-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-business-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteCoworkingTypes = createAsyncThunk('/admin/delete-business-type', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/admin/delete-business-type`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addSpaceType = createAsyncThunk('/admin/add-room-and-desk', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-room-and-desk`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editSpaceTypee = createAsyncThunk('/admin/edit-room-and-desk', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-room-and-desk`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const SpaceTypeDetail = createAsyncThunk('/admin/room-desk-detail', async ({ spaceTypeId, behaveParam }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/room-desk-detail?id=${spaceTypeId}&behave=${behaveParam}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
});
export const addPackages = createAsyncThunk('/admin/add-pricing-package', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-pricing-package`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editPackages = createAsyncThunk('/admin/edit-pricing-package', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-pricing-package`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getPackages= createAsyncThunk('/admin/list-of-pricing-packages', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-pricing-packages`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getRequestedQuotes= createAsyncThunk('/admin/list-of-requested-quotes', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-requested-quotes`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getCareers= createAsyncThunk('/admin/list-of-career', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-career`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getClaimSpaces= createAsyncThunk('/admin/get-claim-space', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-claim-space`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getNewsletterEmails= createAsyncThunk('/admin/list-of-newsletter', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-newsletter`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getEnterpriseSoltions= createAsyncThunk('/admin/list-of-enterprices', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-enterprices`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getReportSpaces= createAsyncThunk('/admin/get-report-space', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-report-space`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getLandlordProperty= createAsyncThunk('/admin/get-all-property', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-all-property`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getInquiries= createAsyncThunk('/admin/list-of-inquiry', async ({page, search}, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-inquiry?page=${page}&search=${search}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAbandonedSpaces = createAsyncThunk('/admin/list-of-abundant-space', async ({page, search}, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-abundant-space?page=${page}&search=${search}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBusinessCountries= createAsyncThunk('/admin/get-business-for-country', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-business-for-country?id=${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBusinessCountrySpaces= createAsyncThunk('/admin/get-spaces-by-country-and-business', async ({businessId, countryId}, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-spaces-by-country-and-business?businessId=${businessId}&countryId=${countryId}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getBusinessess = createAsyncThunk('/admin/get-all-business', async ({ search, isAdmin, limit, page, searchEmail }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-all-business?isAdmin=${isAdmin}&search=${search}&searchEmail=${searchEmail}&page=${page}&limit=${limit}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const sendClaimSpaceEmail = createAsyncThunk('/admin/promotional-email-spaces', async ({ cityId, countryId }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/promotional-email-spaces?countryId=${countryId}&cityId=${cityId}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const aproveBusiness = createAsyncThunk('/admin/approve-business', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/approve-business/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const verifiedBusiness = createAsyncThunk('/admin/verify-business', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/verify-business/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const FeaturedSpace = createAsyncThunk('/admin/feature-space', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/feature-space/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const Trending2Space = createAsyncThunk('/admin/trending-space', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/trending-space/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const InviteBusinessOwner = createAsyncThunk("/admin/invite-business-owner", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/invite-business-owner`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const changeBusinessName = createAsyncThunk("/admin/add-business-by-country", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-business-by-country`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const ResendInviteBusinessOwner = createAsyncThunk("/admin/resend-email-to-business-email", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/resend-email-to-business-email`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const spaceClosed = createAsyncThunk("/admin/change-space-status", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/change-space-status`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const changePassword = createAsyncThunk("/admin/update-password", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/update-password`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getBookedDemos = createAsyncThunk('/admin/list-of-book-demo', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-book-demo`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getmetaDataList = createAsyncThunk('/admin/list-of-meta-data', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-meta-data`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addMetaData = createAsyncThunk("/admin/add-meta-data", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/add-meta-data`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const editMetaData = createAsyncThunk("/admin/edit-meta-data", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/edit-meta-data`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});



export const verifyAccount = createAsyncThunk("/verify-account", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/verify-account`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const signUp = createAsyncThunk('/sign-up', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`sign-up`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const completeProfile = createAsyncThunk('/complete-profile', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`complete-profile`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const completeBusiness = createAsyncThunk('/complete-business', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`complete-business`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updatePassword = createAsyncThunk('/update-password', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/update-password`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const socialLogin = createAsyncThunk("/social-login", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/social-login`, bodyData);

        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const forgetPassword = createAsyncThunk('/forget-password', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`forget-password`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const addBusiness = createAsyncThunk("/add-business", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-business`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getDashboardData = createAsyncThunk("/dashboard", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/dashboard`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getGraphData = createAsyncThunk("/graph-data", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/graph-data`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const addStickyNote = createAsyncThunk("/add-sticky-note", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-sticky-note`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addMailAndPackage = createAsyncThunk("/add-mail-and-package", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-mail-and-package`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addBooking = createAsyncThunk("/booking", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/booking`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addRoom = createAsyncThunk("/add-room", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-room`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addVirtualOffice = createAsyncThunk("/add-virtual-office", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-virtual-office`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addDesk = createAsyncThunk("/add-desk", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-desk`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addLead = createAsyncThunk("/add-lead", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-lead`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addUser = createAsyncThunk("/create-user", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/create-user`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const editEmployee = createAsyncThunk("/edit-employee", async (bodyData, { rejectWithValue }) => {
    try {
        console.log("bodyData", Object.fromEntries(bodyData.entries()))
        const response = await axios.post(`/edit-employee`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const setupPassword = createAsyncThunk('/setup-password', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/setup-password`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const invoiceSend = createAsyncThunk("/email-invoice", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`email-invoice`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const invoiceDescription = createAsyncThunk("/invoice-description", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`invoice-description`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const receiptSend = createAsyncThunk("/email-receipt", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`email-receipt`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const invoicePayment = createAsyncThunk("/invoice-payment", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/invoice-payment`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addPlan = createAsyncThunk("/add-plan", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-plan`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const createRole = createAsyncThunk("/create-role", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/create-role`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const editRole = createAsyncThunk("/edit-role", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/edit-role`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getBusinessType = createAsyncThunk("/all-business-type", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/all-business-type`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getPlans = createAsyncThunk("get-plans-for-rooms-and-desk", async ({ spaceId, type }, { rejectWithValue }) => {
    try {

        const response = await axios.get(`get-plans-for-rooms-and-desk/${spaceId}/${type}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getPlansForEdit = createAsyncThunk("get-plans-for-edit", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`get-plans-for-edit/${bodyData.id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getBookings = createAsyncThunk("booking-list", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`booking-list`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getInvoices = createAsyncThunk("invoice-list", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`invoice-list/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getBookingDetail = createAsyncThunk("booking-detail", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`booking-detail/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getAllPaymentMethods = createAsyncThunk("all-payment-methods", async (bodyData = null, { rejectWithValue }) => {
    try {

        const response = await axios.get(`all-payment-methods`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const setPaymentMethodForBusiness = createAsyncThunk("set-payment-methods-for-business", async (bodyData = null, { rejectWithValue }) => {
    try {

        const response = await axios.post(`set-payment-methods-for-business`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getAllPlans = createAsyncThunk("plan-list", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`plan-list`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getInvoiceDetail = createAsyncThunk("invoice-detail", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`invoice-detail/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getSpacesForDropDown = createAsyncThunk("/get-spaces-for-drop-down", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/get-spaces-for-drop-down/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getSpacesForDropDownPrivate = createAsyncThunk("/get-spaces-for-drop-down-private", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/get-spaces-for-drop-down-private`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getDeskDetailForEdit = createAsyncThunk("/desk-detail-for-edit", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/desk-detail-for-edit/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getroomDetailForEdit = createAsyncThunk("/room-detail-for-edit", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/room-detail-for-edit/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getEmployeeDependsOnSpace = createAsyncThunk("/get-employee-depend-on-space", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/get-employee-depend-on-space`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const allSupportCategory = createAsyncThunk("/get-all-support-category", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/get-all-support-category/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getSpaceAndCategoryCombination = createAsyncThunk("/get-category-space-combine-data/", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/get-category-space-combine-data/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const allFinanceCategory = createAsyncThunk("/get-all-finance-category", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/get-all-finance-category/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getCustomersForDropDown = createAsyncThunk("/get-customers-for-booking-dropdown", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/get-customers-for-booking-dropdown`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const updateCombination = createAsyncThunk("/update-combination", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/update-combination`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getRoomsAndDesksForDropDown = createAsyncThunk("/get-desks-rooms-for-booking-dropdown", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-desks-rooms-for-booking-dropdown/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const chatList = createAsyncThunk("/admin/chat-list", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/chat-list`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getUsersToStartChat = createAsyncThunk("/get-employee-for-chat", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/get-employee-for-chat`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const allRoles = createAsyncThunk("/get-all-roles", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-all-roles/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const sendTheAttachment = createAsyncThunk("/member/send-attachment", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/member/send-attachment`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getPlansForBookingForDropDown = createAsyncThunk("/plan-detail/", async ({ id, type }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/plan-detail/${id}/${type}`, null);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getEmployeeDetail = createAsyncThunk("/employee-detail", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/employee-detail/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const memberListForPackage = createAsyncThunk("/member-list-and-package/", async (bodyData, { rejectWithValue }) => {
    try {


        const response = await axios.get(`/member-list-and-package/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


export const availableSlots = createAsyncThunk("/get-available-slots", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/get-available-slots`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const checkDeskAvaibility = createAsyncThunk("/check-desk-avaibility", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.filterType)
        const response = await axios.post(`/check-desk-avaibility`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getDeskCountSlice = createAsyncThunk("/get-count-for-desks", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/get-count-for-desks/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const checkHotDeskAvaibility = createAsyncThunk("/check-avaibility-for-hot-desk", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.filterType)
        const response = await axios.post(`/check-avaibility-for-hot-desk`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);


export const getCountry = createAsyncThunk("/all-country", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/all-country`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const dashboard = createAsyncThunk('admin/dashboard', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/dashboard`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllUsers = createAsyncThunk('/admin/user-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/user-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getCustomerUsers = createAsyncThunk('/admin/list-of-customers', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/list-of-customers`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const getByBusinessData = createAsyncThunk('/get-my-business', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-my-business`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const userList = createAsyncThunk('/user-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/user-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getEmployeeForEditSpace = createAsyncThunk('/get-employee-for-edit-space-dropdown', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-employee-for-edit-space-dropdown/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const userEmployeeList = createAsyncThunk('/user-employee-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/user-employee-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getMyPaymentMethods = createAsyncThunk('/get-my-payment-methods', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/get-my-payment-methods/${bodyData?.id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const allSideMenus = createAsyncThunk('/get-all-sidemenus', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-all-sidemenus`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const listOfTransaction = createAsyncThunk('/transaction-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/transaction-list`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getInvoicesForTransactionDropDown = createAsyncThunk('/invoices-list-for-tranactions-dropdown', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/invoices-list-for-tranactions-dropdown`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addTransaction = createAsyncThunk('/add-transaction', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/add-transaction`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const transactionDetail = createAsyncThunk('/transaction-detail', async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData)
        const response = await axios.get(`/transaction-detail/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const listOfInvoices = createAsyncThunk('/invoices-list', async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData)
        const response = await axios.post(`/invoices-list/`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteSupportCategory = createAsyncThunk('/delete-support-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-support-category/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteEvents = createAsyncThunk('/delete-events', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-events/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const newsDetail = createAsyncThunk('/news-detail', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/news-detail/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const eventDetail = createAsyncThunk('/event-detail', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/event-detail/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const editSupportCategory = createAsyncThunk('/edit-support-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/edit-support-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editFinanceCategory = createAsyncThunk('/edit-finance-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/edit-finance-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllRooms = createAsyncThunk('/room-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/room-list`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllVirtualOffice = createAsyncThunk('/virtual-office-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/virtual-office-list`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllDesks = createAsyncThunk('/desk-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/desk-list`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const leadsList = createAsyncThunk('/lead-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/lead-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const memberList = createAsyncThunk('/member-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/member-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const contactList = createAsyncThunk('/contact-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/contact-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deskDetail = createAsyncThunk('/desk-detail', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/desk-detail`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const roomDetail = createAsyncThunk('/room-detail', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/room-detail`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getSpaceDetail = createAsyncThunk('/space-detail', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/space-detail`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getSpaceDetail2 = createAsyncThunk('/desk-detail-for-space', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/desk-detail-for-space`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const userDetailData = createAsyncThunk('/user-detail', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/user-detail/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const userDetailView = createAsyncThunk('/user-detail-view', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/user-detail-view/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllActivity = createAsyncThunk('/get-activity', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-activity/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllTechnicalCategory = createAsyncThunk('/get-technical-category', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-technical-category/${bodyData?.businessId}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllTickets = createAsyncThunk('/get-all-tickets', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/get-all-tickets`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const createActivity = createAsyncThunk('/add-activity', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-activity`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addCommentOnTicket = createAsyncThunk('/add-comments-on-ticket', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-comments-on-ticket`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addSupportCategory = createAsyncThunk('/add-technical-category', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-technical-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addFinanceCategory = createAsyncThunk('/add-finance-category', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-finance-category`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const sendEmailPasswordForMember = createAsyncThunk('/send-email-for-password', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/send-email-for-password`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const memberToContact = createAsyncThunk('/member-to-contact', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/member-to-contact`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addTicket = createAsyncThunk('/add-ticket', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-ticket`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const editRoom = createAsyncThunk('/edit-room', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/edit-room`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editNews = createAsyncThunk('/edit-news', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/edit-news`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editEvent = createAsyncThunk('/edit-event', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/edit-event`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const editDesk = createAsyncThunk('/edit-desk', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/edit-desk`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const editPlan = createAsyncThunk('/edit-plan', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/edit-plan`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const editLead = createAsyncThunk('/edit-lead', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/edit-lead`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const createReminder = createAsyncThunk('/add-reminder', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-reminder`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllReminder = createAsyncThunk('/get-reminder', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-reminder/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const ticketDetail = createAsyncThunk('/get-ticket-detail', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-ticket-detail/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const resolveTicket = createAsyncThunk('/resolve-ticket', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/resolve-ticket/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const reopenTicket = createAsyncThunk('/reopen-ticket', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/reopen-ticket/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addEvent = createAsyncThunk('/add-event', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/add-event`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addPrintScanRequest = createAsyncThunk('/create-print-and-scan-request', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/create-print-and-scan-request`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getMailAndPackage = createAsyncThunk('/get-mail-and-packages', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/get-mail-and-packages`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addVisitingRequest = createAsyncThunk("/add-visiting", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-visiting`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const editVIsitingRequest = createAsyncThunk("/edit-visiting", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/edit-visiting`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getVisitsList = createAsyncThunk('/get-visits-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/get-visits-list`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getPrintAndScans = createAsyncThunk('/get-print-and-scan-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/get-print-and-scan-list`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getNotifications = createAsyncThunk('/get-notifications', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-notifications`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getNotificationDetail = createAsyncThunk('/notifications-detail', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/notifications-detail`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteNotification = createAsyncThunk('/delete-notification', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-notification/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const addNews = createAsyncThunk('/add-news', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/add-news`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const editPrintAndScan = createAsyncThunk('/edit-print-and-scan', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/edit-print-and-scan`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updateMailPackageStatus = createAsyncThunk('/change-mail-package-final-status', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/change-mail-package-final-status`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const eventList = createAsyncThunk('/list-of-events', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/list-of-events`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const newsList = createAsyncThunk('/list-of-news', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/list-of-news`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const deleteNews = createAsyncThunk('/delete-news', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-news/${bodyData}`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const closeTicket = createAsyncThunk('/closed-ticket', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/closed-ticket/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const reminderDelete = createAsyncThunk('/delete-reminder', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-reminder/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const activityDelete = createAsyncThunk('/delete-activity', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-activity/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const reminderEdit = createAsyncThunk('/edit-reminder', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/edit-reminder`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const activityEdit = createAsyncThunk('/edit-activity', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/edit-activity`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})



export const recentMemes = createAsyncThunk('admin/recentMemes', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/recentMemes`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllCategories = createAsyncThunk('admin/get-all-categries', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/get-all-categries`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllSounds = createAsyncThunk('admin/get-sound-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-sound-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addPackage = createAsyncThunk('admin/addPackage', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`admin/addPackage`, bodyData)

        return response.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addCategory = createAsyncThunk('admin/add-category', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`admin/add-category`, bodyData)

        return response.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const createSound = createAsyncThunk('admin/create-sound-by-admin', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`admin/create-sound-by-admin`, bodyData)

        return response.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updatePackage = createAsyncThunk('/admin/updatePackage/', async (bodyData, { rejectWithValue }) => {
    try {


        const response = await axios.post(`/admin/updatePackage/${bodyData?.id}`, bodyData)

        return response.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const deleteTemplate = createAsyncThunk('admin/deleteTemplate', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`admin/deleteTemplate/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const getReportedMeme = createAsyncThunk('admin/getReportedMeme', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/getReportedMeme`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const approveDisapproveReport = createAsyncThunk('admin/approveDisapproveReport', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/approveDisapproveReport/${bodyData.id}`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteAccount = createAsyncThunk('admin/delete-user/', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/delete-user/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const deleteSound = createAsyncThunk('admin/delete-sound/', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/delete-sound/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteCategory = createAsyncThunk('admin/delete-category/', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/delete-category/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const blockUnblock = createAsyncThunk('admin/blockunblock', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/block-user/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})



export const updateTcpp = createAsyncThunk('admin/TcPp', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/TcPp`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updateScript = createAsyncThunk('/admin/createScript', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/createScript`, bodyData)

        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const userLogout = createAsyncThunk('admin/sign-out', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/sign-out`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const TcPp = createAsyncThunk('admin/getTcPp', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/getTcPp`)

        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const getScriptData = createAsyncThunk('/admin/getScript', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/getScript`)

        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        token: (state) => {
            var user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                state.user = user
            }
        }
    },
    extraReducers
})


export const getUserStatus = (state) => state?.users?.status;
export const getUserError = (state) => state?.users?.error;
export const getUserToken = (state) => state?.users?.user?.token;
export const getUserRole = (state) => state?.users?.user?.role;
export const getProfile = (state) => state?.users?.user;
export const getSideBar = (state) => state?.users?.sidebarConfig;
export const getUserRight = (state) => state?.users?.userRight;
export const getTcPp = (state) => state?.users?.TcPp;
export const getDashboard = (state) => state?.users?.dashboard;
export const getareaChart = (state) => state?.users?.areaChart;
export const getlineChart = (state) => state?.users?.lineChart;
export const getMemes = (state) => state?.users?.memes;
export const getBusiness = (state) => state?.users?.business;

export const { token } = userSlice.actions
export default userSlice.reducer