import React from 'react'

const Spinner = () => {
  return (
    <>
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", width: "100%", backgroundColor: "rgba(15, 15, 15,0.5)", position: "fixed", zIndex: 1500, top: 0, left:0 }}>
        <div className="fa-3x">
        <dotlottie-player src="https://lottie.host/c37852a2-6f0a-411c-8dd9-01aaa52c65a5/Tx1pv6TyVl.json" background="transparent" speed="1" style={{"width": "300px", "height": "300px"}} loop autoplay></dotlottie-player>
        </div>
    </div>
    </>
  )
}

export default Spinner