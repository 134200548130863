import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllUsers,
  deleteAccount,
  getClaimSpaces
} from "../../store/slices/userSlice";
import moment from "moment"
import Modal from "react-modal";
import { Eye, Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const ClaimSpaces = () => {
    const profile=useSelector(getProfile)


    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [claimSpaces, setclaimSpaces] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [toggle, settoggle] = useState(false);
    const [uniqueCreators, setUniqueCreators] = useState([]);
    const [creators, setCreators] = useState({});
    const [load, setload] = useState(false);
    const [isAdmin, setisAdmin] = useState(1);
    // const [name, setname] = useState("");
    // const [imageName, setimageName] = useState("");
  
  
    function viewModal(item, type) {
      setIsOpen(true);
      if (type == "userDetail") {
        setUserDetail(item);
        setId(item?._id);
      } else if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    }
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const userDelete = async (id) => {
      try {
        await dispatch(deleteAccount({id: id})).unwrap();
        setIsOpen(false);
        try {
            ClaimSpace();
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const ClaimSpace = async () => {
      try {
        setload(true);
        setclaimSpaces(null);
        const response = await dispatch(getClaimSpaces()).unwrap();
        console.log('responsessss', response?.data)
        setclaimSpaces(response?.data);
        setload(false);
       
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    useEffect(() => {
      let mount = true;
      if (mount) {
        ClaimSpace();
      }
      return () => {
        mount = false;
      };
    }, []);

    const data = claimSpaces?.filter(item => item?.spaces?.isAdmin === isAdmin);
    console.log(data, "claimSpaces");
    
  return (
    <>
    <Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
className={`modal fade show cw-modal cw-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
        {modalType == "userDetail" ? (<>
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Claim Space Detail</h1>
              <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-16 black-color fw-bold mb-0">Name</p>
                <p class="font-size-16 black-color">{userDetail?.name}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Email</p>
                <p class="font-size-16 black-color">{userDetail?.email}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Phone</p>
                <p class="font-size-16 black-color">{userDetail?.phone}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Website</p>
                <p class="font-size-16 black-color">{userDetail?.website}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Role</p>
                <p class="font-size-16 black-color">{userDetail?.relationship}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Space Name</p>
                <p class="font-size-16 black-color">{userDetail?.spaces?.name}</p>
              </div>
            </div>
      </>): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Claim Space</h1>
              <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Claim Space?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
                <button onClick={() => userDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>
    <div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Claim Spaces</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Claim Spaces</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">

      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Claim Spaces : {claimSpaces?.filter(item => item?.spaces?.isAdmin === isAdmin)?.length}</p>
      <div class="d-flex gap-2 flex-wrap mb-3">
        <input type="checkbox" class="btn-check" id="pending" checked={isAdmin === 1} onChange={() => setisAdmin(1)} />
        <label class="btn cw-select-btn" for="pending">Pending</label>

        <input type="checkbox" class="btn-check" id="closed" checked={isAdmin === 0} onChange={() => setisAdmin(0)} />
        <label class="btn cw-select-btn" for="closed">Closed</label>
      </div>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Business Name</th>
              <th scope="col">Space Name</th>
              {isAdmin === 1 &&<th scope="col">Invitation</th>}
              <th style={{ width: '140px' }} scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={9}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : claimSpaces?.filter(item => item?.spaces?.isAdmin === isAdmin)?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.email?.substring(0,30)}</td>
                <td>{item?.relationship}</td>
                <td>{item?.businesses?.name}</td>
                <td>{item?.spaces?.name}</td>
                {isAdmin === 1 &&<td>{item?.isUser === 0 ? "Invitation Not Sent" : item?.user?.isPassword === 1 && item?.isUser === 1 ? "Package Not Selected" :  "Invitation Sent"}</td>}
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    {profile?.users_access?.includes("read") && (<div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>)}
                    {profile?.users_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
  )
}

export default ClaimSpaces