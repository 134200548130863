import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  deleteBlogs,
  getBlogCategory,
  getBlogs,
  getProfile,
} from "../../store/slices/userSlice";
import moment from "moment"
import Modal from "react-modal";
import { ExternalLink, Eye, Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
const customStyles = {
  content: {
    display: "block"
  },
};
Modal.setAppElement("#root");

const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile=useSelector(getProfile)
  const [id, setId] = useState("");
  const [blogs, setblogs] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [load, setload] = useState(false);

  const [categories, setcategories] = useState(null);
  const [blogcategories, setblogcategories] = useState(null);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("")
  }


  // const Approve= async (id) => {
  //   try {
  //     await dispatch(editBlog({id: id, approved:1, type:"approve"})).unwrap();
      
  //     try {
  //       Blogs();
  //     } catch (rejectedValueOrSerializedError) {
  //       console.log(rejectedValueOrSerializedError);
  //     }
  //   } catch (rejectedValueOrSerializedError) {
  //     console.log(rejectedValueOrSerializedError);
  //   }
  // };


  const [showApproveIcon, setShowApproveIcon] = useState(true);
  const Approve = async (id) => {
    try {

      setShowApproveIcon(false);
      const items = blogs.map((item) =>
        item._id === id ? { ...item, approved: 1 } : item
      );
      setblogs(items);

      await dispatch(({ id, approved: 1, type: "approve" })).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
      setShowApproveIcon(true);
    }
  };
  

  const blogDelete = async (id) => {
    try {
      console.log(id, "id");
      await dispatch(deleteBlogs(id)).unwrap();
      setIsOpen(false);
      try {
        Blogs();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Blogs = async () => {
    try {
      setload(true);
      setblogs(null);
      setFilteredData(null);
      const response = await dispatch(getBlogs()).unwrap();
      setblogs(response?.data);
      setFilteredData(response?.data);
      setload(false);

      const uniqueCreatorsSet = new Set();
      const creatorsData = [];

      response?.data.forEach((category) => {
        uniqueCreatorsSet.add(category.createdBy?._id);
        creatorsData.push(category.createdBy);
      });

      const uniqueCreatorsArray = Array.from(uniqueCreatorsSet);
      setUniqueCreators(uniqueCreatorsArray);

      const creatorsMap = {};
      creatorsData.forEach((creator) => {
        creatorsMap[creator._id] = creator;
      });
      setCreators(creatorsMap);

    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Blogs();
    }
    return () => {
      mount = false;
    };
  }, []);

  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getBlogCategory()).unwrap();
      setcategories(response?.data);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {   
    Categories();
  }, []);


  const [categoryFilter, setcategoryFilter] = useState('');
  const [nameFilter, setnameFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = blogs?.filter(item => {
      console.log(item);

      const category = item?.categoryId?.name.toLowerCase();
      const name = item?.title?.toLowerCase();
      const creatorName = item?.createdBy?.name

      console.log(creatorName, "creatorName");

      return (
        (categoryFilter === '' || category.includes(categoryFilter.toLowerCase())) &&
        (nameFilter === '' || name.includes(nameFilter.toLowerCase())) &&
        (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };
  console.log('selectedUsers', selectedUsers)

  const handleStatusChange = (status) => {
    if (selectedUsers.includes(status)) {
      setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
    } else {
      setselectedUsers(prevStatuses => [...prevStatuses, status]);
    }
  };

  const resetFilter = () => {
    setcategoryFilter('');
    setnameFilter('');
    setselectedUsers([]);
    setFilteredData(blogs)
    settoggle(false);
  };

  console.log('blogs', blogs)
  // console.log('id', id)

  return (
    <>

<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show cw-modal cw-modal-30`}>

        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
        {modalType == "userDetail" ? (
    <>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Blog</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Blog?</p>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button onClick={() => blogDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
            </div>
        </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>

<div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item"><a>Blogs</a></li>
        <li class="breadcrumb-item active" aria-current="page">All Blogs</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Blogs</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown cw-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating cw-input mb-3">
            <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Name" />
            <label for="att-name">Name</label>
          </div>
          <div class="dropdown cw-select-dropdown mb-3">
              <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {categoryFilter?.length > 0 ? categoryFilter : "Category"}
              </a>
              <ul class="dropdown-menu">
                {categories?.length > 0 && categories?.map((c, i) => (
                  <li key={i}><a onClick={() => setcategoryFilter(c?.name)} className="dropdown-item">
                    {c?.name}
                  </a></li>
                ))}
              </ul>
            </div>

            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label font-size-20 pink-color dm-font">Author</label>
              <div class="col-sm-10 d-flex gap-2 flex-wrap">
                {uniqueCreators.map((creatorId, i) => (
                  <>
                    <input type="checkbox" class="btn-check" checked={selectedUsers.includes(creators[creatorId]?.name)} onChange={() => handleStatusChange(creators[creatorId]?.name)} id={`created-by-${i + 1}`} value={creators[creatorId]?.name} />
                    <label class="btn cw-select-btn" for={`created-by-${i + 1}`}>{creators[creatorId]?.name}</label>
                  </>
                ))}

              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
              <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn cw-btn cw-yellow-btn px-3" onClick={()=>navigate("/add-blog")}>Add Blog</button>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Blogs : {filteredData?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Blog Name</th>
              <th scope="col">Category</th>
              <th scope="col">Excerpt</th>
              <th scope="col">Author</th>
              <th scope="col">Date</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) :filteredData?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.title}</td>
                <td>{item?.categoryId?.name}</td>
                <td>{item?.excerpt.substring(0,60)}...</td>
                <td>{item?.createdBy?.name}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                  <Link target="blank" to={`https://www.thecoworkingspaces.com/insights/${item?.categoryId?.name?.replace(/[\/\s]+/g, '-').toLowerCase()}/${item?.slug}`} className={`cw-action-icons l-blue-bg`}><ExternalLink width="18" /></Link>
                  {profile?.role === "admin" && item?.approved === 2 && showApproveIcon && (<div onClick={() => Approve(item?._id, i)} className={`cw-action-icons l-blue-bg`}><ThumbsUp width="18" /></div>)}
                  <Link to={`/edit-blog/${item?._id}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                    <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
  )
}

export default Blogs