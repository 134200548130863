import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllUsers,
  deleteAccount,
  getRequestedQuotes,
  getLandlordProperty
} from "../../store/slices/userSlice";
import moment from "moment"
import Modal from "react-modal";
import { Eye, Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const LandlordProperty = () => {
    const profile=useSelector(getProfile)


    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [properties, setProperties] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [toggle, settoggle] = useState(false);
    const [uniqueCreators, setUniqueCreators] = useState([]);
    const [creators, setCreators] = useState({});
    const [load, setload] = useState(false);
    // const [name, setname] = useState("");
    // const [imageName, setimageName] = useState("");
  
  
    function viewModal(item, type) {
      setIsOpen(true);
      if (type == "userDetail") {
        setUserDetail(item);
        setId(item?._id);
      } else if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    }
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const userDelete = async (id) => {
      try {
        await dispatch(deleteAccount({id: id})).unwrap();
        setIsOpen(false);
        try {
            LandLordProperties();
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const LandLordProperties = async () => {
      try {
        setload(true);
        setProperties(null);
        const response = await dispatch(getLandlordProperty()).unwrap();
        console.log('responsessss', response?.data)
        setProperties(response?.data);
        setload(false);
       
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    useEffect(() => {
      let mount = true;
      if (mount) {
        LandLordProperties();
      }
      return () => {
        mount = false;
      };
    }, []);
    console.log(properties, "properties");

  return (
    <>
    <Modal closeTimeoutMS={500} isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} className={`modal fade show cw-modal`}>
      <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
      {modalType == "userDetail" ? (<>
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Landlord Property Detail</h1>
            <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              <p class="font-size-16 black-color fw-bold mb-0">Name</p>
              <p class="font-size-16 black-color">{userDetail?.contactDetail?.name}</p>
              
              <p class="font-size-16 black-color fw-bold mb-0">Email</p>
              <p class="font-size-16 black-color">{userDetail?.contactDetail?.email}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Phone</p>
              <p class="font-size-16 black-color">{userDetail?.contactDetail?.phone}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Role</p>
              <p class="font-size-16 black-color">{userDetail?.contactDetail?.role}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Property Name</p>
              <p class="font-size-16 black-color">{userDetail?.propertyName}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Property Size</p>
              <p class="font-size-16 black-color">{userDetail?.propertySize?.size} {userDetail?.propertySize?.unit}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Property Type</p>
              <p class="font-size-16 black-color">{userDetail?.type}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Access Days</p>
              <p class="font-size-16 black-color">{userDetail?.accessDays}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Availability Status</p>
              <p class="font-size-16 black-color">{userDetail?.availableStatus}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Address</p>
              <p class="font-size-16 black-color">{userDetail?.location?.address}</p>

              <p class="font-size-16 black-color fw-bold mb-0">City, Country</p>
              <p class="font-size-16 black-color">{userDetail?.location?.city?.name}, {userDetail?.location?.country?.name}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Postcode</p>
              <p class="font-size-16 black-color">{userDetail?.location?.postCode}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Amenities Provided</p>
              <p class="font-size-16 black-color">{userDetail?.amenitiesProvided?.join(", ")}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Special features or unique selling points of the space</p>
              <p class="font-size-16 black-color">{userDetail?.specialFeatures}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Lease Duration</p>
              <p class="font-size-16 black-color">{userDetail?.leaseDuration}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Pricing Structure</p>
              <p class="font-size-16 black-color">{userDetail?.pricingStructure}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Discounts or incentives for coworking tenants</p>
              <p class="font-size-16 black-color">{userDetail?.discountsIncentive}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Which coworking spaces to contact you</p>
              <p class="font-size-16 black-color">{userDetail?.spacesContactYou}</p>

              <p class="font-size-16 black-color fw-bold mb-0">List your property directly on TheCoworkingSpaces for businesses to contact you</p>
              <p class="font-size-16 black-color">{userDetail?.listYourProperty}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Any specific requirements or preferences for potential tenants</p>
              <p class="font-size-16 black-color">{userDetail?.specificRequirments}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Any restrictions or rules for using the space</p>
              <p class="font-size-16 black-color">{userDetail?.restritions}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Any additional services or support provided by the landlord</p>
              <p class="font-size-16 black-color">{userDetail?.additionalServices}</p>
            </div>
          </div>
    </>): modalType == "delete" ? ( 
      <>
      <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Requested Quote</h1>
            <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Requested Quote?</p>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button onClick={() => userDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
            </div>
          </div>
      </>
      ) : (
      <></>
    )}
      </div>
    </Modal>

    <div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Landlord Property</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-12 col-12 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Landlord Property</h2>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Landlord Property : {properties?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Property Name</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th style={{ width: '140px' }} scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : properties?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.propertyName}</td>
                <td>{item?.contactDetail?.name}</td>
                <td>{item?.contactDetail?.email}</td>
                <td>{item?.contactDetail?.phone}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    {profile?.users_access?.includes("read") && (<div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>)}
                    {/* {profile?.users_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)} */}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
  )
}

export default LandlordProperty