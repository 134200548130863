import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addDeskRoomType,
  addFeature,
  deleteDeskRoomType,
  deleteFeature,
  editDeskRoomType,
  getDeskRoomTypes,
  getDeskTypes,
  getFeatureCategories,
  getFeatures,
  getInclusions,
  getPaymentCycle,
  getProfile,
  getRoomTypes
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Pencil, ThumbsUp, Trash2, X } from "lucide-react";
import { Search } from "lucide-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");

const RoomTypes = () => {
    const profile=useSelector(getProfile)
    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [roomTypes, setroomTypes] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
  
    const [name, setname] = useState("");
    const [description, setdescription] = useState("");
    const [longDescription, setlongDescription] = useState("");
    const [keywords, setkeywords] = useState("");
    const [type, settype] = useState("");
    const [inclusion, setinclusion] = useState([]);
    const [paymentType, setpaymentType] = useState([]);
    const [inclusionsList, setinclusionsList] = useState([]);
    const [paymentCycleList, setpaymentCycleList] = useState([]);
    const [imageName, setimageName] = useState("");
    const [feature, setfeature] = useState("");
    const [toggle, settoggle] = useState(false);
    const [load, setload] = useState(false);
  
    const viewModal = async (item, type, feature) => {
      setIsOpen(true);
      console.log("feature", feature);
      console.log("type", type);
  
      if (type == "userDetail" && feature == "add") {
        setId();
        setname();
        settype();
        setimageName();
        setinclusion([]);
        setpaymentType([]);
        setdescription();
        setlongDescription();
        setkeywords();
        setfeature("add")
      }
      if (type == "userDetail" && feature == "edit") {
  
        setfeature("edit")
        setUserDetail(item);
        setname(item?.name);
        setId(item?._id);
        setinclusion(item?.inclusion);
        setpaymentType(item?.paymentCycle);
        setdescription(item?.description);
        setlongDescription(item?.longDescription);
        setkeywords(item?.keywords);
      }
      if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    };
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const roomtypeDelete = async (id) => {
      try {
        await dispatch(deleteDeskRoomType(id)).unwrap();
        setIsOpen(false);
        try {
            RoomType();
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const RoomType = async () => {
      try {
        setload(true);
        setroomTypes(null);
        setFilteredData(null);
        const response = await dispatch(getRoomTypes()).unwrap();
        console.log('responsessss', response?.data)
        setroomTypes(response?.data);
        setFilteredData(response?.data);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
    const Inclusions = async () => {
      try {
        setload(true);
        setinclusionsList(null);
        const response = await dispatch(getInclusions()).unwrap();
        console.log('responsessss', response?.data)
        setinclusionsList(response?.data);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    const PaymentCycles = async () => {
      try {
        setload(true);
        setpaymentCycleList(null);
        const response = await dispatch(getPaymentCycle()).unwrap();
        console.log('responsessss', response?.data)
        setpaymentCycleList(response?.data);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
  
    useEffect(() => {
      let mount = true;
      if (mount) {
        RoomType();
        Inclusions();
        PaymentCycles();
      }
      return () => {
        mount = false;
      };
    }, []);
  
    
  
  
  
  
    const addandEditroomtype = async (e) => {
      e.preventDefault();
  
      try {
  
        const data = new FormData();
        data.append("name", name);
        data.append("behave", "Room");
        data.append("inclusion", JSON.stringify(inclusion));
        data.append("paymentCycle", JSON.stringify(paymentType));
        data.append("roomDeskImage", imageName);
        data.append("description", description);
        data.append("longDescription", longDescription);
        data.append("keywords", keywords);
        if (id) {
        data.append("id", id);
          await dispatch(editDeskRoomType(data)).unwrap();
          setIsOpen(false);
        
        RoomType();
        setId("")     
        } else { 
            await dispatch(addDeskRoomType(data)).unwrap();
            setIsOpen(false);
            RoomType();
             setname("")
             setimageName("")  
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };

    console.log(roomTypes, "roomtypes");  

    const handleInclusionChange = (e) => {
      const { value, checked } = e.target;
      if (checked) {
        setinclusion(prevInclusions => [...prevInclusions, value]);
      } else {
        setinclusion(prevInclusions => prevInclusions.filter(item => item !== value));
      }
    }
    const handlePaymentCycleChange = (e) => {
      const { value, checked } = e.target;
      if (checked) {
        setpaymentType(prevPaymentCycles => [...prevPaymentCycles, value]);
      } else {
        setpaymentType(prevPaymentCycles => prevPaymentCycles.filter(item => item !== value));
      }
    }

    useEffect(() => {
      setinclusion([]);
    }, []);

    const [nameFilter, setnameFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
  
    const applyFilter = () => {
      const filteredResult = roomTypes?.filter(item => {
        const category = item?.name.toLowerCase();
  
        return (
          (nameFilter === '' || category.includes(nameFilter.toLowerCase()))
        );
      });
  
      setFilteredData(filteredResult);
      settoggle(false);
    };
  
    const resetFilter = () => {
      setFilteredData(roomTypes)
      setnameFilter('')
      settoggle(false);
    };

    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
      ['link', 'image', 'video', 'formula'],
  
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      // [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean']                                         // remove formatting button
    ];

    const handleChange = (html) => {
      setlongDescription(html);
    };


  return (
    <>
    <Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show cw-modal ${modalType == "delete" && "cw-modal-30"}`}
>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
  {modalType == "userDetail"  && feature == "add" ? (
    <>
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add Room Types</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                    <div class="form-floating cw-input mb-3">
                      <input type="text" onChange={(e)=>setname(e.target.value)} class="form-control" id="att-name" placeholder="City Name" />
                      <label for="att-name">Name</label>
                    </div>
                        
                <div class="form-floating cw-input mb-3">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                      <label for="att-file">Room Type Icon</label>
                  </div>
                  <div class="form-floating mb-3 cw-textarea">
                  <textarea class="form-control" placeholder="description" id="description" onChange={(e) => setdescription(e.target.value)} maxLength={220} value={description}
                    style={{ height: '80px' }}></textarea>
                  <label for="description">Description</label>
                </div>
                <div class="mb-3 pb-4 cw-html-text-editor">
                  <p class="font-size-16 fw-semibold black-color mb-2">Long Description</p>
                  <ReactQuill
                    theme="snow"
                    value={longDescription}
                    onChange={handleChange}
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    style={{ height: '300px', marginBottom: '45px' }}
                  />
                </div>
                  <div class="form-floating mb-3 mt-3 cw-textarea">
                  <textarea class="form-control" placeholder="keywords" id="keywords" onChange={(e) => setkeywords(e.target.value)} maxLength={220} value={keywords}
                    style={{ height: '100px' }}></textarea>
                  <label for="keywords">Keywords</label>
                </div>
                  <div className="mb-3">
                  <label className="d-block w-100 mb-2">Inclusions</label>
                  <div className="d-flex flex-wrap gap-2">
                  {inclusionsList?.map((data)=> (
                  <>
                    <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} onChange={(e) => handleInclusionChange(e)}/>
                    <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
                  </>
                  ))}
                  </div>
                  </div>

                  <div>
                  <label className="d-block w-100 mb-2">Payment Cycles</label>
                  <div className="d-flex flex-wrap gap-2">
                  {paymentCycleList?.map((data)=> (
                  <>
                    <input class="btn-check" name="payment-cycles" type="checkbox" id={data?._id} value={data?._id} onChange={(e) => handlePaymentCycleChange(e)}/>
                    <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
                  </>
                  ))}
                  </div>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditroomtype} class="btn cw-btn cw-yellow-btn py-2 px-4">Save</button>
          </div>
    </div>
    </> ) : modalType == "userDetail" && feature == "edit" ? ( 
      <>
      <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Edit Room Type</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                    <div class="form-floating cw-input mb-3">
                      <input type="text"  onChange={(e)=>setname(e.target.value)} defaultValue={name} class="form-control" id="att-name" placeholder="Feature Name" />
                      <label for="att-name">Name</label>
                    </div>
                <div class="form-floating cw-input mb-3">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                      <label for="att-file">Room Type Icon</label>
                  </div>
                  <div class="form-floating mb-3 cw-textarea">
                  <textarea class="form-control" placeholder="description" id="description" onChange={(e) => setdescription(e.target.value)} maxLength={220} value={description}
                    style={{ height: '80px' }}></textarea>
                  <label for="description">Description</label>
                </div>
                <div class="mb-3 pb-4 cw-html-text-editor">
                  <p class="font-size-16 fw-semibold black-color mb-2">Long Description</p>
                  <ReactQuill
                    theme="snow"
                    value={longDescription}
                    onChange={handleChange}
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    style={{ height: '300px', marginBottom: '45px' }}
                  />
                </div>
                  <div class="form-floating mb-3 mt-3 cw-textarea">
                  <textarea class="form-control" placeholder="keywords" id="keywords" onChange={(e) => setkeywords(e.target.value)} maxLength={220} value={keywords}
                    style={{ height: '100px' }}></textarea>
                  <label for="keywords">Keywords</label>
                </div>
                  <div className="mb-3">
                  <label className="d-block w-100 mb-2">Inclusions</label>
                  <div className="d-flex flex-wrap gap-2">
                  {inclusionsList?.map((data)=> (
                  <>
                    <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} checked={inclusion?.includes(data?._id)} value={data?._id} onChange={(e) => handleInclusionChange(e)}/>
                    <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
                  </>
                  ))}
                  </div>
                  </div>
                  <div>
                  <label className="d-block w-100 mb-2">Payment Cycles</label>
                  <div className="d-flex flex-wrap gap-2">
                  {paymentCycleList?.map((data)=> (
                  <>
                    <input class="btn-check" name="payment-cycles" type="checkbox" id={data?._id} value={data?._id} checked={paymentType?.includes(data?._id)} onChange={(e) => handlePaymentCycleChange(e)}/>
                    <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
                  </>
                  ))}
                  </div>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
         <button onClick={addandEditroomtype} class="btn cw-btn cw-yellow-btn py-2 px-4">Update</button>
          </div>
    </div>
      </>
      ): modalType == "delete" ? ( 
        <>
            <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Room Type</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Room Type?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
                <button onClick={() => roomtypeDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>

<div className="cw-areas-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Types</a></li>
            <li class="breadcrumb-item active" aria-current="page">Room Types</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Room Types</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown cw-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating cw-input mb-3">
              <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Type Name" />
              <label for="att-name">Type Name</label>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
              <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn cw-btn cw-yellow-btn px-3" onClick={() => viewModal(null, "userDetail", "add")}>Add Room Type</button>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
    <p className="font-size-20 pink-color">Room Types : {roomTypes?.length}</p>
    <div class="table-responsive">
    <table class="table cw-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Icon</th>
      <th scope="col">Created By</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item,i)=> (
    <tr key={i}>
      <td>{i+1}</td>
      <td>{item?.name}</td>
      <td><img src={`${process.env.REACT_APP_IMGURL}/${item?.imageName}`} width={50} alt="" /></td>
      <td>{item?.createdBy?.name}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            <div onClick={() => viewModal(item, "userDetail","edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
            <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>
    </>
  )
}

export default RoomTypes