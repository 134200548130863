import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  deleteAccount,
  getBusinessess,
  aproveBusiness,
  InviteBusinessOwner,
  getPackages,
  ResendInviteBusinessOwner,
  verifiedBusiness,
  getBusinessCountries,
  getBusinessCountrySpaces,
  changeBusinessName
} from "../../store/slices/userSlice";
import moment from "moment"
import Modal from "react-modal";
import { Eye, Pencil, ReplaceAll, Search, ThumbsUp, Trash2, X } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const Buisnesses = () => {
  const profile = useSelector(getProfile)
  const navigate = useNavigate()
  const location = useLocation()


  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [buisnesses, setbuisnesses] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [isAdmin, setisAdmin] = useState(null);
  const [search, setsearch] = useState("");
  const [searchEmail, setsearchEmail] = useState("");
  const [totalCount, settotalCount] = useState("");
  const [packages, setpackages] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);
  // const [name, setname] = useState("");
  // const [imageName, setimageName] = useState("");

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  

  const [businessId, setbusinessId] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [countryId, setcountryId] = useState("");
  const [currency, setcurrency] = useState("");

  const [countries, setcountries] = useState(null);
  const [countrySpaces, setcountrySpaces] = useState(null);
  
  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setId(item?._id);
    } else if (type == "user") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    } else if (type === "changeBusiness") {
      setUserDetail(item);
      setbusinessId(item?._id);
      setbusinessName("")
      setcountryId("")
      setcurrency("")
      setcountrySpaces(null)
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const userDelete = async (id) => {
    try {
      await dispatch(deleteAccount({id: id})).unwrap();
      setIsOpen(false);
      try {
        BuisnessesList();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const AproveBusiness = async (id) => {
    try {
        const spaceIndex = buisnesses.findIndex(space => space?._id === id);
        if (spaceIndex !== -1) {
            const updatedSpaces = [...buisnesses];
            updatedSpaces[spaceIndex].isApproved = updatedSpaces[spaceIndex].isApproved === 1 ? 0 : 1;
            setbuisnesses(updatedSpaces);
        }
        await dispatch(aproveBusiness(id)).unwrap();
    } catch (error) {
        console.log(error);
    }
  };

  const VerifiedBusiness = async (id) => {
    try {
        const spaceIndex = buisnesses.findIndex(space => space?._id === id);
        if (spaceIndex !== -1) {
            const updatedSpaces = [...buisnesses];
            updatedSpaces[spaceIndex].isVerify = updatedSpaces[spaceIndex].isVerify === 1 ? 0 : 1;
            setbuisnesses(updatedSpaces);
        }
        await dispatch(verifiedBusiness(id)).unwrap();
    } catch (error) {
        console.log(error);
    }
  };

  const Packages = async () => {
    try {
      setpackages(null);
      const response = await dispatch(getPackages()).unwrap();
      setpackages(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const BusinessCountries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getBusinessCountries(businessId)).unwrap();
      setcountries(response?.data?.[0]?.countries);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    if(businessId){
      BusinessCountries();
    }
  }, [businessId]);

  const BusinessCountrySpaces = async () => {
    try {
      setcountrySpaces(null);
      const response = await dispatch(getBusinessCountrySpaces({countryId: countryId, businessId: businessId})).unwrap();
      setcountrySpaces(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if(countryId){
      BusinessCountrySpaces();
      const findCountry = countries?.find(item => item?._id === countryId);
      setcurrency(findCountry?.currency)
      const newName = `${userDetail?.name} - ${findCountry?.name}`
      setbusinessName(newName)
    }
  }, [countryId]);

  useEffect(() => {
    Packages();
  }, []);



  const addUser = async (id) => {
    try {
      const payload ={
        businessId: id,
        name: name,
        email: email,
      }      
      await dispatch(InviteBusinessOwner(payload)).unwrap();     
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const HandleResendInvite = async (id) => {
    try { 
      await dispatch(ResendInviteBusinessOwner({id})).unwrap();     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  
  const [nameFilter, setnameFilter] = useState('');
  const [emailFilter, setemailFilter] = useState('');
  const applyFilter = () => {
    setsearch(nameFilter)
    setsearchEmail(emailFilter)
    settoggle(false);
    navigate(`${location.pathname}`);
  }
  const resetFilter = () => {
    setnameFilter('');
    setemailFilter('');
    setsearch('');
    setsearchEmail('');
    settoggle(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  const renderPaginationLinks = () => {
    const pages = [];
    const maxPagesToShow = 5; 
  
    if (totalPages <= maxPagesToShow) {
  
      for (let page = 1; page <= totalPages; page++) {
        pages.push(renderPageLink(page));
      }
    } else {
     
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    
      
      if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }
  
     
      pages.push(renderPageLink(1));
  
      
      if (startPage > 2) {
        pages.push(renderEllipsis());
      }
  
      
      for (let page = startPage === 1 ? 2 : startPage; page <= endPage; page++) {
        pages.push(renderPageLink(page));
      }
  
      
      if (endPage < totalPages - 1) {
        pages.push(renderEllipsis());
      }
  
    
      if (endPage !== totalPages) {
        pages.push(renderPageLink(totalPages));
      }
    }
  
    return pages;
  };
  
  
  const renderPageLink = (page) => (
    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
      <a className="page-link" onClick={() => handlePageChange(page)}>
        {page}
      </a>
    </li>
  );
  
  const renderEllipsis = () => (
    <li key="ellipsis" className="page-item disabled">
      <span className="page-link">...</span>
    </li>
  );

  const BuisnessesList = async () => {
    try {
      setload(true);
      setbuisnesses(null);
      const response = await dispatch(getBusinessess({isAdmin: isAdmin, page: currentPage, limit: 50, search: search, searchEmail: searchEmail })).unwrap();
      setbuisnesses(response?.data);
      settotalCount(response?.totalCount);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    if (location.pathname === "/admin-businesses") {
      setisAdmin(1);
    } else if (location.pathname === "/owner-businesses") {
      setisAdmin(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isAdmin !== null) {
      BuisnessesList();
    }
  }, [isAdmin, currentPage, search, searchEmail]);


  const changeBusiness = async () => {
    try {
      const spacesIds = countrySpaces?.map(item => (item?._id))
      const payload = {
        countryId: countryId,
        businessName: businessName,
        currency: currency,
        type: "652e2474fc9208d21a338807",
        spaces: spacesIds,
      }      
      console.log(payload, "changeBusinessPayload");
      await dispatch(changeBusinessName(payload)).unwrap();     
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log(countries, "countries");
  console.log(countrySpaces, "countrySpaces");
  console.log(countryId, "countryId");
  console.log(businessId, "businessId");
  

  return (
    <>

<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
className={`modal fade show cw-modal cw-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
        {modalType == "changeBusiness" ? (
    <>
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Change Business for {userDetail?.name}</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              {countries?.length > 1 ? <>
            <select value={countryId} onChange={(e) => setcountryId(e.target.value)} className="form-select py-3 cw-select-field mb-3" style={{ paddingLeft: '10px', height:'fit-content' }}>
            <option value="">Select Country</option>
            {countries?.map((data, i) => (
            <option key={i} value={data?._id}>{data?.name}</option>
            ))}
          </select>
            <div class="form-floating cw-input">
                <input type="text" class="form-control" id="name" placeholder="Name" value={businessName} onChange={(e) => setbusinessName(e.target.value)}/>
                <label for="name">New Business Name</label>
            </div>
            </> : <>
            <p>There is Additional Country</p>
            </>}
            </div>
            {countries?.length > 1 ?
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={() => changeBusiness()}>Submit</button>
            </div> : null}
        </div>
      </>
      ): modalType == "user" ? ( 
        <>
          <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Add User for {userDetail?.name}</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
            <div class="form-floating cw-input mb-3">
                <input type="text" class="form-control" id="name" placeholder="Name" onChange={(e) => setname(e.target.value)}/>
                <label for="name">Name</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="email" class="form-control" id="email" placeholder="Email" onChange={(e) => setemail(e.target.value)}/>
                <label for="email">Email</label>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={() => addUser(id)}>Submit</button>
            </div>
        </div>
        </>): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete User</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this User?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn-2 cw-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
                <button onClick={() => userDelete(id)} class="btn cw-btn-2 cw-pink-btn py-2 px-4 rounded-2">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>

<div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Businesses</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Businesses by {isAdmin === 0 ? "Owner" : "Admin"}</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown cw-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating cw-input mb-3">
              <input type="text" onChange={(e)=> setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="space-name" placeholder="Space Name" />
              <label for="space-name">Business Name</label>
            </div>
          <div class="form-floating cw-input mb-3">
              <input type="email" onChange={(e)=> setemailFilter(e.target.value)} value={emailFilter} class="form-control" id="email-filter" placeholder="Email" />
              <label for="email-filter">Business Email</label>
            </div>
            {/* <div class="form-floating cw-input mb-3">
              <input type="date" class="form-control" id="date-filter" placeholder="Date" onChange={(e) => setdateFilter(e.target.value)} value={dateFilter}/>
              <label for="date-filter">Date</label>
            </div>
            <div class="mb-3 sp-input-filter">
              <p class="font-size-20 fw-semibold mb-2">Packages</p>
              <div class="d-flex gap-2 flex-wrap">
                {packages?.map((data, i) => (
                  <>
                    <input type="checkbox" class="btn-check" checked={packageFilter.includes(data?._id)} onChange={() => handlePackageFilter(data?._id)} id={data?._id} value={data?.name} />
                    <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
                  </>
                ))}
              </div>
            </div>
              <div class="mb-3 sp-input-filter">
              <p class="font-size-20 fw-semibold mb-2">Approved / Accessed / Admin / Non Admin</p>
              <div class="d-flex gap-2 flex-wrap">
                <input type="checkbox" class="btn-check" id="approved" checked={isApprovedFilter} onChange={() => setIsApprovedFilter(!isApprovedFilter)} />
                <label class="btn cw-select-btn" for="approved">Approved</label>

                {isAdmin === 0 && <><input type="checkbox" class="btn-check" id="not-approved" checked={isNotApprovedFilter} onChange={() => setIsNotApprovedFilter(!isNotApprovedFilter)} />
                <label class="btn cw-select-btn" for="not-approved">Not Approved</label></>}

                <input type="checkbox" class="btn-check" id="accessed" checked={isAccessedFilter} onChange={() => setIsAccessedFilter(!isAccessedFilter)} />
                <label class="btn cw-select-btn" for="accessed">Accessed</label>
              </div>
            </div> */}
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
              <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Businesses : {totalCount}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Country</th>
              <th scope="col">Spaces</th>
              {isAdmin === 0 && <th scope="col">Email</th>}
              {isAdmin === 0 && <th scope="col">Package</th>}
              {isAdmin === 0 && <th scope="col">Verified</th>}
              <th scope="col">Date</th>
              {isAdmin === 1 && <th scope="col">Invite</th>}
              {isAdmin === 0 &&<th scope="col" style={{ width: '50px' }}>Approve</th>}
              <th scope="col" style={{ width: '110px' }}>CW Verified</th>
              {isAdmin === 1 &&<th scope="col" style={{ width: '50px' }}>Action</th>}
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={11}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : buisnesses?.map((item, i) => (
              <tr key={i}>
                <td style={{verticalAlign: "middle"}}>{i + startIndex + 1}</td>
                <td style={{verticalAlign: "middle"}}>{item?.name}</td>
                <td style={{verticalAlign: "middle"}}>{item?.country?.name}</td>
                <td style={{verticalAlign: "middle"}}>{item?.noOfSpaces}</td>
                {isAdmin === 0 &&<td style={{verticalAlign: "middle"}}>{item?.businessOwner?.email}</td>}
                {isAdmin === 0 &&<td style={{verticalAlign: "middle"}}>{item?.package?.name ? item?.package?.name : "Not Selected"}</td>}
                {isAdmin === 0 &&<td style={{verticalAlign: "middle"}}>{item?.businessOwner?.isVerified === 1 ? "Yes" : "No"}</td>}
                <td style={{verticalAlign: "middle"}}>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                {isAdmin === 1 &&<td style={{verticalAlign: "middle"}}>{item?.businessOwner?.isPassword === 0 ? <button onClick={() => HandleResendInvite(item?.businessOwner?._id)} disabled={item?.businessOwner?.isPassword === 1 && item?.isPackage === 0} className="btn cw-btn cw-yellow-btn px-3 py-1">Resend Invite</button> :<button disabled={item?.businessOwner?.isPassword === 1 && item?.isPackage === 0} onClick={() => viewModal(item, "user")} className="btn cw-btn cw-yellow-btn px-3 py-1">Invite</button>}</td>}
                {isAdmin === 0 &&<td style={{verticalAlign: "middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    {/* {profile?.system_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)} */}
                    <div class="form-check form-switch cw-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="business-aproved" disabled={item?.businessOwner?.isVerified === 0} checked={item?.isApproved == 1} onChange={()=> AproveBusiness(item?._id)}/>
                      <label class="form-check-label" for="business-aproved"></label>
                    </div>
                  </div>
                </td>}
                <td style={{verticalAlign: "middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    {/* {profile?.system_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)} */}
                    <div class="form-check form-switch cw-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="business-aproved" checked={item?.isVerify == 1} onChange={()=> VerifiedBusiness(item?._id)}/>
                      <label class="form-check-label" for="business-aproved"></label>
                    </div>
                  </div>
                </td>
                {isAdmin === 1 &&<td style={{verticalAlign: "middle"}}>
                  <div className="d-flex align-items-center gap-2">
                  <div onClick={() => viewModal(item, "changeBusiness")} className={`cw-action-icons`}><ReplaceAll width="18" /></div>
                  </div>
                </td>}
              </tr>))}
          </tbody>
        </table>
      </div>
      <div className="cw-pagination" aria-label="Page navigation example">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
          <a className="page-link" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {renderPaginationLinks()}
        <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
          <a className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </div>
    </div>
  </div>
    </>
  )
}

export default Buisnesses