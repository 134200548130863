import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCoworkingTypes,
    addDeskRoomType,
  addFeature,
  deleteCoworkingTypes,
  deleteDeskRoomType,
  deleteFeature,
  editCoworkingTypes,
  getCoworkingTypes,
  getDeskcoworkingTypes,
  getDeskTypes,
  getFeatureCategories,
  getFeatures,
  getProfile,
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Pencil, ThumbsUp, Trash2, X } from "lucide-react";
import { Search } from "lucide-react";
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");

const CoworkingTypes = () => {
    const profile=useSelector(getProfile)
    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [coworkingTypes, setcoworkingTypes] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
  
    const [name, setname] = useState("");
    const [type, settype] = useState("");
    const [imageName, setimageName] = useState("");
    const [feature, setfeature] = useState("");
    const [toggle, settoggle] = useState(false);
    const [load, setload] = useState(false);
  
    const viewModal = async (item, type, feature) => {
      setIsOpen(true);
      console.log("feature", feature);
      console.log("type", type);
  
      if (type == "userDetail" && feature == "add") {
        setId();
        setname();
        settype();
        setimageName();
        setfeature("add")
      }
      if (type == "userDetail" && feature == "edit") {
  
        setfeature("edit")
        setUserDetail(item);
        setId(item?._id);
      }
      if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    };
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const coworkingtypeDelete = async (id) => {
      try {
        await dispatch(deleteCoworkingTypes(id)).unwrap();
        setIsOpen(false);
        try {
          CoworkingType();
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const CoworkingType = async () => {
      try {
        setload(true);
        setcoworkingTypes(null);
        setFilteredData(null);
        const response = await dispatch(getCoworkingTypes()).unwrap();
        console.log('responsessss', response?.data)
        setcoworkingTypes(response?.data);
        setFilteredData(response?.data);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
  
    useEffect(() => {
      let mount = true;
      if (mount) {
        CoworkingType();
      }
      return () => {
        mount = false;
      };
    }, []);
  
    
  
  
  
  
    const addandEditcoworkingtype = async (e) => {
      e.preventDefault();
  
      try {
  
        const data = new FormData();
        data.append("name", name);
        // data.append("roomDeskImage", imageName);
  
      
        if (id) {
        data.append("id", id);
          await dispatch(editCoworkingTypes(data)).unwrap();
          setIsOpen(false);
        
        CoworkingType();
        setId("")     
        } else { 
            await dispatch(addCoworkingTypes(data)).unwrap();
            setIsOpen(false);
            CoworkingType();
             setname("")
             setimageName("")  
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };

    console.log(coworkingTypes, "coworkingTypes");  

    const [nameFilter, setnameFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
  
    const applyFilter = () => {
      const filteredResult = coworkingTypes?.filter(item => {
        const category = item?.name.toLowerCase();
  
        return (
          (nameFilter === '' || category.includes(nameFilter.toLowerCase()))
        );
      });
  
      setFilteredData(filteredResult);
      settoggle(false);
    };
  
    const resetFilter = () => {
      setFilteredData(coworkingTypes)
      setnameFilter('')
      settoggle(false);
    };
  return (
    <>
    <Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show cw-modal cw-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
  {modalType == "userDetail"  && feature == "add" ? (
    <>
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add Coworking Types</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                    <div class="form-floating cw-input mb-3">
                      <input type="text" onChange={(e)=>setname(e.target.value)} class="form-control" id="att-name" placeholder="City Name" />
                      <label for="att-name">Name</label>
                    </div>
                        
                {/* <div class="form-floating cw-input">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                      <label for="att-file">Coworking Type Icon</label>
                  </div> */}
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditcoworkingtype} class="btn cw-btn cw-yellow-btn py-2 px-4">Save</button>
          </div>
    </div>
    </> ) : modalType == "userDetail" && feature == "edit" ? ( 
      <>
      <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Edit Coworking Type</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                    <div class="form-floating cw-input mb-3">
                      <input type="text" disabled={profile?.systemconfig_access?.includes("write")?false:true}  onChange={(e)=>setname(e.target.value)} defaultValue={userDetail?.name} class="form-control" id="att-name" placeholder="Feature Name" />
                      <label for="att-name">Name</label>
                    </div>
                {/* <div class="form-floating cw-input">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                      <label for="att-file">Coworking Type Icon</label>
                  </div> */}
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
         <button onClick={addandEditcoworkingtype} class="btn cw-btn cw-yellow-btn py-2 px-4">Update</button>
          </div>
    </div>
      </>
      ): modalType == "delete" ? ( 
        <>
            <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Coworking Type</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Coworking Type?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
                <button onClick={() => coworkingtypeDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>

<div className="cw-areas-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Types</a></li>
            <li class="breadcrumb-item active" aria-current="page">Coworking Types</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Coworking Types</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown cw-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating cw-input mb-3">
              <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Type Name" />
              <label for="att-name">Type Name</label>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
              <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn cw-btn cw-yellow-btn px-3" onClick={() => viewModal(null, "userDetail", "add")}>Add Coworking Type</button>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
    <p className="font-size-20 pink-color">Coworking Types : {coworkingTypes?.length}</p>
    <div class="table-responsive">
    <table class="table cw-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Icon</th>
      <th scope="col">Created By</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item,i)=> (
    <tr key={i}>
      <td>{i+1}</td>
      <td>{item?.name}</td>
      <td><img src={`${process.env.REACT_APP_IMGURL}/${item?.imageName}`} width={50} alt="" /></td>
      <td>{item?.createdBy?.name}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            <div onClick={() => viewModal(item, "userDetail","edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
            <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>
    </>
  )
}

export default CoworkingTypes