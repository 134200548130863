import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFeature,
  deleteFeature,
  editFeature,
  getFeatureCategories,
  getFeatures,
  getProfile
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Pencil, ThumbsUp, Trash2, X } from "lucide-react";
import { Search } from "lucide-react";
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");

const Features = () => {
  const profile=useSelector(getProfile)
    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [features, setfeatures] = useState(null);
    const [categories, setcategories] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
  
    const [featureName, setfeatureName] = useState("");
    const [category, setcategory] = useState("");
    const [imageName, setimageName] = useState("");
    const [feature, setfeature] = useState("");
    const [toggle, settoggle] = useState(false);
    const [load, setload] = useState(false);
  
    const viewModal = async (item, type, feature) => {
      setIsOpen(true);
      console.log("feature", feature);
      console.log("type", type);
  
      if (type == "userDetail" && feature == "add") {
        setId();
        setfeatureName();
        setcategory();
        setimageName();
        setfeature("add")
      }
      if (type == "userDetail" && feature == "edit") {
  
        setfeature("edit")
        setUserDetail(item);
        setId(item?._id);
      }
      if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    };
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const featureDelete = async (id) => {
      try {
        await dispatch(deleteFeature(id)).unwrap();
        setIsOpen(false);
        try {
          Features();
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const Features = async () => {
      try {
        setload(true);
        setfeatures(null);
        setFilteredData(null);
        const response = await dispatch(getFeatures()).unwrap();
        console.log('responsessss', response?.data)
        setfeatures(response?.data);
        setFilteredData(response?.data);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };

    const FeatureCategories = async () => {
      try {
        setcategories(null);
        const response = await dispatch(getFeatureCategories()).unwrap();
        setcategories(response?.data);
       
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
  
  
    useEffect(() => {
      let mount = true;
      if (mount) {
        Features();
        FeatureCategories()
      }
      return () => {
        mount = false;
      };
    }, []);
  
    
  
  
  
  
    const addandEditfeature = async (e) => {
      e.preventDefault();
  
      try {
  
        const data = new FormData();
        data.append("id", id);
        data.append("name", featureName);
        data.append("featureCategoryId", category);
        data.append("featureImage", imageName);
  
      
        if (id) {
          await dispatch(editFeature(data)).unwrap();
          setIsOpen(false);
        
        Features();
        setId("")     
        } else { 
            await dispatch(addFeature(data)).unwrap();
            setIsOpen(false);
            Features();
             setfeatureName("")
             setcategory("")
             setimageName("")  
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };

    console.log(features, "features");  
  

  
    useEffect(() => {
      setcategory(userDetail?.featureCategoryId?._id)
  }, [userDetail])

  const [nameFilter, setnameFilter] = useState('');
  const [categoryFilter, setcategoryFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = features?.filter(item => {
      const name = item?.name.toLowerCase();
      const category = item?.featureCategoryId?.name.toLowerCase();

      return (
        (nameFilter === '' || name.includes(nameFilter.toLowerCase())) && 
        (categoryFilter === '' || category.includes(categoryFilter.toLowerCase()))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };

  const resetFilter = () => {
    setFilteredData(features)
    setnameFilter('')
    setcategoryFilter('')
    settoggle(false);
  };

  return (
    <>
    <Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show cw-modal cw-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
  {modalType == "userDetail"  && feature == "add" ? (
    <>
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add Feature</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="row mb-3">
                    <div class="col-sm-6">
                    <div class="form-floating cw-input">
                      <input type="text" onChange={(e)=>setfeatureName(e.target.value)} class="form-control" id="att-name" placeholder="City Name" />
                      <label for="att-name">Feature Name</label>
                    </div>
                        
                    </div>
                    <div class="col-sm-6">
                    <div class="dropdown cw-select-dropdown">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {category ? categories.find(user => user?._id === category)?.name : "Category"}
                      </a>
                      <ul class="dropdown-menu">
                      {categories?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                    </div>
                    </div>
                </div>
                <div class="form-floating cw-input">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                      <label for="att-file">Feature Banner</label>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditfeature} class="btn cw-btn cw-yellow-btn py-2 px-4">Save</button>
          </div>
    </div>
    </> ) : modalType == "userDetail" && feature == "edit" ? ( 
      <>
      <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Edit Feature</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="row mb-3">
                    <div class="col-sm-6">
                    <div class="form-floating cw-input">
                      <input type="text"  onChange={(e)=>setfeatureName(e.target.value)} defaultValue={userDetail?.name} class="form-control" id="att-name" placeholder="Feature Name" />
                      <label for="att-name">Feature Name</label>
                    </div>
                        
                    </div>
                    <div class="col-sm-6">
                    <div class="dropdown cw-select-dropdown">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {category ? categories.find(user => user?._id === category)?.name : "Category"}
                      </a>
                      <ul class="dropdown-menu">
                      {categories?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                    </div>
                    </div>
                </div>
                <div class="form-floating cw-input">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                      <label for="att-file">Feature Banner</label>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
         <button onClick={addandEditfeature} class="btn cw-btn cw-yellow-btn py-2 px-4">Update</button>
          </div>
    </div>
      </>
      ): modalType == "delete" ? ( 
        <>
            <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Feature</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Feature?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
                <button onClick={() => featureDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>

<div className="cw-areas-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Features</a></li>
            <li class="breadcrumb-item active" aria-current="page">All Features</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Features</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
          <div class="dropdown cw-search-dropdown">
            <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
              <span>Filter and Search</span>
              <span>{toggle ? <X /> : <Search />}</span>
            </a>

            <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Feature Name" />
                <label for="att-name">Feature Name</label>
              </div>
              <div class="dropdown cw-select-dropdown mb-3">
                  <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {categoryFilter ? categoryFilter : "Category"}
                  </a>
                  <ul class="dropdown-menu">
                  {categories?.map((c,i)=>(
                    <li key={i}><a onClick={(e)=>setcategoryFilter(c?.name)} class="dropdown-item">{c?.name}</a></li>
                    ))}
                  </ul>
                </div>
              <div className="d-flex gap-2 justify-content-end">
                <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
                <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
              </div>
            </ul>
          </div>
        </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn cw-btn cw-yellow-btn px-3" onClick={() => viewModal(null, "userDetail", "add")}>Add Feature</button>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
    <p className="font-size-20 pink-color">Features : {features?.length}</p>
    <div class="table-responsive">
    <table class="table cw-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Category</th>
      <th scope="col">Icon</th>
      <th scope="col">Created By</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item,i)=> (
    <tr key={i}>
      <td>{i+1}</td>
      <td>{item?.name}</td>
      <td>{item?.featureCategoryId?.name}</td>
      <td><img src={`${process.env.REACT_APP_IMGURL}/${item?.imageName}`} width={50} alt="" /></td>
      <td>{item?.createdBy?.name}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
            <div onClick={() => viewModal(item, "userDetail","edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
            <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>
    </>
  )
}

export default Features