import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { CheckCheck, FileAudio, FileText, FileVideo, MoreVertical, Paperclip, Search, Send, SmilePlus, X, } from 'lucide-react'
import { Image } from 'lucide-react'
import { PlusCircle } from 'lucide-react'
// import Lightbox from 'react-image-lightbox';
import { DownloadCloud } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux';
import { getSocket } from '../../store/slices/socketSlice';
import { getProfile, sendTheAttachment } from '../../store/slices/userSlice';
import moment from 'moment';
// import 'react-image-lightbox/style.css';
// import data from '@emoji-mart/data'
// import Picker from '@emoji-mart/react'

const ChatInbox = ({ currentChat, getAllChats, startingToggle, setstartingToggle, messageChat }) => {
    const dummyImage = "https://via.placeholder.com/50x50"
    const [toggle, settoggle] = useState(false);
    const [toggle1, settoggle1] = useState(false);
    const [toggle2, settoggle2] = useState(false);
    const [photoIndex, setphotoIndex] = useState(0)
    const [messages, setmessages] = useState(null)
    const [attachments, setattachments] = useState(null)
    const [messageText, setmessageText] = useState("")
    const [isOpen, setisOpen] = useState(false)
    const lastMessageRef = useRef(null);
    const [searchtext, setsearchtext] = useState("");
    const [filteredData, setfilteredData] = useState();
    const [photoFiles, setPhotoFiles] = useState([]);
    const [otherFile, setOtherFile] = useState(null);
    const [extension, setextension] = useState(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [randomNumber, setrandomNumber] = useState(null);



    const socket = useSelector(getSocket);
    const profile = useSelector(getProfile);
    const dispatch = useDispatch()

    // const handleEmojiSelect = (emoji) => {
    //     // Append the selected emoji to the text content
    //     setmessageText((prevText) => prevText + emoji.native);
    // };

    const handleMessageChange = (e) => {
        setmessageText(e.target.value);
    };
    const sendAttachment = async () => {
        try {
            const form = new FormData();
            photoFiles.length > 0 ? photoFiles.forEach((item) => {
                form.append("chatAttachments", item);

            }) : [otherFile].forEach((item) => {
                form.append("chatAttachments", item);

            })
            const response = await dispatch(sendTheAttachment(form)).unwrap();
            setattachments(response?.data)
            return response?.data
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    const sendMessage = async () => {

        if (startingToggle) {
            socket?.emit("get_messages", {
                sender_id: profile?._id,
                receiver_id: messageChat ? messageChat?.userId?._id : currentChat?.receiver_id? (profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id) : currentChat._id,
                group_id:  messageChat?.group_id ? messageChat?.group_id : currentChat?.group_id ? currentChat?.group_id : null,
            });

            setstartingToggle(!startingToggle)
        }
        if (extension) {

            const data = await sendAttachment()
            scrollToBottom();
            socket?.emit("send_message", {
                sender_id: profile?._id,
                receiver_id: messageChat ? messageChat?.userId?._id : currentChat?.receiver_id ? (profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id) : currentChat._id,
                message: attachments,
                type: extension,
                attachment: data,
                senderType: "admin",
                receiverType: "user",
                businessId: messageChat ? messageChat?.business?._id : currentChat?.businessId?._id,
                group_id: messageChat?.group_id ? messageChat?.group_id : currentChat?.group_id ? currentChat?.group_id : null,
            });

            setPhotoFiles(null)
            setOtherFile(null)
            setextension(null)
            setattachments(null)
        } else {
            scrollToBottom();
            socket?.emit("send_message", {
                sender_id: profile?._id,
                receiver_id: messageChat ? messageChat?.userId?._id : currentChat?.sender_id ? profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id : currentChat._id,
                message: messageText,
                senderType:"admin",
                receiverType: "user",
                businessId: messageChat ? messageChat?.business?._id : currentChat?.businessId?._id,
                group_id:  messageChat?.group_id ? messageChat?.group_id : currentChat?.group_id ? currentChat?.group_id : null,
            });
            setmessageText("")
        }

    };

    const callMessage = () => {
        socket?.emit("get_messages", {
            sender_id: profile?._id,
            receiver_id: currentChat?.sender_id ? (profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?._id : currentChat?.sender_id[0]?._id) : currentChat._id,
            group_id: currentChat?.group_id ? currentChat?.group_id : null,
        });
        socket?.on("response", (data) => {

            if (data?.object_type == "get_messages") {


                console.log("get_messages data", data)

                setmessages(data?.data);
                setfilteredData(data?.data);



            } else if (data?.object_type == "get_message") {

                console.log("new data get message", data)


                setrandomNumber(Math.floor(100000 + Math.random() * 900000))
                setmessages((prevMessages) => [...prevMessages, data?.data])
                setfilteredData((prevMessages) => [...prevMessages, data?.data])
                socket?.emit("unread_messages", {
                    sender_id: profile?._id,
                    receiver_id: profile?._id == currentChat?.sender_id?._id ? currentChat?.receiver_id?._id : currentChat?.sender_id?._id,
                });
                getAllChats();

            }
        });
    };

    const callMessage2 = () => {
        socket?.emit("get_messages", {
            sender_id: profile?._id,
            receiver_id: messageChat?.userId ? messageChat?.userId?._id : null,
            group_id: messageChat?.group_id ? messageChat?.group_id : null,
        });
        socket?.on("response", (data) => {

            if (data?.object_type == "get_messages") {


                console.log("get_messages data", data)

                setmessages(data?.data);
                setfilteredData(data?.data);



            } else if (data?.object_type == "get_message") {

                console.log("new data get message", data)
                setrandomNumber(Math.floor(100000 + Math.random() * 900000))
                setmessages((prevMessages) => [...prevMessages, data?.data])
                setfilteredData((prevMessages) => [...prevMessages, data?.data])
                // socket?.emit("unread_messages", {
                //     sender_id: profile?._id,
                //     receiver_id: profile?._id == currentChat?.sender_id?._id ? currentChat?.receiver_id?._id : currentChat?.sender_id?._id,
                // });
                getAllChats();

            }
        });
    };




    useEffect(() => {
        if(currentChat){
            callMessage();
            return () => {
                // Remove scroll event listener when component unmounts
                socket?.off('response');
            };
        }
    }, [socket, currentChat]);


    useEffect(() => {
        if(messageChat){
        callMessage2();
        return () => {
            socket?.off('response');
        };
        }
    }, [socket, messageChat]);





    useEffect(() => {
        if (currentChat) {
            lastMessageRef.current.scrollTop = lastMessageRef?.current?.scrollHeight;
        }
    }, [currentChat, messageChat]);

    const scrollToBottom = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollTop = lastMessageRef.current.scrollHeight;
        }
    };
    useLayoutEffect(() => {
        // Scroll to the bottom when the component mounts or when currentChat changes
        scrollToBottom();
    }, [currentChat, filteredData, messageChat]);


    const handleSearch = () => {
        if (!searchtext) {
            setfilteredData(messages);
        } else {
            const filters = filteredData?.filter((i) => {
                const namematches = i?.message?.toLowerCase().includes(searchtext?.toLowerCase());
                return namematches;
            });

            setfilteredData(filters);
        }
    };
    const unreadAllMessages = async (data) => {
        try {

            // socket?.emit("unread_messages", {
            //     sender_id: profile?._id,
            //     receiver_id: profile?._id == currentChat?.sender_id?._id ? currentChat?.receiver_id?._id : currentChat?.sender_id?._id,
            // });
            // getAllChats()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const unreadAllMessagesIfAtBottom = async (data) => {
        // Call unreadAllMessages only if the user is at the bottom
        if (isAtBottom) {
            await unreadAllMessages(data);
        }
    };
    useEffect(() => {
        unreadAllMessagesIfAtBottom()
    }, [randomNumber])

    console.log("isAtBottom", isAtBottom)
    useEffect(() => {
        handleSearch()
    }, [searchtext]);



    const handleFileChange = (event, fileType) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const type = getFileType(selectedFile);
            setextension(type);
        }
        if (fileType === 'image') {
            setPhotoFiles((prevFiles) => [...prevFiles, ...event.target.files]);
        } else {
            setOtherFile(selectedFile);
        }
    };

    // Map file extensions to corresponding icons
    const fileIcons = {
        image: <Image width={22} height={22} stroke={"var(--color3)"} />,
        document: <FileText width={22} height={22} stroke={"var(--color3)"} />,
        audio: <FileAudio width={22} height={22} stroke={"var(--color3)"} />,
        video: <FileVideo width={22} height={22} stroke={"var(--color3)"} />,
    };

    const getFileType = (file) => {
        const extension = file.name.split('.').pop().toLowerCase();

        if (extension.match(/(jpg|jpeg|png|gif|bmp)$/)) {

            return 'image';
        } else if (extension.match(/(doc|docx|ppt|pptx|txt|pdf|xls|xlsx)$/)) {
            return 'document';
        } else if (extension.match(/(mp3)$/)) {
            return 'audio';
        } else if (extension.match(/(mp4|mov|avi|mkv)$/)) {
            return 'video';
        } else {
            return 'other';
        }
    };

console.log(currentChat, "currentChat");
console.log(messageChat, "messageChat");
console.log(filteredData, "filteredData");
console.log(socket, "socket");

const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent newline insertion
      sendMessage();
    }
  };


    return (
        <>

            <div class="py-2 px-4 yellow-bg">
                <div style={{marginBottom:'5px'}} class="d-flex align-items-center gap-3 flex-wrap">
                    <div class="flex-grow-1 d-flex gap-2 align-items-center ">
                    <img src={messageChat ? "" : profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?.imageName ? `${process.env.REACT_APP_IMGURL}${currentChat?.receiver_id[0]?.imageName}` : dummyImage : currentChat?.sender_id[0]?.imageName ?  `${process.env.REACT_APP_IMGURL}${currentChat?.sender_id[0]?.imageName}` : dummyImage } class="rounded-circle sm-user-image" /> 
                        <p className="font-size-16 light-blue-color mb-0 titilium-font">{messageChat ? messageChat?.userId?.name : profile?._id == currentChat?.sender_id[0]?._id ? currentChat?.receiver_id[0]?.name : currentChat?.sender_id[0]?.name} (<b>{messageChat ? messageChat?.business?.name : currentChat?.businesses?.name}</b>)</p>
                    </div>
                    <div className="d-flex gap-3">
                        <div onClick={() => settoggle1(!toggle1)} className="light-blue-color cursor-pointer">
                            <Search width="22" height="22" />
                        </div>
                        <div className="light-blue-color cursor-pointer"><MoreVertical width="22" height="22" /></div>
                    </div>
                </div>
            </div>
            {toggle1 == true ? <div class="py-2 px-4 border sm-search-panel yellow-bg">
                <div class="sm-search-chats">
                    <Search width="18" height="18" className="sm-search-chats-icon" />
                    <input type="text" class="form-control sm-search-chats-field rounded-0" placeholder="Search..." value={searchtext}
                        onChange={(e) => setsearchtext(e.target.value)} />
                </div>
            </div> : null}


            <div class="sm-chat-messages p-4" ref={lastMessageRef}>

                {filteredData?.map((data, index) => (
                        <>
                            <div class={data?.senderType === "admin" && profile?._id !== data?.sender_detail ? "sm-chat-message-right" : profile?._id == data?.sender_id ? "sm-chat-message-right" : "sm-chat-message-left"} key={index}>
                                <div class="flex-shrink-1">
                                    {data?.type == "message" ?
                                        <div className="sm-message-text" >
                                            {data?.message}
                                        </div>
                                        : data?.type == "quote" ?
                                        <div className="sm-message-text" >
                                            <h3 className="font-size-20 fw-bold">Quotation</h3>
                                            <p className="font-size-16 mb-1"><b>For how many People:</b> {data?.quote?.noOfPeople}</p>
                                            <p className="font-size-16 mb-1"><b>For How long:</b> {data?.quote?.duration}</p>
                                            <p className="font-size-16 mb-0"><b>What Are You Looking For:</b> {data?.roomdesktypes?.name}</p>
                                        </div>
                                    : data?.type == "image" ?
                                            <div className="sm-message-text">
                                                <div className="d-flex flex-wrap gap-2 sm-message-images">
                                                    {data?.attachment?.slice(0, 4).map((imageUrl, index) => (
                                                        <div onClick={() => setisOpen(!isOpen)} key={index} className={`sm-message-image sm-message-image-${data?.attachment?.length}`}>
                                                            <img src={`${process.env.REACT_APP_IMGURL}/${imageUrl}`} alt={`media-${index}`} />
                                                        </div>
                                                    ))}
                                                    {data?.attachment.length > 4 && (
                                                        <div onClick={() => setisOpen(true)} className="sm-message-image sm-image-count">
                                                            <span>+{data?.attachment?.length - 4}</span>
                                                        </div>
                                                    )}
                                                    {/* {isOpen && (
                                                        <Lightbox
                                                            mainSrc={`${process.env.REACT_APP_IMGURL}/${data?.attachment[photoIndex]}`}
                                                            nextSrc={`${process.env.REACT_APP_IMGURL}/${data?.attachment[(photoIndex + 1) % data?.attachment?.length]}`}
                                                            prevSrc={`${process.env.REACT_APP_IMGURL}/${data?.attachment[(photoIndex + data?.attachment?.length - 1) % data?.attachment?.length]}`}
                                                            onCloseRequest={() => setisOpen(false)}
                                                            onMovePrevRequest={() => setphotoIndex((photoIndex + data?.attachment?.length - 1) % data?.attachment?.length)}
                                                            onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % data?.attachment?.length)}
                                                        />
                                                    )} */}

                                                </div>
                                            </div>
                                            : data?.type == "audio" ?
                                                <div className="sm-message-text">
                                                    <div className="d-flex gap-2 sm-message-media">
                                                        <FileAudio width={22} height={22} stroke={"#3A5CD6"} />
                                                        <div style={{ marginRight: '10px' }}>
                                                            <p className="font-size-14 mb-0 black-color fw-semibold">{data?.attachment[0]}</p>
                                                            <p style={{ color: '#646464' }} className="font-size-12 mb-0">Audio</p>
                                                        </div>
                                                        <a href={`${process.env.REACT_APP_IMGURL}/${data?.attachment[0]}`} target="_blank" download  >
                                                            <div className="cursor-pointer">
                                                                <DownloadCloud width={22} height={22} stroke={"#646464"} />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                : data?.type == "document" ?
                                                    <div className="sm-message-text">
                                                        <div className="d-flex gap-2 sm-message-media">
                                                            <FileText width={22} height={22} stroke={"#3A5CD6"} />
                                                            <div style={{ marginRight: '10px' }}>
                                                                <p className="font-size-14 mb-0 black-color fw-semibold"><a href={`${process.env.REACT_APP_IMGURL}/${data?.attachment[0]}`} target="_blank" download >{data?.attachment[0]}</a></p>
                                                                <p style={{ color: '#646464' }} className="font-size-12 mb-0">Doc</p>
                                                            </div>
                                                            <div className="cursor-pointer">
                                                                <DownloadCloud width={22} height={22} stroke={"#646464"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : data?.type == "video" ?
                                                        <div className="sm-message-text">
                                                            <div className="d-flex gap-2 sm-message-media">
                                                                <FileVideo width={22} height={22} stroke={"#3A5CD6"} />
                                                                <div style={{ marginRight: '10px' }}>
                                                                    <p className="font-size-14 mb-0 black-color fw-semibold"><a href={`${process.env.REACT_APP_IMGURL}/${data?.attachment[0]}`} target="_blank" download >{data?.attachment[0]}</a></p>
                                                                    <p style={{ color: '#646464' }} className="font-size-12 mb-0">Video</p>
                                                                </div>
                                                                <div className="cursor-pointer">
                                                                    <DownloadCloud width={22} height={22} stroke={"#646464"} />
                                                                </div>
                                                            </div> </div> : <></>}
                                    <div className="d-flex gap-3 align-items-center sm-text-bottom">
                                        <p className="font-size-10 mb-0 black-color">{moment(data.createdAt).format("LT")}</p>
                                        <p className="font-size-10 mb-0 sm-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                }


                {/* <div class="sm-chat-message-left">
                    <div class="flex-shrink-1">
                        <div className="sm-message-text">
                            Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo.
                        </div>
                        <div className="d-flex gap-3 align-items-center sm-text-bottom">
                            <p className="font-size-10 mb-0 black-color">9:54 PM</p>
                            <p className="font-size-10 mb-0 sm-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                        </div>
                    </div>
                </div> */}
                {/* <div class="sm-chat-message-left">
                    <div class="flex-shrink-1">
                        <div className="sm-message-text">
                            <div className="d-flex flex-wrap gap-2 sm-message-images">
                                <div className="sm-message-image">
                                    <img src="https://images.unsplash.com/photo-1580489944761-15a19d654956" alt="media" />
                                </div>
                                <div className="sm-message-image">
                                    <img src="https://images.unsplash.com/photo-1580489944761-15a19d654956" alt="media" />
                                </div>
                                <div className="sm-message-image">
                                    <img src="https://images.unsplash.com/photo-1580489944761-15a19d654956" alt="media" />
                                </div>
                                <div className="sm-message-image">
                                    <img src="https://images.unsplash.com/photo-1580489944761-15a19d654956" alt="media" />
                                </div>
                                <div onClick={() => setisOpen(true)} className="sm-message-image sm-image-count"><span>+5</span></div>
                                {isOpen && (
                                    <Lightbox
                                        mainSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                                        nextSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                                        prevSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                                        onCloseRequest={() => setisOpen(false)}
                                    // onMovePrevRequest=""
                                    // onMoveNextRequest=""
                                    />
                                )}
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center sm-text-bottom">
                            <p className="font-size-10 mb-0 black-color">9:54 PM</p>
                            <p className="font-size-10 mb-0 sm-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                        </div>
                    </div>
                </div>
                <div class="sm-chat-message-right">
                    <div class="flex-shrink-1">
                        <div className="sm-message-text">
                            <div className="d-flex gap-2 sm-message-media">
                                <FileAudio width={22} height={22} stroke={"#3A5CD6"} />
                                <div style={{ marginRight: '10px' }}>
                                    <p className="font-size-14 mb-0 black-color fw-semibold">Weddified Audio</p>
                                    <p style={{ color: '#646464' }} className="font-size-12 mb-0">Audio</p>
                                </div>
                                <div className="cursor-pointer">
                                    <DownloadCloud width={22} height={22} stroke={"#646464"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center sm-text-bottom">
                            <p className="font-size-10 mb-0 black-color">9:54 PM</p>
                            <p className="font-size-10 mb-0 sm-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                        </div>
                    </div>
                </div>
                <div class="sm-chat-message-right">
                    <div class="flex-shrink-1">
                        <div className="sm-message-text">
                            <div className="d-flex gap-2 sm-message-media">
                                <FileText width={22} height={22} stroke={"#3A5CD6"} />
                                <div style={{ marginRight: '10px' }}>
                                    <p className="font-size-14 mb-0 black-color fw-semibold">Weddified Doc</p>
                                    <p style={{ color: '#646464' }} className="font-size-12 mb-0">Doc</p>
                                </div>
                                <div className="cursor-pointer">
                                    <DownloadCloud width={22} height={22} stroke={"#646464"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center sm-text-bottom">
                            <p className="font-size-10 mb-0 black-color">9:54 PM</p>
                            <p className="font-size-10 mb-0 sm-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                        </div>
                    </div>
                </div>
                <div class="sm-chat-message-right">
                    <div class="flex-shrink-1">
                        <div className="sm-message-text">
                            <div className="d-flex gap-2 sm-message-media">
                                <FileVideo width={22} height={22} stroke={"#3A5CD6"} />
                                <div style={{ marginRight: '10px' }}>
                                    <p className="font-size-14 mb-0 black-color fw-semibold">Weddified Video</p>
                                    <p style={{ color: '#646464' }} className="font-size-12 mb-0">Video</p>
                                </div>
                                <div className="cursor-pointer">
                                    <DownloadCloud width={22} height={22} stroke={"#646464"} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center sm-text-bottom">
                            <p className="font-size-10 mb-0 black-color">9:54 PM</p>
                            <p className="font-size-10 mb-0 sm-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                        </div>
                    </div>
                </div> */}
            </div>

            <div class="py-2 px-3 sm-shadow  sm-write-message yellow-bg">
                {(photoFiles?.length > 0 || otherFile) && (<div className="sm-upload-box">
                    {photoFiles?.map((file, index) => (
                        <div key={index} className="sm-uploaded-image-size">
                            <img src={URL.createObjectURL(file)} alt={file.name} />
                            <div className="crox-icon">
                                <X width="18" height="18" />
                            </div>
                        </div>
                    ))}

                    {otherFile && (
                        <div className="sm-selected-file">
                            {fileIcons[getFileType(otherFile)]}
                            <div>
                                <p className="font-size-14 black-color titilium-font mb-0">{otherFile.name}</p>
                                <p className="font-size-10 color-grey titilium-font mb-0">{otherFile.type}</p>
                            </div>
                            <div className="crox-icon">
                                <X width="18" height="18" />
                            </div>
                        </div>
                    )}
                </div>)}
                <div className="d-flex gap-3">
                    <div className="d-flex align-items-center gap-3">
                        <div class="dropdown sm-chat-dropdown">
                            <div class="light-blue-color cursor-pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <PlusCircle width="22" height="22" />
                            </div>

                            <div class="dropdown-menu">
                                <div className="d-flex align-items-center gap-2 mb-3">
                                    <div className="color-grey">
                                        <Paperclip width="22" height="22" />
                                    </div>
                                    <p className="font-size-14 mb-0"><b>Attachment</b></p>
                                </div>
                                <div className="d-flex align-items-center gap-3 px-2">
                                    <label for="doc-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                                        <FileText width={22} height={22} stroke={"var(--color3)"} />
                                        <p className="font-size-12 light-blue-color mb-0">Doc</p>
                                    </label>
                                    <input onChange={(e) => handleFileChange(e, 'other')} max={1} type="file" class="btn-check" id="doc-attachment" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf" />

                                    <label for="gallery-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                                        <Image width={22} height={22} stroke={"var(--color3)"} />
                                        <p className="font-size-12 light-blue-color mb-0">Gallery</p>
                                    </label>
                                    <input multiple onChange={(e) => handleFileChange(e, 'image')} type="file" class="btn-check" id="gallery-attachment" accept=".png, .jpg, .jpeg" />

                                    <label for="audio-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                                        <FileAudio width={22} height={22} stroke={"var(--color3)"} />
                                        <p className="font-size-12 light-blue-color mb-0">Audio</p>
                                    </label>
                                    <input onChange={(e) => handleFileChange(e, 'other')} max={1} type="file" class="btn-check" id="audio-attachment" accept=".mp3,audio/*" />

                                    <label for="video-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                                        <FileVideo width={22} height={22} stroke={"var(--color3)"} />
                                        <p className="font-size-12 light-blue-color mb-0">Video</p>
                                    </label>
                                    <input onChange={(e) => handleFileChange(e, 'other')} max={1} type="file" class="btn-check" id="video-attachment" accept="video/mp4,video/x-m4v,video/*" />
                                </div>
                            </div>
                        </div>
                        <div class="dropdown sm-chat-dropdown">
                            <div class="light-blue-color cursor-pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <SmilePlus width="22" height="22" />
                            </div>

                            <div class="dropdown-menu">
                                <div className="d-flex align-items-center gap-2 mb-3">
                                    <div className="">
                                        <SmilePlus width="22" height="22" />
                                    </div>
                                    <p className="font-size-14 mb-0"><b>Emojis</b></p>
                                </div>
                                <div>
                                    {/* <div><Picker data={data} onEmojiSelect={handleEmojiSelect} /></div> */}

                                </div>
                            </div>
                        </div>
                    </div>

                    <textarea style={{ boxShadow: 'none', border: 0 }} value={messageText} onKeyDown={handleKeyDown} class="form-control rounded-0" rows={1} placeholder="Type your message here..." onChange={handleMessageChange}></textarea>
                    <div className="light-blue-color cursor-pointer align-self-center">
                        <Send width="22" height="22" onClick={sendMessage} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatInbox