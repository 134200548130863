import ContextProvider from "./context/context"
import { useEffect } from "react";
import Layout from "./components/Layout/Layout";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from "react-redux";
import { setSocket } from "./store/slices/socketSlice";
import { token } from "./store/slices/userSlice";

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      dispatch(token())
      dispatch(setSocket())
    } catch (error) {
      console.log(error)
    }
  }, [])
  return (
    <ContextProvider >
      <Layout />
    </ContextProvider>
  );
}

export default App;
