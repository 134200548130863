import React, { useEffect, useState } from 'react'
import { addMetaData, deleteAccount, editMetaData, getmetaDataList, getProfile } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment"
import Modal from "react-modal";
import { ExternalLink, Eye, Pencil, Trash2} from "lucide-react";
import { Link } from 'react-router-dom';
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const MetaData = () => {
    const profile = useSelector(getProfile)
    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [metaDataList, setmetaDataList] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [load, setload] = useState(false);
    const [title, settitle] = useState("");
    const [description, setdescription] = useState("");
    const [keywords, setkeywords] = useState("");
    const [url, seturl] = useState("");
    const [imageName, setimageName] = useState("");
  
  
    function viewModal(item, type) {
      setIsOpen(true);
      if (type == "userDetail") {
        setUserDetail(item);
        setId(item?._id);
      } else if (type == "add") {
        settitle("")
        setdescription("")
        setkeywords("")
        setimageName("")
        seturl("")
      } else if (type == "edit") {
        setUserDetail(item);
        setId(item?._id);
        settitle(item?.title)
        setdescription(item?.description)
        setkeywords(item?.keywords)
        setimageName(item?.metaImage)
        seturl(item?.url)
      } else if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    }
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const deleteMetaData = async (id) => {
      try {
        await dispatch(deleteAccount({id: id})).unwrap();
        setIsOpen(false);
        MetaDataList();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const MetaDataList = async () => {
      try {
        setload(true);
        setmetaDataList(null);
        const response = await dispatch(getmetaDataList()).unwrap();
        setmetaDataList(response?.data);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    useEffect(() => {
        MetaDataList();
    }, []);

    const addandEditMetaData = async (e) => {
        e.preventDefault();
        try {
            const data = new FormData();
            data.append("title", title);
            data.append("url", url);
            data.append("metaImage", imageName);
            data.append("description", description);
            data.append("keywords", keywords);
            if (id) {
            data.append("id", id);
            await dispatch(editMetaData(data)).unwrap();
            setIsOpen(false);
            MetaDataList();
            setId("")    
            settitle("")
            setdescription("")
            setkeywords("")
            setimageName("")
            seturl("") 
          } else { 
            await dispatch(addMetaData(data)).unwrap();
            setIsOpen(false);
            MetaDataList();
            settitle("")
            setdescription("")
            setkeywords("")
            setimageName("")
            seturl("")
        }
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };

  return (
    <>
    <Modal closeTimeoutMS={500} isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} className={`modal fade show cw-modal cw-modal-30`}>
    <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
    {modalType == "userDetail" ? (<>
      <div class="modal-content">
        <div class="modal-header border-0 pb-0">
          <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Meta Data Detail</h1>
          <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body mt-0">
            <p class="font-size-16 black-color fw-bold mb-0">URL</p>
            <p class="font-size-16 black-color">{userDetail?.url}</p>

            <p class="font-size-16 black-color fw-bold mb-0">Title</p>
            <p class="font-size-16 black-color">{userDetail?.title}</p>

            <p class="font-size-16 black-color fw-bold mb-0">Description</p>
            <p class="font-size-16 black-color">{userDetail?.description}</p>

            <p class="font-size-16 black-color fw-bold mb-0">Keywords</p>
            <p class="font-size-16 black-color">{userDetail?.keywords}</p>
          </div>
        </div>
  </>): (modalType === "add" || modalType === "edit") ? (<>
    <div class="modal-content">
    <form onSubmit={addandEditMetaData}>
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">{modalType === "add" ? "Add" : "Edit"} Meta Data</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="form-floating cw-input mb-3">
                <input type="text" value={url} onChange={(e)=>seturl(e.target.value)} class="form-control" id="URL" placeholder="URL" required />
                <label for="URL">URL</label>
            </div>
            <div class="form-floating cw-input mb-3">
                <input type="text" value={title} onChange={(e)=>settitle(e.target.value)} class="form-control" id="title" placeholder="Title" required />
                <label for="title">Title</label>
            </div>
            <div class="form-floating cw-input mb-3">
                <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="metaOgImage" placeholder="Meta OG Image" />
                <label for="metaOgImage">Meta OG Image</label>
            </div>
            <div class="form-floating mb-3 cw-textarea">
                <textarea class="form-control" placeholder="description" id="description" onChange={(e) => setdescription(e.target.value)} value={description} style={{ height: '120px' }} required></textarea>
                <label for="description">Description</label>
            </div>
            <div class="form-floating mb-3 mt-3 cw-textarea">
                <textarea class="form-control" placeholder="keywords" id="keywords" onChange={(e) => setkeywords(e.target.value)} value={keywords} style={{ height: '100px' }}></textarea>
                <label for="keywords">Keywords</label>
            </div>
        </div>
        <div class="modal-footer border-0 justify-content-center">
         <button type="submit" class="btn cw-btn cw-yellow-btn py-2 px-4">{modalType === "add" ? "Submit" : "Update"}</button>
        </div>
    </form>
    </div>
  </>) : modalType == "delete" ? ( 
    <>
    <div class="modal-content">
        <div class="modal-header border-0 pb-0">
          <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Meta Data</h1>
          <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body mt-0">
            <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Meta Data?</p>
          </div>
          <div class="modal-footer border-0 justify-content-center gap-2">
            <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
            <button onClick={() => deleteMetaData(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
          </div>
        </div>
    </>
    ) : (
    <></>
  )}
    </div>

</Modal>
<div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb cw-breadcrumb">
    <li class="breadcrumb-item"><a>Menu</a></li>
    <li class="breadcrumb-item active" aria-current="page">Meta Data List</li>
  </ol>
</nav>
<div className="row gy-3 mb-4 align-items-center mb-2">
  <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
    <h2 className="font-size-28 fw-bold mb-0">Meta Data List</h2>
  </div>
  <div className="col-md-6 order-md-2 order-3">

  </div>
  <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
  <button class="btn cw-btn cw-yellow-btn px-3" onClick={() => viewModal(null, "add")}>Add Meta Data</button>
  </div>
</div>
<div className="cw-radius-20 white-bg cw-shadow p-3">
  <p className="font-size-20 pink-color">Meta Data List : {metaDataList?.length}</p>
  <div class="table-responsive">
    <table class="table cw-table table-borderless table-striped">
      <thead>
        <tr>
          <th scope="col">S.No</th>
          <th scope="col">URL</th>
          <th scope="col">Title</th>
          <th style={{ width: '140px' }} scope="col">Date</th>
          <th scope="col" style={{ width: '100px' }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : metaDataList?.map((item, i) => (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{item?.url}</td>
            <td>{item?.title}</td>
            <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
            <td>
              <div className="d-flex align-items-center gap-2">
              <Link target="blank" to={item?.url === "/home" ? `https://www.thecoworkingspaces.com` : `https://www.thecoworkingspaces.com${item?.url}`} className={`cw-action-icons l-blue-bg`}><ExternalLink width="18" /></Link>
                {profile?.blogs_access?.includes("read") && (<div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>)}
                {profile?.blogs_access?.includes("write") && (<div onClick={() => viewModal(item, "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>)}
                {profile?.blogs_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
              </div>
            </td>
          </tr>))}
      </tbody>
    </table>
  </div>
</div>
</div>
</>
  )
}

export default MetaData