import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getAllAdmins,
    deleteAdmin
} from "../../store/slices/userSlice";
import { Trash2 } from "lucide-react";
import moment from "moment"
import { X } from "lucide-react";
import { ArrowRight } from "lucide-react";
import { Pencil } from "lucide-react";
import { Plus } from "lucide-react";
import Modal from "react-modal";
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [id, setId] = useState("");
  const [admins, setadmins] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const adminDelete = async (id) => {
    try {
      await dispatch(deleteAdmin(id)).unwrap();
      setIsOpen(false);
      try {
        Admins();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Admins = async () => {
    try {
      setload(true);
      setadmins(null);
      const response = await dispatch(getAllAdmins()).unwrap();
      setadmins(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
        Admins();
    }
    return () => {
      mount = false;
    };
  }, []);



  return (
    <>
    <Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show cw-modal cw-modal-30 `}
>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
        {modalType == "userDetail" ? (
    <>
      </>
      ): modalType == "delete" ? ( 
        <>
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Admin User</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Admin User?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
                <button onClick={() => adminDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
              </div>
          </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>


<div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item"><a>Admins</a></li>
        <li class="breadcrumb-item active" aria-current="page">All Admins</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Admins</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">

      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn cw-btn cw-yellow-btn px-3" onClick={()=>navigate("/add-admin")}>Add Admin</button>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Admins : {admins?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Space</th>
              <th scope="col">Blogs</th>
              <th scope="col">Users</th>
              <th scope="col">System</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : admins?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.email}</td>
                <td>{item?.space_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.space_access?.length-1?", ":""}</>))}</td>
                <td>{item?.blogs_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.blogs_access?.length-1?", ":""}</>))}</td>
                <td>{item?.users_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.users_access?.length-1?", ":""}</>))}</td>
                <td>{item?.system_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.system_access?.length-1?", ":""}</>))}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <div onClick={()=>navigate("/edit-admin", { state: { _id: item },})} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                    <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
  )
}

export default Admin