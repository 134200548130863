import { Camera, ChevronsDown } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SpaceTypeDetail, addSpaceType, editSpaceTypee, getRoomDeskTypes, getRoomTypes } from '../../store/slices/userSlice';
import toast from 'react-hot-toast';
import Spinner from '../../components/Spinner';

const AddFacilities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.state;
  const [load, setload] = useState(false);
  const [Types, setTypes] = useState(null);
  const [inclusionList, setinclusionList] = useState(null);
  const [paymentCycleList, setpaymentCycleList] = useState(null);
  const [paymentCycleEdit, setpaymentCycleEdit] = useState(null);

  const [editSpaceType, seteditSpaceType] = useState(null);

  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);
  const [dp,setdp]=useState("")
  const [inclusions, setinclusions] = useState([]);
  const [paymentCycles, setpaymentCycles] = useState([]);
  const [numberOfDeskPerson, setnumberOfDeskPerson] = useState("");
  const [numberOfPerson, setnumberOfPerson] = useState("");
  const [RoomSize, setRoomSize] = useState("");
  const [SpaceSize, setSpaceSize] = useState("");
  const [AcessMeetingRoom, setAcessMeetingRoom] = useState("");
  const [Configuration, setConfiguration] = useState("");
  const [Placement, setPlacement] = useState("");
  const [seatingLayout, setseatingLayout] = useState("");
  const [RecommendedUse, setRecommendedUse] = useState("");
  const [roomType, setRoomType] = useState("");
  const [numberOfDesks, setnumberOfDesks] = useState("");
  const [deskArea, setdeskArea] = useState("");
  const [maxBookings, setmaxBookings] = useState("");
  const [slug, setslug] = useState("");
  const [type, settype] = useState("");
  const [behave, setbehave] = useState("");
  const [noOfHours, setnoOfHours] = useState(null);

  const handleImageUpload = (event) => {

    const selectedImages = event.target.files;
    const imagesArray = [];

    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    setImages(images.concat(imagesArray));
  };

  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);

    setImages(updatedImages);
  };

  const handleImageSelection = (selectedImage) => {
    if (!dp.includes(selectedImage)) {
      setdp(prevDP => [...prevDP, selectedImage]); // Add the selected image to the dp array
    } else {
      setdp(prevDP => prevDP.filter(image => image !== selectedImage)); // Remove the selected image from the dp array
    }
  };

  const Type = async () => {
    try {
      setload(true);
      setTypes(null);
      const response = await dispatch(getRoomDeskTypes()).unwrap();
      console.log('responsessss', response?.data)
      setTypes(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Type();
    }
    return () => {
      mount = false;
    };
  }, []);

  const [selectedType, setSelectedType] = useState(null);

  const handleTypeChange = (data) => {
    settype(data?._id)
    setbehave(data?.behave)
    setSelectedType(data?.name);
    setinclusionList(data?.inclusions);
    setpaymentCycleList(data?.paymentcycles);
    console.log(data, "selected type");
  };

  const handleInclusionChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setinclusions(prevInclusions => [...prevInclusions, value]);
    } else {
      setinclusions(prevInclusions => prevInclusions.filter(item => item !== value));
    }
  }
  const handleRecommendedUseChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setRecommendedUse((prevRecommendedUse) => [...prevRecommendedUse, value]);
    } else {
      setRecommendedUse((prevRecommendedUse) =>
        prevRecommendedUse.filter((item) => item !== value)
      );
    }
  };
  // const handleInputChange = (e, data) => {
  //   const { id, value } = e.target;
  //   setpaymentCycles(prevState => ({
  //     ...prevState,
  //     [data]: value
  //   }));
  // };

  const handleInputChange = (event, id) => {
    const { value } = event.target;
    const updatedPaymentCycles = paymentCycles.map((cycle) =>
      cycle.type === id ? { ...cycle, amount: value } : cycle
    );
    if (!updatedPaymentCycles.some((cycle) => cycle.type === id)) {
      updatedPaymentCycles.push({ type: id, amount: value });
    }
    setpaymentCycles(updatedPaymentCycles);
  };

  // const handleInputChange = (event, id) => {
  //   const { value } = event.target;
    
  //   // Check if the payment cycle with the given id exists in the current paymentCycles state
  //   const cycleIndex = paymentCycles.findIndex(cycle => cycle.type._id === id);
  
  //   // If the payment cycle exists, update its amount
  //   if (cycleIndex !== -1) {
  //     const updatedCycles = [...paymentCycles];
  //     updatedCycles[cycleIndex] = { ...updatedCycles[cycleIndex], amount: value };
  //     setpaymentCycles(updatedCycles);
  //   } else {
  //     // If the payment cycle does not exist, create a new one and add it to the state
  //     setpaymentCycles(prevCycles => [
  //       ...prevCycles,
  //       { type: id, amount: value }
  //     ]);
  //   }
  // };
  

  useEffect(() => {
    if (selectedType) {
      setinclusions([])
      setpaymentCycles([])
      setnumberOfDeskPerson("");
      setnumberOfPerson("");
      setRoomSize("");
      setSpaceSize("");
      setAcessMeetingRoom("");
      setConfiguration("");
      setPlacement("");
      setseatingLayout("");
      setRecommendedUse("");
      setRoomType("");
      setnumberOfDesks("");
      setdeskArea("");
      setmaxBookings("");
      setnoOfHours("");
    }
  }, [selectedType]);

  const generateSlug = (text) => {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters except hyphens
      .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, ''); // Remove hyphens from the start and end
  };

  useEffect(() => {
    if (name) {
      const slugifiedName = generateSlug(name);
      setslug(slugifiedName);
    }
  }, [name]);

  useEffect(() => {
    if (images && images.length > 0) {
        setdp(0);
    }
  }, [images]);

  const urlParts = window.location.pathname.split('/');
  const spaceTypeId = urlParts[urlParts.length - 1];
  const [behaveParam, setBehaveParam] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const behaveValue = searchParams.get('behave');
    setBehaveParam(behaveValue || '');
  }, [location.search]);

  const EditSpaceType = async () => {
    try {
      setload(true)
      seteditSpaceType(null);
      const response = await dispatch(SpaceTypeDetail({ spaceTypeId, behaveParam })).unwrap();
      seteditSpaceType(response?.data[0]);
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
      EditSpaceType();
  }, [behaveParam])

  useEffect(() => {
    if (editSpaceType) {
      setname(editSpaceType?.name)
      setdescription(editSpaceType?.description)
      setprev_images(editSpaceType?.image)
      // setdp(editSpaceType?.featureImage)
      // setConfiguration(editSpaceType);
      // setPlacement(editSpaceType);
      // setseatingLayout(editSpaceType);
      // setRecommendedUse(editSpaceType);
      // setRoomType(editSpaceType);
    }
  }, [editSpaceType]);


  const findType = Types?.find(user => user?._id === editSpaceType?.type[0]?._id);


  useEffect(() => {
    if (editSpaceType) {
      document.title = editSpaceType?.name
    }
  }, [editSpaceType, location.pathname]);

  useEffect(() => {
    if (findType) {
      settype(findType?._id)
      setbehave(findType?.behave)
      setSelectedType(findType?.name);
      setinclusionList(findType?.inclusions);
      setpaymentCycleList(findType?.paymentcycles);
      setnumberOfDesks(editSpaceType?.deskCount);
      setdeskArea(editSpaceType?.deskSize);
      setnumberOfDeskPerson(editSpaceType?.capacity);
      setnumberOfPerson(editSpaceType?.capacity);
      setRoomSize(editSpaceType?.roomSize);
      setSpaceSize(editSpaceType?.roomSize);
      setAcessMeetingRoom(editSpaceType?.isMeetingRoomAvailable?.isAvailable);
      setnoOfHours(editSpaceType?.isMeetingRoomAvailable?.availableHours || "");
      setinclusions(editSpaceType?.inclusion)
      setpaymentCycleEdit(editSpaceType?.paymentCycleDetail)
      setpaymentCycles(editSpaceType?.paymentCycleDetail)
      setConfiguration(editSpaceType?.configuration)
      setseatingLayout(editSpaceType?.seatingLayout)
      setPlacement(editSpaceType?.placement)
      setRoomType(editSpaceType?.CoLivingType)
      setRecommendedUse(editSpaceType?.recommendedUse)
    }
    if (editSpaceType && editSpaceType?.featureImage) {
      const foundIndex = prev_images?.findIndex(image => image === editSpaceType?.featureImage);
      if (foundIndex !== -1) {
        setdp(foundIndex);
      }
    }
  }, [findType]);



  const saveBranch = async (e) => {
    try {
      setload(true);
      if (!name) {
        toast.error('Name is required');
        setload(false);
        return;
      }
      if (!editSpaceType) {
        if (!images.length == 1) {
          toast.error('Images are required');
          setload(false);
          return;
        }
      } else {}
      if (!type) {
        toast.error('Type is required');
        setload(false);
        return;
      }
      e.preventDefault();
      const data = new FormData();
      if (editSpaceType) {
        data.append("id", spaceTypeId);
      } else{
        data.append("businessId", path?.businessId);
        data.append("spaceId", path?._id);
      }
      
      data.append("name", name);
      data.append("description", description);
      data.append("slug", slug);
      data.append("type", type);
      data.append("behave", behave);
      data.append("featureIndex", dp);
      data.append("prevImages", JSON.stringify(prev_images));
      images.forEach((item) => {
        data.append("roomDeskVirtualOffice", item);
      });
      if (selectedType === "Virtual Office") {
        data.append("inclusion", JSON.stringify(inclusions));
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Dedicated Desks") {
        data.append("deskCount", numberOfDesks);
        data.append("deskSize", deskArea);
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Hot Desks") {
        data.append("deskCount", numberOfDesks);
        data.append("deskSize", deskArea);
        // data.append("type", maxBookings);
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Private Office") {
        data.append("capacity", numberOfDeskPerson);
        data.append("roomSize", RoomSize);
        data.append("isAvailable", AcessMeetingRoom);
        data.append("availableHours", noOfHours);
        data.append("inclusion", JSON.stringify(inclusions));
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Meeting Room") {
        data.append("capacity", numberOfPerson);
        data.append("roomSize", RoomSize);
        data.append("inclusion", JSON.stringify(inclusions));
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Conference Room") {
        data.append("capacity", numberOfPerson);
        data.append("roomSize", RoomSize);
        data.append("configuration", Configuration);
        data.append("inclusion", JSON.stringify(inclusions));
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Event Space") {
        data.append("capacity", numberOfPerson);
        data.append("roomSize", SpaceSize);
        data.append("placement", Placement);
        data.append("seatingLayout", seatingLayout);
        data.append("inclusion", JSON.stringify(inclusions));
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Studio Space") {
        data.append("capacity", numberOfPerson);
        data.append("roomSize", RoomSize);
        data.append("recommendedUse", JSON.stringify(RecommendedUse));
        data.append("inclusion", JSON.stringify(inclusions));
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Retail space") {
        data.append("roomSize", SpaceSize);
        data.append("placement", Placement);
        data.append("inclusion", JSON.stringify(inclusions));
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      } if (selectedType === "Co-living accommodations") {
        data.append("roomSize", SpaceSize);
        data.append("CoLivingType", roomType);
        data.append("inclusion", JSON.stringify(inclusions));
        data.append("paymentCycle", JSON.stringify(paymentCycles));
      }
      console.log('Form Data:', Object.fromEntries(data.entries()));
      if (editSpaceType) {
        await dispatch(editSpaceTypee(data)).unwrap();
        setload(false);
        navigate('/spaces')
      } else {
        await dispatch(addSpaceType(data)).unwrap();
        setload(false);
        navigate('/spaces')
      }

    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };



  const AccessMeetingRoom =["No" , "Unlimited" , "X No. of Hours (Monthly)"]
  const Configration = ["Boardroom style", "Theater style", "U-shape configuration", "Banquet style"]
  const EventSpacePlacement = ["Indoor" , "Outdoor" , "Marque"]
  const StudioRecommendedUse = ["Podcast Studio", "Photographic Studio", "Video Studio"]
  const RetailSpacePlacement = ["High-traffic area", "Near the entrance", "Near common areas"]
  const RoomType = ["Private rooms", "Shared rooms", "Suites", "Studio apartments"]
  const SeatingLayout = ["Theater-style Seating", "Classroom-style seating", "Banquet-style seating", "Lounge seating", "Open space layout"]

  console.log(path, "data");
  console.log(paymentCycles, "paymentCycles");
  console.log(Types, "Types");
  console.log(type, "type");
  console.log(behave, "behave");
  console.log(behaveParam, "behaveParam");
  console.log(editSpaceType, "editSpaceType");
  console.log(findType, "findType");
  console.log(inclusions, "inclusions");
  console.log(prev_images, "prev_images");
  console.log(dp, "dp");
  console.log(RecommendedUse, "RecommendedUse");
  console.log(noOfHours, "noOfHours");
    
  return (
    <>
    {load && <Spinner/>}
    <div className="cw-attributes-list-page px-md-5 pt-3 px-3 pb-5">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Spaces</a></li>
            <li class="breadcrumb-item active" aria-current="page">{editSpaceType ? "Edit" : "Add"} Space Type</li>
        </ol> 
      </nav>
      <h2 className="font-size-28 fw-bold mb-3">{editSpaceType ? "Edit" : "Add"} Space Type</h2>
      <div className="cw-radius-20 white-bg cw-shadow p-3 mb-4">
      <div className="row gy-3 mb-3">
      <div className="col-md-6">
      <div class="form-floating cw-input">
      <input type="text" maxLength={50} class="form-control" disabled value={path?.name || editSpaceType?.spaceId?.name} id="location-name" placeholder="location Name"/>
      <label for="location-name">Location Name</label>
      </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating cw-input">
      <input type="text"  maxLength={50} class="form-control" id="name" placeholder="Name" value={name} onChange={(e)=>setname(e.target.value)}/>
      <label for="name">Name</label>
      </div>
      </div>
      <div className="col-md-12">
      <div class="form-floating cw-textarea">
        <textarea class="form-control" placeholder="description" id="description" defaultValue={description} onChange={(e)=>setdescription(e.target.value)} style={{height:'100px'}}></textarea>
        <label for="description">Description</label>
      </div>
      </div>
      <div className="col-md-12">
      <div className="upload__img-wrap mb-3">
        {prev_images?.length>0&&prev_images.map((image, index) => (
            <div className="upload__img-box" key={index}>
              <div className="img-bg">
                <img src={`${process.env.REACT_APP_IMGURL}/${image}`} alt={`Store Image`} className="img-bg-size" />
                <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                <div className="upload__img-profile">
                <div class="dropdown">
                  <a class=""  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <ChevronsDown />
                  </a>
                  <ul class="dropdown-menu">
                  <div class="form-check form-check-inline">
                <input checked={dp === index} onChange={() => setdp(index)} class="form-check-input" type="radio" name="dp" id="dp" />
                <label class="form-check-label" for="dp">Featured Image</label>
                </div>
                  </ul>
                </div>
            </div>
              </div>
            </div>
          ))}

        {images?.length>0&&images.map((image, index) => (
            <div className="upload__img-box" key={index}>
              <div className="img-bg">
                <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                <div className="upload__img-profile">
                <div class="dropdown">
                  <a class=""  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <ChevronsDown />
                  </a>
                  <ul class="dropdown-menu">
                  <div class="form-check form-check-inline">
                  <input checked={dp === index + prev_images.length} onChange={() => setdp(index + prev_images.length)} class="form-check-input" type="radio" name="dp" id="dp" />
                <label class="form-check-label" for="dp">Featured Image</label>
                </div>
                  </ul>
                </div>
                </div>
              </div>
            </div>
          ))}
          <input type="file" class="btn-check" id="btn-check" multiple data-max_length="20" onChange={handleImageUpload} />
          <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> Add Photos</label>
    </div>
      </div>
      </div>

      <p class="font-size-16 fw-semibold black-color mb-2">Type</p>
        <div className="d-flex flex-wrap gap-2 mb-3">
        {Types?.map((s) => (
          <>
          <input class="btn-check" name="types-of-facilities" type="radio" checked={type?.includes(s?._id)} disabled={editSpaceType} onChange={() => handleTypeChange(s)} id={s?._id} value={s?._id} />
          <label class="btn cw-select-btn" for={s?._id}>{s?.name}</label>
          </>
        ))}
        </div>
        {selectedType && (
      <div>
        {selectedType === "Virtual Office" && (
          <>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Inclusions</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}
          {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
        {selectedType === "Private Office" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="number-desk-person" value={parseFloat(numberOfDeskPerson)} placeholder="Number of Desks / Persons" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setnumberOfDeskPerson(newValue);
                  }}/>
          <label for="number-desk-person">Number of Desks / Persons</label>
          </div>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="Room-Size" value={parseFloat(RoomSize)} placeholder="Room Size (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setRoomSize(newValue);
                  }}/>
          <label for="Room-Size">Room Size (sq. ft.)</label>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Inclusions</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Access to Meeting Room</label>
          <div className="d-flex flex-wrap gap-2">
          {AccessMeetingRoom?.map((data)=> (
          <>
            <input class="btn-check" name="access-meeting-room" type="radio" id={data} checked={AcessMeetingRoom?.includes(data)} value={data} onChange={(e)=>setAcessMeetingRoom(data)}/>
            <label class="btn cw-select-btn" for={data}>{data}</label>
          </>
          ))}
          </div>
          </div>
          {AcessMeetingRoom === "X No. of Hours (Monthly)" && <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={2} class="form-control" value={parseFloat(noOfHours)} id="number-of-hours" placeholder="No Of Hours" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setnoOfHours(newValue);
                  }}/>
          <label for="number-of-hours">No Of Hours</label>
          </div>}
          
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}
          {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}

          </div>
          </div>

          </>
        )}
        {selectedType === "Meeting Room" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="number-person" value={parseFloat(numberOfPerson)} placeholder="Number of Persons" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setnumberOfPerson(newValue);
                  }}/>
          <label for="number-person">Number of Persons</label>
          </div>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="Room-Size" value={parseFloat(RoomSize)} placeholder="Room Size (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setRoomSize(newValue);
                  }}/>
          <label for="Room-Size">Room Size (sq. ft.)</label>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Available Equipment & Tools</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}
          {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
        {selectedType === "Conference Room" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="number-person" value={parseFloat(numberOfPerson)} placeholder="Number of Persons" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setnumberOfPerson(newValue);
                  }}/>
          <label for="number-person">Number of Persons</label>
          </div>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="Room-Size" value={parseFloat(RoomSize)} placeholder="Room Size (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setRoomSize(newValue);
                  }}/>
          <label for="Room-Size">Room Size (sq. ft.)</label>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Configuration</label>
          <div className="d-flex flex-wrap gap-2">
          {Configration?.map((data)=> (
          <>
            <input class="btn-check" name="access-meeting-room" type="radio" id={data} value={data} checked={Configuration?.includes(data)} onChange={(e)=>setConfiguration(data)}/>
            <label class="btn cw-select-btn" for={data}>{data}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Available Equipment & Tools</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}

          {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
        {selectedType === "Event Space" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={4} class="form-control" id="number-person" value={parseFloat(numberOfPerson)} placeholder="Number of Persons" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setnumberOfPerson(newValue);
                  }}/>
          <label for="number-person">Number of Persons</label>
          </div>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="Space-Size" value={parseFloat(SpaceSize)} placeholder="Space Size (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setSpaceSize(newValue);
                  }}/>
          <label for="Space-Size">Space Size (sq. ft.)</label>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Placement</label>
          <div className="d-flex flex-wrap gap-2">
          {EventSpacePlacement?.map((data)=> (
          <>
            <input class="btn-check" name="placement" type="radio" id={data} value={data} checked={Placement?.includes(data)} onChange={(e)=>setPlacement(data)} />
            <label class="btn cw-select-btn" for={data}>{data}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Inclusion</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Seating Layout</label>
          <div className="d-flex flex-wrap gap-2">
          {SeatingLayout?.map((data)=> (
          <>
            <input class="btn-check" name="seating-layout" type="radio" id={data} value={data} checked={seatingLayout?.includes(data)} onChange={(e)=>setseatingLayout(data)} />
            <label class="btn cw-select-btn" for={data}>{data}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row gy-3">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}

          {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
        {selectedType === "Studio Space" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="number-person" value={parseFloat(numberOfPerson)} placeholder="Number of Persons" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setnumberOfPerson(newValue);
                  }}/>
          <label for="number-person">Number of Persons</label>
          </div>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="Room-Size" value={parseFloat(RoomSize)} placeholder="Room Size (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setRoomSize(newValue);
                  }}/>
          <label for="Room-Size">Room Size (sq. ft.)</label>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Recommended Use</label>
          <div className="d-flex flex-wrap gap-2">
          {StudioRecommendedUse?.map((data)=> (
          <>
            <input class="btn-check" name="access-meeting-room" type="checkbox" id={data} value={data} checked={RecommendedUse.includes(data)} onChange={handleRecommendedUseChange}/>
            <label class="btn cw-select-btn" for={data}>{data}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Inclusion</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row gy-3">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}

        {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
        {selectedType === "Retail space" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" id="Space-Size" value={parseFloat(SpaceSize)} placeholder="Space Size (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setSpaceSize(newValue);
                  }}/>
          <label for="Space-Size">Space Size (sq. ft.)</label>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Placement</label>
          <div className="d-flex flex-wrap gap-2">
          {RetailSpacePlacement?.map((data)=> (
          <>
            <input class="btn-check" name="access-meeting-room" type="radio" id={data} value={data} checked={Placement?.includes(data)} onChange={(e)=>setPlacement(data)}/>
            <label class="btn cw-select-btn" for={data}>{data}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Inclusion</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row gy-3">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}
          {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
        {selectedType === "Co-living accommodations" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" value={parseFloat(SpaceSize)} id="Space-Size" placeholder="Space Size (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setSpaceSize(newValue);
                  }}/>
          <label for="Space-Size">Space Size (sq. ft.)</label>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Room Type</label>
          <div className="d-flex flex-wrap gap-2">
          {RoomType?.map((data)=> (
          <>
            <input class="btn-check" name="access-meeting-room" type="radio" id={data} value={data} checked={roomType?.includes(data)} onChange={(e)=>setRoomType(data)}/>
            <label class="btn cw-select-btn" for={data}>{data}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-3">
          <label className="d-block w-100 mb-2">Inclusion</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row gy-3">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}
        {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
        {selectedType === "Dedicated Desks" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" value={parseFloat(numberOfDesks)} id="number-of-desks" placeholder="Number of Desks" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setnumberOfDesks(newValue);
                  }}/>
          <label for="number-of-desks">Number of Desks</label>
          </div>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" value={parseFloat(deskArea)} id="desk-area" placeholder="Desk Area (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setdeskArea(newValue);
                  }}/>
          <label for="desk-area">Desk Area (sq. ft.)</label>
          </div>
          {inclusionList?.length < 0 && <div className="mb-3">
          <label className="d-block w-100 mb-2">Inclusion</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>}
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row gy-3">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}
          {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
        {selectedType === "Hot Desks" && (
          <>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" value={parseFloat(numberOfDesks)} id="number-of-desks" placeholder="Number of Desks" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setnumberOfDesks(newValue);
                  }}/>
          <label for="number-of-desks">Number of Desks</label>
          </div>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" value={parseFloat(deskArea)} id="desk-area" placeholder="Desk Area (sq. ft.)" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setdeskArea(newValue);
                  }}/>
          <label for="desk-area">Desk Area (sq. ft.)</label>
          </div>
          <div class="form-floating cw-input mb-3">
          <input type="number" maxLength={3} class="form-control" value={parseFloat(maxBookings)} id="Space-Size" placeholder="Max. number of bookings" onChange={(event) => {
                    // console.log('first')
                    let newValue = parseFloat(event.target.value);
                    if (newValue < 0 || isNaN(newValue)) {
                      newValue = "";
                    }
                    setmaxBookings(newValue);
                  }}/>
          <label for="Space-Size">Max. number of bookings</label>
          </div>
          {inclusionList?.length < 0 && <div className="mb-3">
          <label className="d-block w-100 mb-2">Inclusion</label>
          <div className="d-flex flex-wrap gap-2">
          {inclusionList?.map((data)=> (
          <>
            <input class="btn-check" name="inclusions" type="checkbox" id={data?._id} value={data?._id} checked={inclusions?.includes(data?._id)} onChange={(e) => handleInclusionChange(e)}/>
            <label class="btn cw-select-btn" for={data?._id}>{data?.name}</label>
          </>
          ))}
          </div>
          </div>}
          <div className="mb-0">
          <label className="d-block w-100 mb-2">Payment Cycles</label>
          <div className="row gy-3">
          {/* {paymentCycleList?.map((data)=> (
            <div className="col-md-3">
              <div class="form-floating cw-input">
              <input type="number" class="form-control" id={data?._id} placeholder={data?._id} value={paymentCycles.find((cycle) => cycle.type === data?._id)?.amount || ''} onChange={(e) => handleInputChange(e, data?._id)}/>
              <label for={data?._id}>{data?.name}</label>
              </div>
            </div>
          ))} */}

        {paymentCycleList
          ?.sort((a, b) => a.SortingKey - b.SortingKey)
          .map((cycle) => {
          const paymentCycle = paymentCycleEdit?.find((pc) => pc?.type?._id === cycle?._id);
          const amount = paymentCycle ? paymentCycle?.amount : '';

          return (
            <div className="col-md-3" key={cycle?._id}>
              <div className="form-floating cw-input">
                <input
                  type="number"
                  className="form-control"
                  id={cycle?._id}
                  placeholder={cycle?._id}
                  value={paymentCycles.find((c) => c?.type === cycle?._id)?.amount || amount}
                  onChange={(e) => handleInputChange(e, cycle?._id)}
                />
                <label htmlFor={cycle?._id}>{cycle?.name}</label>
              </div>
            </div>
          );
        })}
          </div>
          </div>
          </>
        )}
      </div>
    )}
      </div>
      <div class="d-flex justify-content-center gap-2">
      <button onClick={()=>navigate("/spaces")} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
      <button onClick={saveBranch} class="btn cw-btn cw-yellow-btn py-2 px-4" disabled={load}>Submit</button>
    </div>
    </div>
    </>
  )
}

export default AddFacilities