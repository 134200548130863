import React from 'react'

const DashboardAdmin = () => {
  return (
    <>
    {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80vh", width: "100%", position: "absolute", top: 0, left:0 }}>
    <div className="fa-3x">
    <dotlottie-player src="https://lottie.host/c37852a2-6f0a-411c-8dd9-01aaa52c65a5/Tx1pv6TyVl.json" background="transparent" speed="1" style={{"width": "500px", "height": "500px"}} loop autoplay></dotlottie-player>
    <h2 className="text-uppercase text-center">The <span className="fw-bold">Coworking</span> Spaces</h2>
    </div>
  </div> */}
  </>
  )
}

export default DashboardAdmin