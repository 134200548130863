import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addFeatureCategory,
  deleteFeatureCategory,
  editFeatureCategory,
  getFeatureCategories,
  getProfile
} from "../../store/slices/userSlice";
import { Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
import Modal from "react-modal";
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");

const FeatureCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile=useSelector(getProfile)

  const [userDetail, setUserDetail] = useState(null);
  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [featurecategories, setfeaturecategories] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "add") {
      setname(item);
      setId(item);
    } 

 else if (type == "userDetail") {
    setname(item.name);
    setId(item?._id);
  } 
    
    else {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const blogDelete = async (id) => {
    try {
      await dispatch(deleteFeatureCategory(id)).unwrap();
      setIsOpen(false);

      try {
        FeatureCategory();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const FeatureCategory = async () => {
    try {
      setload(true);
      setfeaturecategories(null);
      setFilteredData(null);
      const response = await dispatch(getFeatureCategories()).unwrap();
      setfeaturecategories(response?.data);
      setFilteredData(response?.data);
      setload(false);
     
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      FeatureCategory();
    }
    return () => {
      mount = false;
    };
  }, []);

  





const addandEditSubAdmin = async (e) => {
        e.preventDefault();
    
        try {

          const payload ={
            name: name,
          }
          const payload2 ={
            id: id,
            name: name,
          }
          if(id){        
            await dispatch(editFeatureCategory(payload2)).unwrap();     
            closeModal() 
            try {
                FeatureCategory();
              } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError);
              }
            } else { 
              await dispatch(addFeatureCategory(payload)).unwrap(); 
              closeModal() 
              try {
                  FeatureCategory();
                } catch (rejectedValueOrSerializedError) {
                  console.log(rejectedValueOrSerializedError);
                }
               
          }
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };

      console.log('blogs', featurecategories)
      console.log('name', name)

      const [nameFilter, setnameFilter] = useState('');
      const [filteredData, setFilteredData] = useState([]);
    
      const applyFilter = () => {
        const filteredResult = featurecategories?.filter(item => {
          const category = item?.name.toLowerCase();
    
          return (
            (nameFilter === '' || category.includes(nameFilter.toLowerCase()))
          );
        });
    
        setFilteredData(filteredResult);
        settoggle(false);
      };
    
      const resetFilter = () => {
        setFilteredData(featurecategories)
        setnameFilter('')
        settoggle(false);
      };

  return (
    <>
        <Modal
    closeTimeoutMS={500}
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Change Password"
    className={`modal fade show cw-modal cw-modal-30`}>

    <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
      {modalType == "add" ? (
        <>
        <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add Feature Category</h1>
            <button type="button" onClick={()=> closeModal()} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
              <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e)=>setname(e.target.value)} class="form-control" id="att-name" placeholder="Feature Category Name" />
                <label for="att-name">Feature Category Name</label>
              </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditSubAdmin} class="btn cw-btn cw-yellow-btn py-2 px-4">Save</button>
          </div>
    </div>
   
    </>
  ) : modalType == "userDetail" ?(
    <>
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Edit Feature Category</h1>
            <button type="button" onClick={()=> closeModal()} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
              <div class="form-floating cw-input mb-3">
                <input type="text"  defaultValue={name} onChange={(e)=>setname(e.target.value)} class="form-control" id="att-name" placeholder="Feature Category Name" />
                <label for="att-name">Feature Category Name</label>
              </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditSubAdmin} class="btn cw-btn cw-yellow-btn py-2 px-4">Update</button>
          </div>
    </div>

</>
): modalType == "delete" ? (
    <>
  <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Feature Category</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Feature Category?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
                <button onClick={() => blogDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
              </div>
          </div>
    </>
  ) : (
    <></>
  )}
</div>
</Modal>

<div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Features</a></li>
            <li class="breadcrumb-item active" aria-current="page">Feature Categories</li>
          </ol>
        </nav>
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <h2 className="font-size-28 fw-bold mb-0">Feature Categories</h2>
          </div>
          <div className="col-md-6 order-md-2 order-3">
          <div class="dropdown cw-search-dropdown">
            <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
              <span>Filter and Search</span>
              <span>{toggle ? <X /> : <Search />}</span>
            </a>

            <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            <div class="form-floating cw-input mb-3">
                <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="att-name" placeholder="Category Name" />
                <label for="att-name">Category Name</label>
              </div>
              <div className="d-flex gap-2 justify-content-end">
                <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
                <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
              </div>
            </ul>
          </div>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <button class="btn cw-btn cw-yellow-btn px-3" onClick={()=>viewModal("", "add")}>Add Feature Category</button>
          </div>
        </div>
        <div className="cw-radius-20 white-bg cw-shadow p-3">
          <p className="font-size-20 pink-color">Feature Categories : {featurecategories?.length}</p>


          <div class="table-responsive">
            <table class="table cw-table table-borderless table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Category Name</th>
                  <th scope="col">Created By</th>
                  <th style={{ width: '100px' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {load ? new Array(16).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.createdBy?.name}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <div onClick={()=> viewModal(item,"userDetail")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                        <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default FeatureCategories