import { MessageSquarePlus, Search, X, ImageDown } from 'lucide-react'
import { Settings2 } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import ChatInbox from './ChatInbox'
import { useDispatch, useSelector } from 'react-redux';
import { chatList, getBusiness, getProfile, getUsersToStartChat } from '../../store/slices/userSlice';
import { getSocket } from '../../store/slices/socketSlice';
import { useLocation } from 'react-router-dom';

const Chat = () => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    const location = useLocation()
    const dummyImage = "https://via.placeholder.com/50x50"
    const [listOfChat, setlistOfChat] = useState()
    const [users, setusers] = useState()
    const [currentChat, setcurrentChat] = useState(null);
    const [messageChat, setmessageChat] = useState(location?.state ? location?.state : null);
    const [filterExistingChats, setfilterExistingChats] = useState();
    const [type, settype] = useState(null);
    const [filterNewChats, setfilterNewChats] = useState();
    const [toggle, settoggle] = useState(false);
    const [startingToggle, setstartingToggle] = useState(false);
    const [searchExitingText, setsearchExitingText] = useState();
    const [searchNewText, setsearchNewText] = useState();
    const socket = useSelector(getSocket);


    const getAllChats = async () => {
        try {
            const response = await dispatch(chatList()).unwrap();
            console.log("listttttt", response)
            setlistOfChat(response?.data)
            setfilterExistingChats(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log("listttttt", rejectedValueOrSerializedError);
        }
    };



    const unreadAllMessages = async (data) => {
        try {
            setcurrentChat(data)

            socket?.emit("unread_messages", {
                sender_id: profile?._id,
                receiver_id: profile?._id == data?.sender_id?._id ? data?.receiver_id?._id : data?.sender_id?._id,
            });
            getAllChats()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    // useEffect(() => {
    //     unreadAllMessages(location?.state)
    // }, [location?.state])

    const callMessage = () => {
        socket?.on("response", (data) => {
            if (data?.object_type == "unread_messages") {
                console.log(data)
            }
        });
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            getAllChats();
        }
        return () => {
            mount = false;
        };
    }, [type])


    useEffect(() => {
        callMessage();
        return () => socket?.off("response");
    }, [socket, currentChat]);

    const handleSearch = () => {
        if (!searchExitingText) {
            setfilterExistingChats(listOfChat);
        } else {
            const filters = filterExistingChats?.filter((i) => {
                const namematches = profile?._id == i?.sender_id?._id ? i?.receiver_id?.name : i?.sender_id?.name.toLowerCase().includes(searchExitingText?.toLowerCase());
                return namematches;
            });

            setfilterExistingChats(filters);
        }
    };


    useEffect(() => {
        handleSearch()
    }, [searchExitingText]);


    useEffect(() => {
        if(currentChat){
            setmessageChat(null)
        }
    }, [currentChat]);

    return (
        <>
            <section className="sm-hero sm-messages-hero"></section>
            <section class="sm-chat-enqiries">
                <div class="container-fluid">

                    <div class="d-flex white-bg position-relative overflow-hidden">
                        <div class="col-12 col-lg-5 col-xl-3 border">
                            <div class="d-flex align-items-center gap-3 py-3 px-3 yellow-bg">
                                <div class="flex-grow-1 sm-search-chats">
                                    <Search width="18" height="18" className="sm-search-chats-icon" />
                                    <input type="text" class="form-control sm-search-chats-field rounded-0" placeholder="Search..." value={searchExitingText}
                                        onChange={(e) => setsearchExitingText(e.target.value)} />
                                </div>
                                {/* <div class="light-blue-color cursor-pointer" onClick={() => settoggle(true)} >
                                    <MessageSquarePlus width="22" height="22" />
                                </div> */}
                                <div className="light-blue-color cursor-pointer">
                                    <Settings2 width="22" height="22" />
                                </div>
                            </div>
                            <div className="sm-messages-list-container">

                                {filterExistingChats?.map((data, index) => (
                                    <div class="sm-message-user-list d-flex align-items-center gap-2 cursor-pointer" onClick={() => unreadAllMessages(data)} key={index}>
                                        <img src={profile?._id == data?.sender_id[0]?._id ? data?.receiver_id[0]?.imageName ? `${process.env.REACT_APP_IMGURL}${data?.receiver_id[0]?.imageName}` : dummyImage : data?.sender_id[0]?.imageName ?  `${process.env.REACT_APP_IMGURL}${data?.sender_id[0]?.imageName}` : dummyImage } class="rounded-circle sm-user-image" />
                                        <div class="flex-grow-1">
                                            <div className="d-flex align-items-center justify-content-between mb-0">
                                                <p className="font-size-14 light-blue-color titilium-font mb-0">{profile?._id == data?.sender_id[0]?._id ? data?.receiver_id[0]?.name : data?.sender_id[0]?.name} (<b>{data?.businesses?.name}</b>)</p>
                                                <p className="font-size-12 color-grey mb-0">{data?.last_message_date_time}</p>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <p className="font-size-14 titilium-font black-color mb-0">{data?.type === "message" ? (data?.message?.length > 10 ? `${data?.message?.substring(0, 44)}...` : data?.message) : data?.type === "quote" ? "Quotation" : <ImageDown />}</p>
                                                {data?.noOfUnread == 0 ? <></> : <span className="sm-message-count yellow-bg black-color rounded-circle font-size-12">{data?.noOfUnread}</span>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {currentChat || messageChat ? <div className="col-12 col-lg-7 col-xl-9 position-relative border">
                            <ChatInbox currentChat={currentChat} messageChat={messageChat} getAllChats={getAllChats} startingToggle={startingToggle} setstartingToggle={setstartingToggle} />
                        </div > : <div className="col-12 col-lg-7 col-xl-9  border d-flex align-items-center justify-content-center" style={{ height: "89vh" }}>
                            <h2 className="font-size-24 fw-bold yellow-color mb-0">Select Any Chat</h2>
                        </div >}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Chat 