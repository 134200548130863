import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Nav from "./Nav";
import SidebarAdmin from "./SidebarAdmin";
import { context } from "../../context/context";
import DashboardAdmin from "../../pages/core/DashboardAdmin";
import Cities from "../../pages/core/Cities";
import Countries from "../../pages/core/Countries";
import Features from "../../pages/core/Features";
import FeatureCategories from "../../pages/core/FeatureCategories";
import Blogs from "../../pages/core/Blogs";
import BlogCategories from "../../pages/core/BlogCategories";
import AddBlog from "../../pages/core/AddBlog";
import Admin from "../../pages/core/Admin";
import AddAdmin from "../../pages/core/AddAdmin";
import Locations from "../../pages/core/Locations";
import LocationDetails from "../../pages/core/LocationDetails";
import AddLocation from "../../pages/core/AddLocation";
import Users from "../../pages/core/Users";
import Login from "../../pages/auth/Login";
import { getUserRole, getUserStatus, getUserToken } from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import RoomTypes from "../../pages/core/RoomTypes";
import DeskTypes from "../../pages/core/DeskTypes";
import CoworkingTypes from "../../pages/core/CoworkingTypes";
import AddFacilities from "../../pages/core/AddFacilities";
import Inclusions from "../../pages/core/Inclusions";
import Packages from "../../pages/core/Packages";
import AddPackage from "../../pages/core/AddPackage";
import ClaimSpaces from "../../pages/core/ClaimSpaces";
import ReportSpaces from "../../pages/core/ReportSpaces";
import RequestQuoteSpaces from "../../pages/core/RequestQuoteSpaces";
import LandlordProperty from "../../pages/core/LandlordProperty";
import Buisnesses from "../../pages/core/Buisnesses";
import SpacesByBusinesses from "../../pages/core/SpacesByBusinesses";
import UserCustomers from "../../pages/core/UserCustomers";
import Chat from "../../pages/core/Chat";
import Careers from "../../pages/core/Careers";
import ContactUsList from "../../pages/core/ContactUsList";
import DownloadData from "../../pages/core/DownloadData";
import EnterpriseSolutions from "../../pages/core/EnterpriseSolutions";
import NewsletterEmails from "../../pages/core/NewsletterEmails";
import BookedDemos from "../../pages/core/BookedDemos";
import MetaData from "../../pages/core/MetaData";
import Inquiry from "../../pages/core/Inquiry";
import AbandonedSpaces from "../../pages/core/AbandonedSpaces";
import PushEmail from "../../pages/core/PushEmail";




const Layout = () => {
  const { isLoading } = useContext(context);
  const status = useSelector(getUserStatus)
  const authToken = useSelector(getUserToken)
  const role = useSelector(getUserRole)
  

  return (
    <>
        <BrowserRouter>
          <Content authtoken={authToken} role={role} />
        </BrowserRouter>
    </>
  );
};


const Content = ({ authtoken, role }) => {
  var dash = "";
const location = useLocation();
const p = location.pathname.split('/');
const p1 = p[p.length - 1];

for (var i = p1.length - 1; i >= 0; i--) {
  if (p1[i] == '_') {
    console.log("trueeeeeeeee");
    dash = i;
    break;
  }
}

const t = decodeURIComponent(p1.replace(/-/g, ' '));
function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}
useEffect(() => {
  document.title = location.pathname == "/" && !authtoken ? "The Coworking Spaces" : location.pathname == "/" && authtoken ? "Dashboard" :dash !== "" ? capitalizeEachWord(t.substring(0, dash)) : capitalizeEachWord(t);
}, [dash, t, location.pathname]);

  return (
    <>
        {authtoken && (role === "admin" || role === "sub-admin") ? <>
        {location.pathname == "/login" ? <></> : <Nav />}
        {location.pathname == "/login" ? <></> : <SidebarAdmin />}
        
            <section className={location.pathname == "/login" ? "" : "content-section"}>
            <>
              <Routes>
                <Route path="*" element={<DashboardAdmin />} />
                <Route path="/" element={<DashboardAdmin />} />
                <Route path="/cities" element={<Cities />} />
                <Route path="/countries" element={<Countries />} />
                <Route path="/features" element={<Features />} />
                <Route path="/feature-categories" element={<FeatureCategories />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blog-categories" element={<BlogCategories />} />
                <Route path="/add-blog" element={<AddBlog />} />
                <Route path="/edit-blog/:id" element={<AddBlog />} />
                <Route path="/admins" element={<Admin />} />
                <Route path="/add-admin" element={<AddAdmin />} />
                <Route path="/edit-admin" element={<AddAdmin />} />
                <Route path="/Spaces" element={<Locations />} />
                <Route path="/add-space" element={<AddLocation />} />
                <Route path="/add-space-type" element={<AddFacilities />} />
                <Route path="/edit-space-type/:id" element={<AddFacilities />} />
                <Route path="/edit-space/:id" element={<AddLocation />} />
                <Route path="/space-details/:id" element={<LocationDetails />} />
                <Route path="/business-users" element={<Users />} />
                <Route path="/customer-users" element={<UserCustomers />} />
                <Route path="/inclusions" element={<Inclusions />} />
                <Route path="/room-types" element={<RoomTypes />} />
                <Route path="/desk-types" element={<DeskTypes />} />
                <Route path="/coworking-types" element={<CoworkingTypes />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/add-package" element={<AddPackage />} />
                <Route path="/edit-package/:id" element={<AddPackage />} />
                <Route path="/claim-spaces" element={<ClaimSpaces />} />
                <Route path="/report-spaces" element={<ReportSpaces />} />
                <Route path="/user-requested-quotes" element={<RequestQuoteSpaces />} />
                <Route path="/guest-requested-quotes" element={<RequestQuoteSpaces />} />
                <Route path="/landlord-property" element={<LandlordProperty />} />
                <Route path="/admin-businesses" element={<Buisnesses />} />
                <Route path="/owner-businesses" element={<Buisnesses />} />
                <Route path="/business-spaces" element={<SpacesByBusinesses />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contact-us-list" element={<ContactUsList />} />
                <Route path="/download-data" element={<DownloadData />} />
                <Route path="/enterprise-solutions" element={<EnterpriseSolutions />} />
                <Route path="/newsletter-emails" element={<NewsletterEmails />} />
                <Route path="/booked-demos" element={<BookedDemos />} />
                <Route path="/meta-data" element={<MetaData />} />
                <Route path="/inquiries" element={<Inquiry />} />
                <Route path="/abandoned-spaces" element={<AbandonedSpaces />} />
                <Route path="/claim-invitation" element={<PushEmail />} />
              </Routes>
            </>
            </section>
            </> :
            <>
            <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/" element={<Login />} />
            </Routes>
            </>
            }

      
    </>
  );
};

export default Layout;