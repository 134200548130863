import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import {  useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../store/slices/userSlice"
import { AlignJustify, ChevronDown, ChevronUp } from 'lucide-react';


const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const profile = useSelector(getProfile)  
    const { pathname } = location;
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    if (isSidebarOpen) {
        document.body.classList.add('active');
    } else {
        document.body.classList.remove('active');
    }

    const { toggleButton,SetToggleButton } = useContext(context);

    const [togglemenu1, settogglemenu1] = useState(false);
    const OpenDropdown1 = () => {
        return  pathname === "/blogs" ||
        pathname === "/blog-categories" ||
        pathname === "/add-blog";
      };
      useEffect(() => {
        settogglemenu1(OpenDropdown1());
      }, [pathname]);

    const [togglemenu2, settogglemenu2] = useState(false);
    const OpenDropdown2 = () => {
        return  pathname === "/features" ||
        pathname === "/feature-categories";
      };
      useEffect(() => {
        settogglemenu2(OpenDropdown2());
      }, [pathname]);
    const [togglemenu3, settogglemenu3] = useState(false);
    const OpenDropdown3 = () => {
        return  pathname === "/admins" ||
        pathname === "/add-admin";
      };
      useEffect(() => {
        settogglemenu3(OpenDropdown3());
      }, [pathname]);

      const [togglemenu4, settogglemenu4] = useState(false);
      const OpenDropdown4 = () => {
          return  pathname === "/room-types" ||
          pathname === "/desk-types" ||
          pathname === "/coworking-types";
        };
        useEffect(() => {
          settogglemenu4(OpenDropdown4());
        }, [pathname]);

      const [togglemenu5, settogglemenu5] = useState(false);
      const OpenDropdown5 = () => {
          return  pathname === "/spaces" 
          || pathname === "/business-spaces"
          || pathname === "/space-details"
          || pathname === "/add-space";
        };
        useEffect(() => {
          settogglemenu5(OpenDropdown5());
        }, [pathname]);

      const [togglemenu6, settogglemenu6] = useState(false);
      const OpenDropdown6 = () => {
          return  pathname === "/customer-users" 
          || pathname === "/business-users"
        };
        useEffect(() => {
          settogglemenu6(OpenDropdown6());
        }, [pathname]);

      const [togglemenu7, settogglemenu7] = useState(false);
      const OpenDropdown7 = () => {
          return  pathname === "/owner-businesses" 
          || pathname === "/admin-businesses"
        };
        useEffect(() => {
          settogglemenu7(OpenDropdown7());
        }, [pathname]);

      const [togglemenu8, settogglemenu8] = useState(false);
      const OpenDropdown8 = () => {
          return  pathname === "/user-requested-quotes" 
          || pathname === "/guest-requested-quotes"
        };
        useEffect(() => {
          settogglemenu8(OpenDropdown8());
        }, [pathname]);


  return (
    <>
    <div className={toggleButton ? "sidebar close" : "sidebar"}>
            {/* <div onClick={() => SetToggleButton((prev) => !prev)}  className="cw-sidebar-toggle-btn">
            {toggleButton ? <ChevronRight /> : <ChevronLeft />}
            </div> */}
                <div class="logo-details">
                    <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color2)' strokeWidth={1} />
                </div>
                <ul class="nav-links">
                <li className={`${location?.pathname === "/" ? "active" : ""}`}>
                    <div class="icon-link">
                        <Link to="/">
                            <img src='../assets/images/dashboard-icon.svg' />
                            <span class="link_name">Dashboard</span>
                        </Link>
                    </div>
                </li>
                {profile?.system_access?.length > 0 && (<><li className={`${location?.pathname === "/cities" ? "active" : ""}`}>
                    <div class="icon-link">
                        <Link to="/cities">
                            <img src='../assets/images/city-icon.svg' />
                            <span class="link_name">Cities</span>
                        </Link>
                    </div>
                </li>
                <li className={`${location?.pathname === "/countries" ? "active" : ""}`}>
                    <div class="icon-link">
                        <Link to="/countries">
                            <img src='../assets/images/country-icon.svg' />
                            <span class="link_name">Countries</span>
                        </Link>
                    </div>
                </li>
                <li className={`${location?.pathname === "/inclusions" ? "active" : ""}`}>
                    <div class="icon-link">
                        <Link to="/inclusions">
                            <img src='../assets/images/country-icon.svg' />
                            <span class="link_name">Inclusion</span>
                        </Link>
                    </div>
                </li>


                <li className={`${location?.pathname === "/features" || location?.pathname === "/feature-categories" ? "active" : ""} ${togglemenu2 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu2(!togglemenu2)}>
                            <Link>
                            <img src='../assets/images/features-icon.svg' />
                                <span class="link_name">Features</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu2 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu2 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/features" ? "active" : ""}`} to="/features">
                                    All Features
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/feature-categories" ? "active" : ""}`} to="/feature-categories">
                                    Feature Categories
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className={`${location?.pathname === "/desk-types" || location?.pathname === "/room-types" || location?.pathname === "/coworking-types" ? "active" : ""} ${togglemenu4 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu4(!togglemenu4)}>
                            <Link>
                            <img src='../assets/images/types-icon.svg' />
                                <span class="link_name">Types</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu4 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu4 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/desk-types" ? "active" : ""}`} to="/desk-types">
                                    Desk Types
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/room-types" ? "active" : ""}`} to="/room-types">
                                    Room Types
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/coworking-types" ? "active" : ""}`} to="/coworking-types">
                                    Coworking Types
                                </Link>
                            </li>
                        </ul>
                    </li> </>)}
                
                    {profile?.blogs_access?.length > 0 && (<>
                    <li className={`${location?.pathname === "/blogs" || location?.pathname === "/add-blog" || location?.pathname === "/blog-categories" ? "active" : ""} ${togglemenu1 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu1(!togglemenu1)}>
                            <Link>
                            <img src='../assets/images/blogs-icon.svg' />
                                <span class="link_name">Blogs</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu1 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu1 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/blogs" ? "active" : ""}`} to="/blogs">
                                    All Blogs
                                </Link>
                            </li>
                            {/* <li>
                                <Link class={`${location?.pathname === "/add-blog" ? "active" : ""}`} to="/add-blog">
                                    Add Blogs
                                </Link>
                            </li> */}
                            <li>
                                <Link class={`${location?.pathname === "/blog-categories" ? "active" : ""}`} to="/blog-categories">
                                    Categories
                                </Link>
                            </li>
                        </ul>
                    </li>
                    </>)}
                    {profile?.role === "admin" && (
                    <li className={`${location?.pathname === "/admins" || location?.pathname === "/add-admin" ? "active" : ""}`}>
                    <div class="icon-link">
                        <Link to="/admins">
                            <img src='../assets/images/admin-icon.svg' />
                            <span class="link_name">Admin</span>
                        </Link>
                    </div>
                </li>)}
                {profile?.space_access?.length > 0 && (<>
                    <li  className={`${location?.pathname === "/admin-businesses" || location?.pathname === "/owner-businesses" ? "active" : ""} ${togglemenu7 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu7(!togglemenu7)}>
                            <Link>
                            <img src='../assets/images/businesses-icon-cw.svg' />
                                <span class="link_name">Businesses</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu7 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu7 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/admin-businesses" ? "active" : ""}`} to="/admin-businesses">
                                Businesses By Admin
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/owner-businesses" ? "active" : ""}`} to="/owner-businesses">
                                Businesses By Owner
                                </Link>
                            </li>
                        </ul>
                    </li>
                    </>)}
                {profile?.space_access?.length > 0 && (<>
                    <li  className={`${location?.pathname === "/spaces" || location?.pathname === "/add-space" || location?.pathname === "/space-details" || location?.pathname === "/business-spaces" ? "active" : ""} ${togglemenu5 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu5(!togglemenu5)}>
                            <Link>
                            <img src='../assets/images/spaces-icon.svg' />
                                <span class="link_name">Spaces</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu5 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu5 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/spaces" ? "active" : ""}`} to="/spaces">
                                    Spaces By Admin
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/business-spaces" ? "active" : ""}`} to="/business-spaces">
                                Spaces By Business
                                </Link>
                            </li>
                        </ul>
                    </li>
                    </>)}
                
                    {profile?.role === "admin" && (
                    <li className={`${location?.pathname === "/packages" || location?.pathname === "/add-package" ? "active" : ""}`}>
                    <div class="icon-link">
                        <Link to="/packages">
                            <img src='../assets/images/packages-icon.svg' />
                            <span class="link_name">Packages</span>
                        </Link>
                    </div>
                </li>)}
                {profile?.users_access?.length > 0 && (<>
                    <li  className={`${location?.pathname === "/business-users" || location?.pathname === "/customer-users" ? "active" : ""} ${togglemenu6 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu6(!togglemenu6)}>
                            <Link>
                            <img src='../assets/images/users-icon.svg' />
                                <span class="link_name">Users</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu6 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu6 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/business-users" ? "active" : ""}`} to="/business-users">
                                    Buisness Users
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/customer-users" ? "active" : ""}`} to="/customer-users">
                                Customer Users
                                </Link>
                            </li>
                        </ul>
                    </li>
                </>)}
                {profile?.users_access?.length > 0 && (<>
                    <li  className={`${location?.pathname === "/user-requested-quotes" || location?.pathname === "/guest-requested-quotes" ? "active" : ""} ${togglemenu8 ? "showMenu" : ""}`}>
                        <div class="icon-link" onClick={() => settogglemenu8(!togglemenu8)}>
                            <Link>
                            <img src='../assets/images/requested-quotes-icon.svg' />
                                <span class="link_name">Requested Quotes</span>
                            </Link>
                            <span class={`cursor-pointer`}>
                                {togglemenu8 ? <ChevronUp /> : <ChevronDown />}
                            </span>
                        </div>
                        <ul class={`sub-menu ${togglemenu8 && "active"}`}>
                            <li>
                                <Link class={`${location?.pathname === "/user-requested-quotes" ? "active" : ""}`} to="/user-requested-quotes">
                                User Requested Quotes
                                </Link>
                            </li>
                            <li>
                                <Link class={`${location?.pathname === "/guest-requested-quotes" ? "active" : ""}`} to="/guest-requested-quotes">
                                Guest Requested Quotes
                                </Link>
                            </li>
                        </ul>
                    </li>
                    </>)}
            
                {profile?.users_access?.length > 0 && (
                    <li className={`${location?.pathname === "/careers" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/careers">
                            <img src='../assets/images/careers-icon-cw.svg' />
                                <span class="link_name">Careers</span>
                            </Link>
                        </div>
                    </li>)}
                {profile?.users_access?.length > 0 && (
                    <li className={`${location?.pathname === "/claim-spaces" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/claim-spaces">
                            <img src='../assets/images/claim-spaces-icon.svg' />
                                <span class="link_name">Claim Spaces</span>
                            </Link>
                        </div>
                    </li>)}
                {profile?.users_access?.length > 0 && (
                    <li className={`${location?.pathname === "/report-spaces" ? "active" : ""}`}>
                        <div class="icon-link">
                            <Link to="/report-spaces">
                            <img src='../assets/images/report-spaces-icon.svg' />
                                <span class="link_name">Report Spaces</span>
                            </Link>
                        </div>
                    </li>)}
                    {profile?.users_access?.length > 0 && (
                        <li className={`${location?.pathname === "/landlord-property" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/landlord-property">
                                <img src='../assets/images/landlord-property-icon-cw.svg' />
                                    <span class="link_name">Landlord Property</span>
                                </Link>
                            </div>
                        </li>)}
                    {profile?.users_access?.length > 0 && (
                        <li className={`${location?.pathname === "/contact-us-list" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/contact-us-list">
                                <img src='../assets/images/contact-us-icon-cw.svg' />
                                    <span class="link_name">Contact Us</span>
                                </Link>
                            </div>
                        </li>)}
                    {profile?.users_access?.length > 0 && (
                        <li className={`${location?.pathname === "/enterprise-solutions" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/enterprise-solutions">
                                <img src='../assets/images/enterprise-solution-icon-cw.svg' />
                                    <span class="link_name">Enterprise Solutions</span>
                                </Link>
                            </div>
                        </li>)}
                    {profile?.users_access?.length > 0 && (
                        <li className={`${location?.pathname === "/newsletter-emails" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/newsletter-emails">
                                <img src='../assets/images/newsletter-icon-cw.svg' />
                                    <span class="link_name">Newsletter</span>
                                </Link>
                            </div>
                        </li>)}
                    {profile?.users_access?.length > 0 && (
                        <li className={`${location?.pathname === "/booked-demos" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/booked-demos">
                                <img src='../assets/images/newsletter-icon-cw.svg' />
                                    <span class="link_name">Booked Demos</span>
                                </Link>
                            </div>
                        </li>)}
                    {profile?.blogs_access?.length > 0 && (
                        <li className={`${location?.pathname === "/meta-data" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/meta-data">
                                <img src='../assets/images/newsletter-icon-cw.svg' />
                                    <span class="link_name">Meta Data</span>
                                </Link>
                            </div>
                        </li>)}
                        {profile?.users_access?.length > 0 && (
                        <li className={`${location?.pathname === "/inquiries" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/inquiries">
                                <img src='../assets/images/newsletter-icon-cw.svg' />
                                    <span class="link_name">Inquiries</span>
                                </Link>
                            </div>
                        </li>)}
                        {profile?.users_access?.length > 0 && (
                        <li className={`${location?.pathname === "/abandoned-spaces" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/abandoned-spaces">
                                <img src='../assets/images/newsletter-icon-cw.svg' />
                                    <span class="link_name">Abandoned Spaces</span>
                                </Link>
                            </div>
                        </li>)}
                        {profile?.users_access?.length > 0 && (
                        <li className={`${location?.pathname === "/claim-invitation" ? "active" : ""}`}>
                            <div class="icon-link">
                                <Link to="/claim-invitation">
                                <img src='../assets/images/claim-spaces-icon.svg' />
                                    <span class="link_name">Claim Invitation</span>
                                </Link>
                            </div>
                        </li>)}
                </ul>
            </div>
  </>
  
  )
}

export default Sidebar
