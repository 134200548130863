import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { SpaceDetail, deleteReviews, editReviewSpace, getSpaceReviews, spaceClosed } from '../../store/slices/userSlice';
import { useDispatch } from 'react-redux';
import { ArrowRight, Clock, Eye, MapPinned, Pencil, Star, Trash2 } from 'lucide-react';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import Modal from "react-modal";
const customStyles = {
  content: {
    display: "block"
  },
};
Modal.setAppElement("#root");

const LocationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [load, setload] = useState(false);
  const [data, setdata] = useState(null);
  const [reviews, setreviews] = useState(null);
  const urlParts = window.location.pathname.split('/');
  const spaceId = urlParts[urlParts.length - 1];

  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [closingDate, setclosingDate] = useState("");
  const [status, setstatus] = useState("");

  const [id, setId] = useState("");
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [date, setdate] = useState("");
  const [review, setreview] = useState("");

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "view") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "edit") {
      setUserDetail(item);
      setId(item?._id);
      setname(item?.name);
      setdescription(item?.description);
      setdate(item?.date);
      setreview(item?.rating);
    } else if (type == "delete") {
      setId(item);
    } else if (type == "close-space") {
      setstatus(item)
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("");
    setname("");
    setdescription("");
    setdate("");
    setreview("");
    setclosingDate("")
    setstatus("")
  }

  const SpaceDetails = async () => {
    try {
      setload(true)
      setdata(null);
      const response = await dispatch(SpaceDetail(spaceId)).unwrap();
      setdata(response?.data[0]);
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  const SpaceReviews = async () => {
    try {
      setload(true)
      setreviews(null);
      const response = await dispatch(getSpaceReviews(spaceId)).unwrap();
      setreviews(response?.data);
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0})
    SpaceDetails();
    SpaceReviews();
  }, []);

  useEffect(() => {
    if (data) {
      document.title = data?.name
    }
  }, [data, location.pathname]);

  console.log(data, "data");


  const formatDays = (days) => {
    if (!days || days.length === 0) return '';
  
    const allDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const selectedDays = days.map(day => allDays.indexOf(day));
    selectedDays.sort((a, b) => a - b);
  
    if (selectedDays.length === 5 && selectedDays[0] === 0 && selectedDays[selectedDays.length - 1] === 4) {
      return 'Mon - Fri';
    }

    if (selectedDays.length === 7 && selectedDays[0] === 0 && selectedDays[selectedDays.length - 1] === 6) {
      return 'Mon - Sun';
    }

    const formatted = selectedDays.map(day => allDays[day]);
    return formatted.join(', ');
  };

  const formattedDays = formatDays(data?.days);
  console.log(formattedDays);
  
  const reviewDelete = async (id) => {
    try {
      await dispatch(deleteReviews(id)).unwrap();
      setIsOpen(false);
      SpaceReviews();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const editReview = async (id) => {
    try {
      const payload ={
        id: id,
        name: name,
        description: description,
        date: date,
        rating: review,
      }      
      console.log(payload, "addReview");
      await dispatch(editReviewSpace(payload)).unwrap();     
      closeModal()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const closeSpace = async () => {
    try {
      const payload ={
        id: spaceId,
        date: closingDate,
        status: status
      }      
      console.log(payload, "payload");
      await dispatch(spaceClosed(payload)).unwrap();  
      setclosingDate("");
      closeModal();
      SpaceDetails();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  


  return (
    <>
        <Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show cw-modal cw-modal-30`}>

        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
        {modalType == "view" ? (
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Review Detail</h1>
              <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-16 black-color fw-bold mb-0">Name</p>
                <p class="font-size-16 black-color">{userDetail?.name}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Description</p>
                <p class="font-size-16 black-color">{userDetail?.description}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Date</p>
                <p class="font-size-16 black-color">{moment(userDetail?.date).format("DD-MM-YYYY")}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Review Count</p>
                <p class="font-size-16 black-color">{userDetail?.rating}</p>
              </div>
            </div>
            ) : modalType == "edit" ? (
    <>
          <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Edit Review</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
            <div class="form-floating cw-input mb-3">
                <input type="text" class="form-control" id="name-re" placeholder="Name" defaultValue={userDetail?.name} onChange={(e) => setname(e.target.value)}/>
                <label for="name-re">Name</label>
              </div>
              <div class="form-floating cw-textarea mb-3">
                <textarea class="form-control" placeholder="Description" id="Description" defaultValue={userDetail?.description} onChange={(e) => setdescription(e.target.value)} style={{ height: '200px' }}></textarea>
                <label for="Description">Description</label>
              </div>
              <div class="form-floating cw-input mb-3">
                <input type="date" class="form-control" id="Date-re" placeholder="Date" defaultValue={userDetail?.date} onChange={(e) => setdate(e.target.value)}/>
                <label for="Date-re">Date</label>
              </div>
              <div>
              <p className="font-size-14 fw-semibold mb-1">Review</p>
              <div className="star-rating">
                {[1, 2, 3, 4, 5].map((index) => (
                  <span
                    key={index}
                    onClick={() => setreview(index)}
                    className={index <= review ? 'star-filled' : 'star-empty'}
                  >
                    {index <= review ? (
                      <Star size={24} stroke="var(--color3)" fill="var(--color3)" />
                    ) : (
                      <Star size={24} stroke="var(--color3)" />
                    )}
                  </span>
                ))}
              </div>
              </div>
              {/* <div class="form-floating cw-input">
                <input type="number" class="form-control" id="Review-re" placeholder="Review" onChange={(e) => setreview(e.target.value)}/>
                <label for="Review-re">Review</label>
              </div> */}
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={() => editReview(id)}>Submit</button>
            </div>
        </div>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Review</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Review?</p>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button onClick={() => reviewDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
            </div>
        </div>
        </>
        ) : modalType == "close-space" ? (
          <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{status} This Space</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              <div class="form-floating cw-input mb-3">
                <input type="date" class="form-control" id="close-date" placeholder="Date" value={closingDate} onChange={(e) => setclosingDate(e.target.value)}/>
                <label for="close-date">Closing Date</label>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={() => closeSpace()}>Submit</button>
            </div>
        </div>
      ) : null}
        </div>
 
</Modal>
    {load && <Spinner/>}
    <div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item"><a>Spaces</a></li>
        <li class="breadcrumb-item active" aria-current="page">Space Details</li>
      </ol>
    </nav>
      <div className="row gy-3 mb-4 align-items-center mb-2">
        <div className="col-md-6">
          <h2 className="font-size-28 fw-bold mb-0">{data?.name}</h2>
        </div>
        <div className="col-md-6 d-flex justify-content-end gap-3">
        <button class="btn cw-btn cw-yellow-btn px-3" onClick={()=>navigate("/add-space-type", {state: data,})}>Add Space Type</button>
        <Link class="btn cw-btn cw-yellow-btn px-3" to={`/edit-space/${spaceId}`}>Edit Space</Link>
        </div>
        <div className="col-md-6 d-flex gap-3">
        {data?.spaceStatus?.status !== "Temporary Closed" && <button class="btn cw-btn cw-yellow-btn px-3" onClick={() => viewModal("Temporary Closed", "close-space")}>Temporary Close Space</button>}
        {data?.spaceStatus?.status !== "Permanent Closed" && <button class="btn cw-btn cw-yellow-btn px-3" onClick={() => viewModal("Permanent Closed", "close-space")}>Permanent Close Space</button>}
        </div>
      </div>
      <div className="row justify-content-between mb-3">
        <div className="col-md-5 d-flex gap-2 align-items-center">
        <div><MapPinned size={40} stroke='var(--color3)' /></div>
        <div>
          <p className="fw-semibold font-size-16 mb-0">Address:</p>
          <p className="font-size-16 mb-0">{data?.location?.address}</p>
        </div>
        </div>
        <div className="col-md-3 d-flex gap-2 align-items-center">
        <div><MapPinned size={40} stroke='var(--color3)' /></div>
        <div>
          <p className="fw-semibold font-size-16 mb-0">Phone:</p>
          <p className="font-size-16 mb-0">{data?.spacePhone}</p>
        </div>
        </div>
        <div className="col-md-2 d-flex gap-2 align-items-center">
        <div><MapPinned size={40} stroke='var(--color3)' /></div>
        <div>
          <p className="fw-semibold font-size-16 mb-0">Email:</p>
          <p className="font-size-16 mb-0">{data?.spaceEmail}</p>
        </div>
        </div>
        <div className="col-md-2 d-flex gap-2 align-items-center">
        <div><MapPinned size={40} stroke='var(--color3)' /></div>
        <div>
          <p className="fw-semibold font-size-16 mb-0">Whatsapp:</p>
          <p className="font-size-16 mb-0">{data?.spaceMobile}</p>
        </div>
        </div>
      </div>
      <div className="d-flex justify-content-end gap-4">
        <div className="d-flex gap-2 align-items-center">
        <Clock stroke='var(--color3)' />
        <p className="fw-medium font-size-16 mb-0">{data?.spaceStartTime === "00:00" && data?.spaceEndTime === "23:59" ? "24 Hours" : `${data?.spaceStartTime} to ${data?.spaceEndTime}`} ({formattedDays})
        {/* ({data?.days?.length > 0 && `${data.days[0]} - ${data.days[data.days.length - 1]}`}) */}
        </p>
        </div>
        <div className="d-flex gap-2 align-items-center">
        <Star stroke='var(--color3)' />
        <p className="fw-medium font-size-16 mb-0">4.8 . 15 Reviews</p>
        </div>
      </div>

      <div className="mb-3">
          <p className="fw-semibold font-size-16 mb-0">Description:</p>
          <p className="font-size-16 mb-0">{data?.description}</p>
        </div>
        <div className="mb-3">
          <p className="fw-semibold font-size-16 mb-2">Features ({data?.features?.length}):</p>
          <div className="row gy-3 mb-3">
          {data?.features?.map((f,i) => (
            <div key={i} className="col-md-3 d-flex align-items-center gap-2">
            <img src={`${process.env.REACT_APP_IMGURL}/${f?.imageName}`} width={30} alt={f?.name} />
            <p className="font-size-16 grey-color mb-0">{f?.name}</p>
            </div>
          ))}
          </div>
          {/* {[...new Set(data?.features?.map(option => option?.featurecategories?.name))]?.map(category => (
            <>
              <p className="font-size-16 fw-semibold black-color mb-1">{category}</p>
              <div className="row gy-3 gx-3 mb-3">
                {data?.features?.filter(option => option.featurecategories?.name === category)?.map((data, i) => (
                  <div key={i} className="col-md-2">
                  <div className='p-3 h-100' style={{backgroundColor:'#F9F9F9'}}>
                  <p className="mb-0">{data?.name}</p>
                  </div>
                  </div>
                ))}
              </div>
            </>
          ))} */}
        </div>
        <div className="mb-3">
          <p className="fw-semibold font-size-16 mb-2">Locations ({data?.relatedSpaces?.length}):</p>
          <div className="row gy-3">
          {data?.relatedSpaces?.map((item, i) => (
            <div className="col-md-3">
              <div className='p-3 yellow-bg'>
                <div className="d-flex justify-content-between mb-1">
                <p className="mb-0">{item?.name}</p>
                <Link className="black-color" to={`/space-details/${item?._id}`}><ArrowRight /></Link>
                </div>
                <p>{item?.location?.address}</p>
                <div className="d-flex gap-3">
                <div className="cw-count">
                    <p className="mb-0">{item?.deskcategories_count}</p>
                    <p className="font-size-12 mb-0">Desk</p>
                  </div>
                  <div className="cw-count">
                    <p className="mb-0">{item?.rooms_count}</p>
                    <p className="font-size-12 mb-0">Room</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>

      <div className="cw-radius-20 white-bg cw-shadow p-3 mb-3">
      <p className="font-size-20 pink-color">Total Desk Spaces: {data?.desks?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">type</th>
              <th scope="col">No. of desks</th>
              <th scope="col">Desk Area</th>
              <th scope="col">Created By</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.desks?.map((item, i) => (
              <tr key={i}>
                <td style={{verticalAlign:"middle"}}>{i + 1}</td>
                <td style={{verticalAlign:"middle"}}>{item?.name}</td>
                <td style={{verticalAlign:"middle"}}>{item?.type[0]?.name}</td>
                <td style={{verticalAlign:"middle"}}>{item?.deskCount}</td>
                <td style={{verticalAlign:"middle"}}>{item?.deskSize}</td>
                <td className="text-capitalize" style={{verticalAlign:"middle"}}>{item?.adminsId?.role === "admin" ? item?.adminsId?.role : item?.adminsId?.name}</td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    <Link to={`/edit-space-type/${item?._id}?behave=${item?.type[0]?.behave}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                    <div className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3 mb-3">
      <p className="font-size-20 pink-color">Total Virtual Office: {data?.virtualoffices?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">type</th>
              <th scope="col">Created By</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.virtualoffices?.map((item, i) => (
              <tr key={i}>
                <td style={{verticalAlign:"middle"}}>{i + 1}</td>
                <td style={{verticalAlign:"middle"}}>{item?.name}</td>
                <td style={{verticalAlign:"middle"}}>{item?.type[0]?.name}</td>
                <td className="text-capitalize" style={{verticalAlign:"middle"}}>{item?.adminsId?.role === "admin" ? item?.adminsId?.role : item?.adminsId?.name}</td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    <Link to={`/edit-space-type/${item?._id}?behave=${item?.type[0]?.behave}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                    <div className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Total Rooms: {data?.rooms?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">type</th>
              <th scope="col">No. of Persons</th>
              <th scope="col">Space Size</th>
              <th scope="col">Created By</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.rooms?.map((item, i) => (
              <tr key={i}>
                <td style={{verticalAlign:"middle"}}>{i + 1}</td>
                <td style={{verticalAlign:"middle"}}>{item?.name}</td>
                <td style={{verticalAlign:"middle"}}>{item?.type[0]?.name}</td>
                <td style={{verticalAlign:"middle"}}>{item?.capacity}</td>
                <td style={{verticalAlign:"middle"}}>{item?.roomSize}</td>
                <td className="text-capitalize" style={{verticalAlign:"middle"}}>{item?.adminsId?.role === "admin" ? item?.adminsId?.role : item?.adminsId?.name}</td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    <Link to={`/edit-space-type/${item?._id}?behave=${item?.type[0]?.behave}`} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                    <div className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Reviews: {reviews?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Rating</th>
              <th scope="col">Date</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {reviews?.map((item, i) => (
              <tr key={i}>
                <td style={{verticalAlign:"middle"}}>{i + 1}</td>
                <td style={{verticalAlign:"middle"}}>{item?.name}</td>
                <td>{item?.rating}</td>
                <td>{moment(item?.date).format("DD-MM-YYYY")}</td>
                <td style={{verticalAlign:"middle"}}>
                  <div className="d-flex align-items-center gap-2">
                    <div className={`cw-action-icons l-blue-bg`} onClick={() => viewModal(item, "view")}><Eye width="18" /></div>
                    <div className={`cw-action-icons l-blue-bg`} onClick={() => viewModal(item, "edit")}><Pencil width="18" /></div>
                    <div className={`cw-action-icons l-blue-bg`} onClick={() => viewModal(item?._id, "delete")}><Trash2 width="18" /></div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
      
    </>
  )
}

export default LocationDetails