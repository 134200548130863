import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllUsers,
  deleteAccount,
  getRequestedQuotes,
  deleteQuotation
} from "../../store/slices/userSlice";
import moment from "moment"
import Modal from "react-modal";
import { ArrowRight, Eye, Mail, Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const RequestQuoteSpaces = () => {
    const profile = useSelector(getProfile)
    const navigate = useNavigate();
    const location = useLocation();
    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [requestedQuotes, setrequestedQuotes] = useState(null);
    const [filteredData, setfilteredData] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [toggle, settoggle] = useState(false);
    const [load, setload] = useState(false);
    const [isCustomerQuotes, setisCustomerQuotes] = useState(false);
    const [isGuestQuotes, setisGuestQuotes] = useState(false);
  
  
    function viewModal(item, type) {
      setIsOpen(true);
      if (type == "userDetail") {
        setUserDetail(item);
        setId(item?._id);
      } else if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    }
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const userDelete = async (id) => {
      try {
        await dispatch(deleteQuotation(id)).unwrap();
        setIsOpen(false);
        setfilteredData((prev) => prev?.filter(item => item?._id !== id));
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const RequestQuotes = async () => {
      try {
        setload(true);
        setrequestedQuotes(null);
        setfilteredData(null);
        const response = await dispatch(getRequestedQuotes()).unwrap();
        setrequestedQuotes(response?.data);
        if (location.pathname === "/user-requested-quotes") {
          const filterGuest = response?.data?.filter(item => item?.userId?._id);
          setfilteredData(filterGuest)
          setisCustomerQuotes(true)
          setisGuestQuotes(false)
        } else {
          const filterUser = response?.data?.filter(item => item?.guestUserId?.name);
          setfilteredData(filterUser)
          setisGuestQuotes(true)
          setisCustomerQuotes(false)
        }
        // setfilteredData(response?.data);
        setload(false);
       
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    useEffect(() => {
        RequestQuotes();
    }, [location]);


    const [nameFilter, setnameFilter] = useState('');
    const [spaceNameFilter, setspaceNameFilter] = useState('');
    const [dateFilter, setdateFilter] = useState('');
    const [BusinessOwnerFilter, setBusinessOwnerFilter] = useState(false);
    const [notAcceptedFilter, setnotAcceptedFilter] = useState(false);
    const [acceptedFilter, setacceptedFilter] = useState(false);
  
    const applyFilter = () => {
      const filteredResult = requestedQuotes?.filter(item => {
        console.log(item);
  
        const name = item?.userId?.name?.toLowerCase();
        const spacename = item?.spaceId?.name?.toLowerCase();
        const itemCreatedAt = new Date(item?.createdAt);
  
        // Date filter logic
        const dateFilterTimestamp = new Date(dateFilter).setUTCHours(0, 0, 0, 0);
        const itemCreatedAtTimestamp = new Date(itemCreatedAt.setUTCHours(0, 0, 0, 0)).getTime();
  
        return (
          (!dateFilter || itemCreatedAtTimestamp === dateFilterTimestamp) && 
          (nameFilter === '' || name?.toLowerCase()?.includes(nameFilter?.toLowerCase())) &&
          (spaceNameFilter === '' || spacename?.toLowerCase()?.includes(spaceNameFilter?.toLowerCase())) &&
          (!BusinessOwnerFilter || item?.business?.isAdmin === 0) &&
          (!acceptedFilter || item?.chatObject?.user) &&
          (!notAcceptedFilter || (!item?.chatObject?.user && item?.business?.isAdmin === 1))
        );
      });
  
      setfilteredData(filteredResult);
      settoggle(false);
    };

    const resetFilter = () => {
      setdateFilter('');
      setnameFilter('');
      setspaceNameFilter('');
      setnotAcceptedFilter(false)
      setBusinessOwnerFilter(false)
      setacceptedFilter(false)
      setfilteredData(requestedQuotes)
      settoggle(false);
    };
  


  return (
    <>
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
className={`modal fade show cw-modal cw-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
        {modalType == "userDetail" ? (<>
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Requested Quote Detail</h1>
              <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-16 black-color fw-bold mb-0">Name</p>
                <p class="font-size-16 black-color">{userDetail?.userId?.name ? userDetail?.userId?.name : userDetail?.guestUserId?.name}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Email</p>
                <p class="font-size-16 black-color">{userDetail?.userId?.email ? userDetail?.userId?.email : userDetail?.guestUserId?.email}</p>

                {userDetail?.guestUserId?.phone && <>
                <p class="font-size-16 black-color fw-bold mb-0">Phone</p>
                <p class="font-size-16 black-color">{userDetail?.guestUserId?.phone}</p>
                </>}

                <p class="font-size-16 black-color fw-bold mb-0">Duration</p>
                <p class="font-size-16 black-color">{userDetail?.duration}</p>

                <p class="font-size-16 black-color fw-bold mb-0">No. of People</p>
                <p class="font-size-16 black-color">{userDetail?.noOfPeople}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Solution Type</p>
                <p class="font-size-16 black-color">{userDetail?.roomDeskType?.name}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Space Name</p>
                <p class="font-size-16 black-color">{userDetail?.spaceId?.name}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Space Email</p>
                <p class="font-size-16 black-color">{userDetail?.spaceId?.spaceEmail}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Location</p>
                <p class="font-size-16 black-color">{userDetail?.cities?.name}, {userDetail?.countries?.name}</p>
              </div>
            </div>
      </>): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Requested Quote</h1>
              <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Requested Quote?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
                <button onClick={() => userDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>
    <div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Requested Quotes</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">{location.pathname === "/user-requested-quotes" ? "User Requested Quotes" : "Guest Requested Quotes"}</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown cw-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating cw-input mb-3">
              <input type="text" onChange={(e)=>setnameFilter(e.target.value)} value={nameFilter} class="form-control" id="name" placeholder="Name" />
              <label for="name">Name</label>
            </div>
          <div class="form-floating cw-input mb-3">
              <input type="text" onChange={(e)=>setspaceNameFilter(e.target.value)} value={spaceNameFilter} class="form-control" id="space-name" placeholder="Space Name" />
              <label for="space-name">Space Name</label>
            </div>
            <div class="form-floating cw-input mb-3">
              <input type="date" class="form-control" id="date-filter" placeholder="Date" onChange={(e) => setdateFilter(e.target.value)} value={dateFilter}/>
              <label for="date-filter">Date</label>
            </div>
              <div class="mb-3 sp-input-filter">
              <p class="font-size-20 fw-semibold mb-2">Business Owner / Not Accepted / Accepted</p>
              <div class="d-flex gap-2 flex-wrap">
                <input type="checkbox" class="btn-check" id="business-owner" checked={BusinessOwnerFilter} onChange={() => setBusinessOwnerFilter(!BusinessOwnerFilter)} />
                <label class="btn cw-select-btn" for="business-owner">Business Owner</label>

                <input type="checkbox" class="btn-check" id="not-accepted" checked={notAcceptedFilter} onChange={() => setnotAcceptedFilter(!notAcceptedFilter)} />
                <label class="btn cw-select-btn" for="not-accepted">Not Accepted</label>

                <input type="checkbox" class="btn-check" id="accepted" checked={acceptedFilter} onChange={() => setacceptedFilter(!acceptedFilter)} />
                <label class="btn cw-select-btn" for="accepted">Accepted</label>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn cw-btn cw-yellow-btn py-2 px-4" onClick={applyFilter}>Search</button>
              <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">{location.pathname === "/user-requested-quotes" ? "User Requested Quotes" : "Guest Requested Quotes"} : {filteredData?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Space Name</th>
              <th scope="col">Location</th>
              <th scope="col">Accepted By</th>
              <th scope="col">Date</th>
              <th style={{ width: '140px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.userId?.name ? item?.userId?.name : item?.guestUserId?.name}</td>
                <td>{item?.spaceId?.name}</td>
                <td>{item?.cities?.name}, {item?.countries?.name}</td>
                <td>{item?.business?.isAdmin === 0 ? "Business Owner" : item?.chatObject?.user ? item?.chatObject?.user?.name : "Not Accepted"}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    {/* {(profile?._id === item?.chatObject?.user?._id && item?.business?.isAdmin === 1 || !item?.chatObject && item?.business?.isAdmin === 1 || profile?.role === "admin" && item?.business?.isAdmin === 1) && <div onClick={()=> navigate('/chat', { state: item } )} className={`cw-action-icons l-blue-bg`}>{profile?._id === item?.chatObject?.user?._id ? <ArrowRight width="18" /> : <Mail width="18" />}</div>} */}
                    {(item?.business?.isAdmin === 1 && !isGuestQuotes) && (
                      (profile?.role === "admin" || 
                      profile?._id === item?.chatObject?.user?._id || 
                      !item?.chatObject?.user) && 
                      <div onClick={() => navigate('/chat', { state: item })} className={`cw-action-icons l-blue-bg`}>
                        {profile?._id === item?.chatObject?.user?._id ? <ArrowRight width="18" /> : <Mail width="18" />}
                      </div>
                    )}
                    {profile?.users_access?.includes("read") && (<div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>)}
                    {profile?.users_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
  )
}

export default RequestQuoteSpaces