import React, { useEffect, useState } from 'react'
import { getAllCountries, getAllSpaces, getCountryCity, getProfile } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import Spinner from '../../components/Spinner';
import toast from 'react-hot-toast';

const DownloadData = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const profile=useSelector(getProfile)
    const [countries, setcountries] = useState([]);
    const [cities, setcities] = useState([]);
    const [country, setcountry] = useState('');
    const [countryName, setcountryName] = useState('');
    const [cityName, setcityName] = useState('');
    const [countryFilter, setcountryFilter] = useState('');
    const [cityFilter, setcityFilter] = useState('');
    const [load, setload] = useState(false);

    const [search, setsearch] = useState('');
    const [totalCount, settotalCount] = useState('');
    const [locations, setlocations] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    // const Locations = async () => {
    //     try {
    //         setload(true);
    //         let allLocations = [];
    //         let page = 1;
    //         const pageSize = 50; // Adjust if your API has a different page size
        
    //         while (true) {
    //           const response = await dispatch(getAllSpaces({city: cityFilter, country: countryFilter, adminId: "", page: page, search: "", isTrending: "", isFeatured: "", date: "", emailSearch:""})).unwrap();
              
    //           // Append new locations to the array
    //           allLocations = [...allLocations, ...response?.data];
        
    //           // Check if we have fetched all data
    //           if (allLocations.length >= response?.totalCount) {
    //             break;
    //           }
    //           // Move to the next page
    //           page++;
    //         }
    //         console.log(allLocations, "locations");
    //         downloadCSV(allLocations);
    //         setlocations(allLocations);
    //         settotalCount(allLocations.length);
    //     } catch (rejectedValueOrSerializedError) {
    //       setload(false);
    //       console.log(rejectedValueOrSerializedError);
    //     }
    //   };

    const Locations = async () => {
      try {
          setload(true);
          let allLocations = [];
          let page = 1;
          const pageSize = 50; // Adjust if your API has a different page size
  
          while (true) {
              const response = await dispatch(getAllSpaces({
                  city: cityFilter,
                  country: countryFilter,
                  adminId: "",
                  page: page,
                  search: "",
                  isTrending: "",
                  isFeatured: "",
                  date: "",
                  emailSearch: ""
              })).unwrap();
  
              // Check if response data is empty or if response totalCount is not available
              if (!response?.data || response?.data.length === 0 || response?.totalCount === undefined) {
                  toast.error('No data found');
                  setload(false);
                  return;
              }
  
              // Append new locations to the array
              allLocations = [...allLocations, ...response?.data];
  
              // Check if we have fetched all data
              if (allLocations.length >= response?.totalCount) {
                  break;
              }
              // Move to the next page
              page++;
          }
          console.log(allLocations, "locations");
          downloadCSV(allLocations);
          setlocations(allLocations);
          settotalCount(allLocations.length);
      } catch (rejectedValueOrSerializedError) {
          setload(false);
          console.log(rejectedValueOrSerializedError);
      }
  };
  
    
    const Countries = async () => {
    try {
        setcountries(null);
        const response = await dispatch(getAllCountries()).unwrap();
        setcountries(response?.data);
        
    } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
    }
    };

    const Cities = async (countryId) => {
    try {
        setcities(null);
        const response = await dispatch(getCountryCity(countryId)).unwrap();
        setcities(response?.data);
        
    } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
    }
    };

    useEffect(() => {
        if (country) {
          Cities(country);
        }
    }, [country]);
    
    useEffect(() => {
        Countries();
    }, [])

    // useEffect(() => {
    //     if(countryFilter){
    //         Locations();
    //     }
    // }, [countryFilter, cityFilter])

    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
  
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [searchValue1, setSearchValue1] = useState('');
    const [highlightedIndex1, setHighlightedIndex1] = useState(-1);
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      setSearchValue(value);
      if (value.length > 0) {
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    };
    
    const handleOptionClick = (data) => {
      setSearchValue(data?.name);
      setcountry(data?.id); 
      setcountryName(data?.name)
      setcountryFilter(data?._id)
      setShowDropdown(false);
    };
  
    const handleInputChange1 = (e) => {
      const value = e.target.value;
      setSearchValue1(value);
      if (value.length > 0) {
        setShowDropdown1(true);
      } else {
        setShowDropdown1(false);
      }
    };
    const handleOptionClick1 = (data) => {
      setSearchValue1(data?.name);
      setcityName(data?.name);
      setcityFilter(data?._id);
      setShowDropdown1(false);
    };
  
    const filteredCountries = countries?.filter((data) =>
      data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    const filteredCities = cities?.filter((data) =>
      data?.name?.toLowerCase().includes(searchValue1?.toLowerCase())
    );
  
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        // Move to the next item in the list
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === 'ArrowUp') {
        // Move to the previous item in the list
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      } else if (e.key === 'Enter') {
        // Select the highlighted item when Enter key is pressed
        if (highlightedIndex !== -1) {
          handleOptionClick(filteredCountries[highlightedIndex]);
        }
      }
    };
    const handleKeyDown1 = (e) => {
        if (e.key === 'ArrowDown') {
          // Move to the next item in the list
          setHighlightedIndex1((prevIndex) =>
            prevIndex < filteredCities.length - 1 ? prevIndex + 1 : prevIndex
          );
        } else if (e.key === 'ArrowUp') {
          // Move to the previous item in the list
          setHighlightedIndex1((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (e.key === 'Enter') {
          // Select the highlighted item when Enter key is pressed
          if (highlightedIndex !== -1) {
            handleOptionClick1(filteredCities[highlightedIndex1]);
          }
        }
    };
    const columns = {
        originalColumn1: 'Space Name',
        originalColumn2: 'Business Name',
        originalColumn3: 'Email',
        originalColumn4: 'Address',
        originalColumn5: 'Phone Number',
        originalColumn6: 'slug',
        originalColumn7: 'City',
        originalColumn8: 'Country',
        originalColumn9: 'Website',
        originalColumn10: 'Facebook',
        originalColumn11: 'Instagram',
        originalColumn12: 'Twitter',
        originalColumn13: 'Tiktok',
        originalColumn14: 'Youtube',
        originalColumn15: 'linkedIn',
      };
      
      const transformData = (data) => {
        return data.map(item => {
          return {
            [columns.originalColumn1]: item?.name,
            [columns.originalColumn2]: item?.businesses?.name,
            [columns.originalColumn3]: item?.spaceEmail,
            [columns.originalColumn4]: item?.location?.address,
            [columns.originalColumn5]: item?.spacePhone,
            [columns.originalColumn6]: `https://www.thecoworkingspaces.com/space/${item?.slug}`,
            [columns.originalColumn7]: item?.cities?.name,
            [columns.originalColumn8]: item?.countries?.name,
            [columns.originalColumn9]: item?.website,
            [columns.originalColumn10]: item?.social?.faceBook,
            [columns.originalColumn11]: item?.social?.instagram,
            [columns.originalColumn12]: item?.social?.twitter,
            [columns.originalColumn13]: item?.social?.tiktok,
            [columns.originalColumn14]: item?.social?.youtube,
            [columns.originalColumn15]: item?.social?.linkedIn,
          };
        });
      };
      
      const downloadCSV = (data) => {
        if (data && data.length > 0) {
          const transformedData = transformData(data);
          const csv = Papa.unparse(transformedData, {
            headers: Object.values(columns), // Use custom headers
          });
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          if(cityName){
            saveAs(blob, `coworking_spaces_in_${cityName?.toLowerCase()}.csv`);
          } else {
            saveAs(blob, `coworking_spaces_in_${countryName?.toLowerCase()}.csv`);
          }
          setload(false)
        } else {
          toast.error("No data available to download.");
          setload(false)
        }
      };
    // const downloadCSV = (data) => {
    //     if (data && data.length > 0) {
    //       const csv = Papa.unparse(data);
    //       const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    //       saveAs(blob, 'locations.csv');
    //     } else {
    //       console.error("No data available to download.");
    //     }
    //   };

    const fetchAndDownloadLocations = async () => {
        await Locations();
    };

    // console.log(locations, "locations");

  return (
    <>
    {load && <Spinner />}
    <div className="cw-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Download Data</li>
        </ol>
    </nav>
    <h2 className="font-size-28 fw-bold mb-3">Download Data</h2>
    <div className="cw-radius-20 white-bg cw-shadow p-3 mb-4">
      <div className="row gy-3">
      <div className="col-md-6">
        <div class="form-floating cw-input">
        <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue} id="country" placeholder="country" autoComplete="off"/>
        <label for="country">Select Country</label>
        </div>
        {showDropdown && (
        <div className="dropdown cw-select-dropdown">
            <ul className="dropdown-menu show" style={{ width: '100%' }}>
            {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
            ))}
            {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
            )}
            </ul>
        </div>
        )}
        </div>
        {country && (
        <div className="col-md-6">
            <div class="form-floating cw-input">
            <input type="text" class="form-control" onKeyDown={handleKeyDown1} maxLength={120} onChange={(e) => handleInputChange1(e)} value={searchValue1} id="city" placeholder="city" autoComplete="off" />
            <label for="city">City</label>
            </div>
            {showDropdown1 && (
            <div className="dropdown cw-select-dropdown">
                <ul className="dropdown-menu show" style={{ width: '100%' }}>
                {cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.map((data, index) => (
                    <li key={index} onClick={() => handleOptionClick1(data)} className={highlightedIndex1 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                ))}
                {cities?.length > 0 && cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.length === 0 && (
                    <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                )}
                </ul>
            </div>
            )}
        </div>
        )}
      </div>
  
    </div>

    <div class="d-flex justify-content-center gap-2">
      <button class="btn cw-btn cw-black-outline-btn py-2 px-4" onClick={fetchAndDownloadLocations}>Download</button>
    </div>
    </div>
    </>
  )
}

export default DownloadData