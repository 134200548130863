import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCity,
  deleteCity,
  editCity,
  getCity,
  getCountry,
  getCountryCity,
  getCountryCitySearch,
  getProfile
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Pencil, ThumbsUp, Trash2, X } from "lucide-react";
import { Search } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const customStyles = {
  content: {
    display: "block",
    paddingRight: "0"
  },
};
Modal.setAppElement("#root");

const Cities = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = useSelector(getProfile)
  const [search, setsearch] = useState('');
  const [search2, setsearch2] = useState('');
  const [totalCount, settotalCount] = useState('');
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [cities, setcities] = useState(null);
  const [countries, setcountries] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [cityCountry, setcityCountry] = useState("");
  const [imageName, setimageName] = useState("");
  const [feature, setfeature] = useState("");
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);

  const [seoTitle, setseoTitle] = useState("");
  const [seoTitleDescription, setseoTitleDescription] = useState("");
  const [description, setdescription] = useState("");
  const [longDescription, setlongDescription] = useState("");
  const [keywords, setkeywords] = useState("");


  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    console.log("feature", feature);
    console.log("type", type);

    if (type == "userDetail" && feature == "add") {
      setId();
      setcity();
      setcountry();
      setimageName();
      setfeature("add")
    }
    if (type == "userDetail" && feature == "edit") {
      setfeature("edit")
      setUserDetail(item);
      setId(item?._id);
      setseoTitle(item?.seoTitle)
      setseoTitleDescription(item?.seoTitleDescription)
      setdescription(item?.description)
      setlongDescription(item?.longDescription)
      setkeywords(item?.keywords)
    }
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const cityDelete = async (id) => {
    try {
      await dispatch(deleteCity(id)).unwrap();
      setIsOpen(false);
      // Cities(cityCountry);
      setcities((prevCities) => prevCities?.filter(city => city?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const Cities = async (countryId) => {
    try {
      setload(true)
      setcities(null);
      const response = await dispatch(getCountryCitySearch({ countryId, page: currentPage, search: search2 })).unwrap();
      setcities(response?.data);
      settotalCount(response?.count)
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log(cities, "cities");

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getCountry()).unwrap();
      console.log('responsessss', response?.data)
      setcountries(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  useEffect(() => {
    if (cityCountry) {
      Cities(cityCountry);
    }
  }, [cityCountry, search2, currentPage]);

  useEffect(() => {
    Countries();
  }, []);


  const addandEditCity = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", city);
      data.append("cityImage", imageName);
      data.append("seoTitle", seoTitle);
      data.append("seoTitleDescription", seoTitleDescription);
      data.append("description", description);
      data.append("longDescription", longDescription);
      data.append("keywords", keywords);
      await dispatch(editCity(data)).unwrap();
      setIsOpen(false);
      Cities(cityCountry);
      setId("")
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log('country', country)
  console.log('totalCount', totalCount)

  useEffect(() => {
    setcountry(userDetail?.countryId?._id)
  }, [userDetail])

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };
  const handleOptionClick = (data) => {
    setSearchValue(data?.name);
    setcityCountry(data?.id);
    setShowDropdown(false);
  };

  const filteredCountries = countries?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      // Move to the next item in the list
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      // Move to the previous item in the list
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      // Select the highlighted item when Enter key is pressed
      if (highlightedIndex !== -1) {
        handleOptionClick(filteredCountries[highlightedIndex]);
      }
    }
  };

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  const renderPaginationLinks = () => {
    const pages = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {

      for (let page = 1; page <= totalPages; page++) {
        pages.push(renderPageLink(page));
      }
    } else {

      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);


      if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }


      pages.push(renderPageLink(1));


      if (startPage > 2) {
        pages.push(renderEllipsis());
      }


      for (let page = startPage === 1 ? 2 : startPage; page <= endPage; page++) {
        pages.push(renderPageLink(page));
      }


      if (endPage < totalPages - 1) {
        pages.push(renderEllipsis());
      }


      if (endPage !== totalPages) {
        pages.push(renderPageLink(totalPages));
      }
    }

    return pages;
  };


  const renderPageLink = (page) => (
    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
      <a className="page-link" onClick={() => handlePageChange(page)}>
        {page}
      </a>
    </li>
  );

  const renderEllipsis = () => (
    <li key="ellipsis" className="page-item disabled">
      <span className="page-link">...</span>
    </li>
  );

  const handleKeyDown2 = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setsearch2(search);
    }
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ];

  const handleChange = (html) => {
    setlongDescription(html);
  };



  return (
    <>
      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Change Password"
        className={`modal fade show cw-modal cw-modal-30`}
      >
        <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
          {modalType == "userDetail" && feature == "edit" ? (
            <>
            <div class="modal-content">
              <div class="modal-header border-0">
                <h1 class="font-size-22 dm-font purple-color">Edit City</h1>
                <button type="button" onClick={() => closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="form-floating cw-input mb-3">
                  <input type="text" onChange={(e) => setcity(e.target.value)} defaultValue={userDetail?.name} class="form-control" id="att-name" placeholder="City Name" />
                  <label for="att-name">City Name</label>
                </div>
                <div class="form-floating cw-input mb-3">
                  <input type="file" onChange={(e) => setimageName(e.target.files[0])} class="form-control" id="att-file" placeholder="City Banner" />
                  <label for="att-file">City Banner</label>
                </div>
                <div class="form-floating cw-input mb-3">
                  <input type="text" onChange={(e) => setseoTitle(e.target.value)} value={seoTitle} class="form-control" id="att-name" placeholder="SEO Title" />
                  <label for="att-name">SEO Title</label>
                </div>
                <div class="form-floating mb-3 cw-textarea">
                  <textarea class="form-control" placeholder="description" id="seo-description" onChange={(e) => setseoTitleDescription(e.target.value)} value={seoTitleDescription}
                    style={{ height: '100px' }}></textarea>
                  <label for="seo-description">SEO Description</label>
                </div>
                <div class="form-floating mb-3 cw-textarea">
                  <textarea class="form-control" placeholder="description" id="description" onChange={(e) => setdescription(e.target.value)} value={description}
                    style={{ height: '120px' }}></textarea>
                  <label for="description">Description</label>
                </div>
                <div class="mb-3 pb-5 cw-html-text-editor">
                  <p class="font-size-16 fw-semibold black-color mb-2">Long Description</p>
                  <ReactQuill
                    theme="snow"
                    value={longDescription}
                    onChange={handleChange}
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    style={{ height: '300px', marginBottom: '45px' }}
                  />
                </div>
                <div class="form-floating mb-3 mt-3 cw-textarea">
                  <textarea class="form-control" placeholder="keywords" id="keywords" onChange={(e) => setkeywords(e.target.value)} maxLength={220} value={keywords}
                    style={{ height: '100px' }}></textarea>
                  <label for="keywords">Keywords</label>
                </div>
              </div>
              <div class="modal-footer border-0 justify-content-center">
                <button onClick={addandEditCity} class="btn cw-btn cw-yellow-btn py-2 px-4">Update</button>
              </div>
            </div>
            </>
          ) : modalType == "delete" ? (
            <>
              <div class="modal-content">
                <div class="modal-header border-0 pb-0">
                  <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete City</h1>
                  <button type="button" onClick={() => closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body mt-0">
                  <p class="font-size-14 color-black mb-0">Are you sure you want to delete this City?</p>
                </div>
                <div class="modal-footer border-0 justify-content-center gap-2">
                  <button onClick={closeModal} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
                  <button onClick={() => cityDelete(id)} class="btn cw-btn cw-yellow-btn py-2 px-4">Delete</button>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Modal>

      <div className="cw-areas-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Cities</li>
          </ol>
        </nav>
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <h2 className="font-size-28 fw-bold mb-0">Cities</h2>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <div className="">
              <div class="form-floating cw-input">
                <input type="text" class="form-control" onKeyDown={handleKeyDown} maxLength={120} onChange={(e) => handleInputChange(e)} value={searchValue} id="country" placeholder="country" autoComplete="off" />
                <label for="country">Select Country</label>
              </div>
              {showDropdown && (
                <div className="dropdown cw-select-dropdown">
                  <ul className="dropdown-menu show" style={{ width: '100%' }}>
                    {countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                      <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                    ))}
                    {countries?.length > 0 && countries?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                      <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            {/* <div class="dropdown cw-select-dropdown">
          <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {cityCountry ? countries.find(user => user?.id === cityCountry)?.name : "Country"}
          </a>
          <ul class="dropdown-menu">
          {countries?.map((c,i)=>(
            <li key={i}><a onClick={(e)=>setcityCountry(c?.id)} class="dropdown-item">{c?.name}</a></li>
            ))}
          </ul>
        </div> */}
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            {/* <button class="btn cw-btn cw-yellow-btn px-3" onClick={() => viewModal(null, "userDetail", "add")}>Add City</button> */}
          </div>
        </div>
        <div className="cw-radius-20 white-bg cw-shadow p-3">
          <div className="row mb-3">
            <div className="col-md-6 align-self-center">
              <p className="font-size-20 pink-color mb-0">Cities : {totalCount}</p>
            </div>
            <div className="col-md-6">
              <div class="flex-grow-1 sm-search-chats">
                <Search width="18" height="18" className="sm-search-chats-icon" stroke="var(--color3)" />
                <input type="text" class="form-control sm-search-chats-field rounded-0" style={{ borderColor: "var(--color3) !important" }} placeholder="Search..." value={search}
                  onChange={(e) => setsearch(e.target.value)} onKeyDown={handleKeyDown2} />
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table cw-table table-borderless table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  {/* <th scope="col">ID</th> */}
                  <th scope="col">City Name</th>
                  <th scope="col">Country</th>
                  <th scope="col">Total Spaces</th>
                  <th style={{ width: '100px' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : cities?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + startIndex + 1}</td>
                    {/* <td>{item?._id}</td> */}
                    <td>{item?.name}</td>
                    <td>{item?.country_name}</td>
                    <td>{item?.noOfSpaces}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <div onClick={() => viewModal(item, "userDetail", "edit")} className={`cw-action-icons l-blue-bg`}><Pencil width="18" /></div>
                        <div onClick={() => viewModal(item?._id, "delete")} className={`cw-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
          {totalCount > 20 &&
            <div className="cw-pagination" aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                  <a className="page-link" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {renderPaginationLinks()}
                <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                  <a className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </div>}
        </div>
      </div>
    </>
  )
}

export default Cities