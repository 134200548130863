import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { ArrowRight, LockKeyhole, Mail } from 'lucide-react';
import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';
import { getProfile, getUserRight, signinAdmin } from "../../store/slices/userSlice";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRight = useSelector(getUserRight);
    const profile = useSelector(getProfile);
    const [load, setload] = useState(false);

    const [user_device_token, setuser_device_token] = useState(true)

    console.log("login right check", userRight, profile)
  
  
    const handleSubmit = async (e) => {
      setload(true);
      e.preventDefault();
      try {
          let payload = {
              email,
              password,
              devicetoken: user_device_token
          }
          const response = await dispatch(signinAdmin(payload)).unwrap();

          navigate('/dashboard');
          setload(false);
      } catch (rejectedValueOrSerializedError) {
        setload(false);
          console.log(rejectedValueOrSerializedError);
      }
  };
  
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSecureEntry, setisSecureEntry] = useState(true)


  return (
    <>
<section class="vh-100 authentication-bg">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-sm rounded-0 border-0">
          <div class="card-body p-5 text-center">

          <img className="mx-auto d-block auth-logo mb-5" width="300" src={"../assets/images/logo.svg"} />
            <form onSubmit={handleSubmit}>
            <div class="input-group cw-input-group mb-4">
                <span class="input-group-text"><Mail /></span>
                <div class="form-floating">
                    <input type="email" class="form-control" id="email" placeholder="email" onChange={(e) => setEmail(e.target.value)}/>
                    <label for="email">Email</label>
                </div>
                </div>
                <div class="input-group cw-input-group mb-4">
                <span class="input-group-text"><LockKeyhole /></span>
                <div class="form-floating">
                    <input type={isSecureEntry ? "password" : "text"} class="form-control" id="Password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                    <label for="Password">Password</label>
                    <span onClick={() => { setisSecureEntry((prev) => !prev) }} className="cw-eye-auth">{isSecureEntry ? <Eye /> : <EyeOff />}</span>
                </div>
                </div>

              <button class="btn cw-btn cw-black-btn w-100" type="submit">{load ? <div class="loading-dots">
                <div class="loading-dot"></div>
                <div class="loading-dot"></div>
                <div class="loading-dot"></div>
              </div> : "Log in"}</button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </>
  )
}

export default Login