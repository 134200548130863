import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword, getProfile } from '../store/slices/userSlice'
import { LockKeyhole } from 'lucide-react';
import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';


const ChangePassword = ({ closeModal }) => { 
    const dispatch = useDispatch()
    const profile = useSelector(getProfile) 
    const [old_password, setCurrentPassword] = useState("")
    const [new_password, setNewPassword] = useState("")
    const [confirm_password, setConfirmNewPassword] = useState("")
    const [isSecureEntry, setisSecureEntry] = useState(true)
    const [isSecureEntry2, setisSecureEntry2] = useState(true)
    const [isSecureEntry3, setisSecureEntry3] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                currentPassword: old_password, 
                newPassword: new_password, 
                confirmNewPassword: confirm_password
            }
            await dispatch(changePassword(payload)).unwrap()
            closeModal()
            setCurrentPassword('')
            setNewPassword('')
            setConfirmNewPassword('')
        } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
        }
    } 
 
  return (
    <>
    <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Change Password</h1>
            <button type="button" onClick={()=> closeModal()} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form onSubmit={handleSubmit}>
        <div class="modal-body">
            <div class="input-group cw-input-group mb-3">
                <span class="input-group-text"><LockKeyhole /></span>
                <div class="form-floating">
                    <input type={isSecureEntry ? "password" : "text"} class="form-control" id="oldPassword" placeholder="Password" value={old_password} onChange={(e) => setCurrentPassword(e.target.value)}/>
                    <label for="oldPassword">Old Password</label>
                    <span onClick={() => { setisSecureEntry((prev) => !prev) }} className="cw-eye-auth">{isSecureEntry ? <Eye /> : <EyeOff />}</span>
                </div>
            </div>
            <div class="input-group cw-input-group mb-3">
                <span class="input-group-text"><LockKeyhole /></span>
                <div class="form-floating">
                    <input type={isSecureEntry2 ? "password" : "text"} class="form-control" id="Password" placeholder="Password" value={new_password} onChange={(e) => setNewPassword(e.target.value)}/>
                    <label for="Password">Password</label>
                    <span onClick={() => { setisSecureEntry2((prev) => !prev) }} className="cw-eye-auth">{isSecureEntry2 ? <Eye /> : <EyeOff />}</span>
                </div>
            </div>
            <div class="input-group cw-input-group">
                <span class="input-group-text"><LockKeyhole /></span>
                <div class="form-floating">
                    <input type={isSecureEntry3 ? "password" : "text"} class="form-control" placeholder="Password" value={confirm_password} id="cpassword" onChange={(e) => setConfirmNewPassword(e.target.value)}/>
                    <label for="cpassword">Confirm Password</label>
                    <span onClick={() => { setisSecureEntry3((prev) => !prev) }} className="cw-eye-auth">{isSecureEntry3 ? <Eye /> : <EyeOff />}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button class="btn cw-btn cw-yellow-btn py-2 px-4" type="submit">Update</button>
          </div>
          </form>
    </div>
    </div>
    </> 
  )
}

export default ChangePassword