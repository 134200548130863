import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"
import Modal from "react-modal";
import { Eye, Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAbandonedSpaces } from "../../store/slices/userSlice";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const AbandonedSpaces = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [abandonedSpaces, setabandonedSpaces] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [load, setload] = useState(false);

    const [totalCount, settotalCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const totalPages = Math.ceil(totalCount / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalCount);
  
    function viewModal(item, type) {
      setIsOpen(true);
      if (type == "userDetail") {
        setUserDetail(item);
      }
      setModalType(type);
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
      setUserDetail("")
    }
  
    const AbandonedSpacesList = async () => {
      try {
        setload(true);
        setabandonedSpaces(null);
        const response = await dispatch(getAbandonedSpaces({page: currentPage, search: ""})).unwrap();
        setabandonedSpaces(response?.data);
        settotalCount(response?.totalCount);
        setload(false);
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    useEffect(() => {
        AbandonedSpacesList();
    }, [currentPage]);

    const handlePageChange = (page) => {
        navigate(`${location.pathname}?page=${page}`);
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    
      useEffect(() => {
        if (location.search) {
          const queryParams = new URLSearchParams(location.search);
          const pageParam = queryParams.get('page');
          const parsedPage = parseInt(pageParam, 10) || 1;
          setCurrentPage(parsedPage);
        }
      }, [location.search]);
    
      const renderPaginationLinks = () => {
        const pages = [];
        const maxPagesToShow = 5; 
        if (totalPages <= maxPagesToShow) {
          for (let page = 1; page <= totalPages; page++) {
            pages.push(renderPageLink(page));
          }
        } else {
          let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
          const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
          if (endPage === totalPages) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
          }
          pages.push(renderPageLink(1));
          if (startPage > 2) {
            pages.push(renderEllipsis());
          }
          for (let page = startPage === 1 ? 2 : startPage; page <= endPage; page++) {
            pages.push(renderPageLink(page));
          }
          if (endPage < totalPages - 1) {
            pages.push(renderEllipsis());
          }
          if (endPage !== totalPages) {
            pages.push(renderPageLink(totalPages));
          }
        }
        return pages;
      };
      
      
      const renderPageLink = (page) => (
        <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
          <a className="page-link" onClick={() => handlePageChange(page)}>
            {page}
          </a>
        </li>
      );
      
      const renderEllipsis = () => (
        <li key="ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );

  return (
    <>
    <Modal closeTimeoutMS={0} isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} className={`modal fade show cw-modal`}>
      <div class="modal-dialog modal-dialog-centered cw-modal-dialog">
      {modalType == "userDetail" ? (<>
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Landlord Property Detail</h1>
            <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              <p class="font-size-16 black-color fw-bold mb-0">Business Name</p>
              <p class="font-size-16 black-color">{userDetail?.businessName}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Space Name</p>
              <p class="font-size-16 black-color">{userDetail?.name}</p>
              
              <p class="font-size-16 black-color fw-bold mb-0">Email</p>
              <p class="font-size-16 black-color">{userDetail?.userEmail}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Space Manager Name</p>
              <p class="font-size-16 black-color">{userDetail?.spaceManagerName}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Space Phone</p>
              <p class="font-size-16 black-color">{userDetail?.spacePhone}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Space Email</p>
              <p class="font-size-16 black-color">{userDetail?.spaceEmail}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Website</p>
              <p class="font-size-16 black-color">{userDetail?.website}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Address</p>
              <p class="font-size-16 black-color">{userDetail?.location?.address}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Location</p>
              <p class="font-size-16 black-color">{userDetail?.cities?.name}, {userDetail?.countries?.name}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Days</p>
                <p class="font-size-16 black-color">{userDetail?.days?.join(', ')}</p>

                <p class="font-size-16 black-color fw-bold mb-0">Time</p>
                <p class="font-size-16 black-color">{userDetail?.spaceStartTime === "00:00" && userDetail?.spaceEndTime === "23:59" ? "24 Hours" : `${userDetail?.spaceStartTime} to ${userDetail?.spaceEndTime}`}</p>
                
                {userDetail?.social?.faceBook && <>
                <p class="font-size-16 black-color fw-bold mb-0">Facebook</p>
                <p class="font-size-16 black-color">{userDetail?.social?.faceBook}</p>
                </>}
                
                {userDetail?.social?.instagram && <>
                <p class="font-size-16 black-color fw-bold mb-0">Instagram</p>
                <p class="font-size-16 black-color">{userDetail?.social?.instagram}</p>
                </>}
                
                {userDetail?.social?.linkedIn && <>
                <p class="font-size-16 black-color fw-bold mb-0">LinkedIn</p>
                <p class="font-size-16 black-color">{userDetail?.social?.linkedIn}</p>
                </>}
                
                {userDetail?.social?.tiktok && <>
                <p class="font-size-16 black-color fw-bold mb-0">Tiktok</p>
                <p class="font-size-16 black-color">{userDetail?.social?.tiktok}</p>
                </>}
                
                {userDetail?.social?.twitter && <>
                <p class="font-size-16 black-color fw-bold mb-0">X/Twitter</p>
                <p class="font-size-16 black-color">{userDetail?.social?.twitter}</p>
                </>}
                
                {userDetail?.social?.youtube && <>
                <p class="font-size-16 black-color fw-bold mb-0">Youtube</p>
                <p class="font-size-16 black-color">{userDetail?.social?.youtube}</p>
              </>}

              <p class="font-size-16 black-color fw-bold mb-0">Types</p>
              <p class="font-size-16 black-color">{userDetail?.types?.map((item, i) => (
                  <span key={i}>{item?.name}, </span>
                ))}</p>

              <p class="font-size-16 black-color fw-bold mb-0">Features</p>
              <p class="font-size-16 black-color">{userDetail?.features?.map((item, i) => (
                  <span key={i}>{item?.name}, </span>
                ))}</p>
            </div>
          </div>
    </>) : null}
      </div>
    </Modal>

    <div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Abandoned Spaces</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-12 col-12 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Abandoned Spaces</h2>
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Abandoned Spaces : {totalCount}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Business Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone</th>
              <th scope="col">Location</th>
              <th scope="col">Error</th>
              <th style={{ width: '140px' }} scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={9}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : abandonedSpaces?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.businessName}</td>
                <td>{item?.userEmail}</td>
                <td>{item?.spacePhone}</td>
                <td>{item?.cities?.name}, {item?.countries?.name}</td>
                <td>{item?.errorMessage}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <div onClick={() => viewModal(item, "userDetail")} className={`cw-action-icons l-blue-bg`}><Eye width="18" /></div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
      <div className="cw-pagination" aria-label="Page navigation example">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
          <a className="page-link" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {renderPaginationLinks()}
        <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
          <a className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </div>
    </div>
  </div>
    </>
  )
}

export default AbandonedSpaces