import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllUsers,
  deleteAccount,
  getClaimSpaces,
  getNewsletterEmails
} from "../../store/slices/userSlice";
import moment from "moment"
import { Eye, Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";

const NewsletterEmails = () => {
    const profile=useSelector(getProfile)
    const dispatch = useDispatch();
    const [newsletterEmails, setnewsletterEmails] = useState(null);
    const [load, setload] = useState(false);
  
    
    const EmailForNewsletter = async () => {
      try {
        setload(true);
        setnewsletterEmails(null);
        const response = await dispatch(getNewsletterEmails()).unwrap();
        console.log('responsessss', response?.data)
        setnewsletterEmails(response?.data?.filter(item => item?.email !== null && item?.email !== ''));
        setload(false);
       
      } catch (rejectedValueOrSerializedError) {
        setload(false);
        console.log(rejectedValueOrSerializedError);
      }
    };
  
    useEffect(() => {
        EmailForNewsletter();
    }, []);


  return (
    <>
    <div className="cw-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb cw-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Newsletter Email</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 fw-bold mb-0">Newsletter Email</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">

      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        
      </div>
    </div>
    <div className="cw-radius-20 white-bg cw-shadow p-3">
      <p className="font-size-20 pink-color">Newsletter Email : {newsletterEmails?.length}</p>
      <div class="table-responsive">
        <table class="table cw-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Email</th>
              <th style={{ width: '140px' }} scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : newsletterEmails?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.email}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
  )
}

export default NewsletterEmails