import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { addPackages, editPackages, getPackages } from '../../store/slices/userSlice';
import Spinner from '../../components/Spinner';

const AddPackage  = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [editPackage, seteditPackage] = useState(null);
    const [packageList, setpackageList] = useState(null);
    const [otherLead, setotherLead] = useState(false);

    const [name, setname] = useState("");
    const [description, setdescription] = useState("");
    const [freeTrialDays, setfreeTrialDays] = useState("");
    const [monthlyPrice, setmonthlyPrice] = useState("");
    const [yearlyPrice, setyearlyPrice] = useState("");
    const [yearlySavingsPercentage, setyearlySavingsPercentage] = useState("");
    const [numberOfLocations, setnumberOfLocations] = useState("");
    const [numberOfLeads, setnumberOfLeads] = useState("");
    const [BasicListing, setBasicListing] = useState("");
    const [EnhancedListing, setEnhancedListing] = useState("");
    const [SearchRanking, setSearchRanking] = useState("");
    const [VisibilityDirectory, setVisibilityDirectory] = useState("");
    const [AddressVisibility, setAddressVisibility] = useState("");
    const [BasicReporting, setBasicReporting] = useState("");
    const [UserReviews, setUserReviews] = useState("");
    const [AdvancedAnalytics, setAdvancedAnalytics] = useState("");
    const [VerifiedSpaceBadge, setVerifiedSpaceBadge] = useState("");
    const [CompetitorsAnalysis, setCompetitorsAnalysis] = useState("");
    const [PremiumSpaceBadge, setPremiumSpaceBadge] = useState("");
    const [MultipleTeamUsersAccess, setMultipleTeamUsersAccess] = useState("");
    const [AddressGoogleMaps, setAddressGoogleMaps] = useState("");
    const [WebsiteURL, setWebsiteURL] = useState("");
    const [SocialMediaLinks, setSocialMediaLinks] = useState("");
    const [AdvertisingProfilePage, setAdvertisingProfilePage] = useState("");
    const [AdvertisingNearbySpaces, setAdvertisingNearbySpaces] = useState("");
    const [PhotosAllowed, setPhotosAllowed] = useState("");
    const [IntegratedContactForm, setIntegratedContactForm] = useState("");
    const [ContactNumberAllowed, setContactNumberAllowed] = useState("");
    const [VideosAllowed, setVideosAllowed] = useState("");
    const [VirtualTour, setVirtualTour] = useState("");
    const [DoFollowWebsiteURL, setDoFollowWebsiteURL] = useState("");
    const [DirectWhatsAppMessage, setDirectWhatsAppMessage] = useState("");
    const [RealtimeBookings, setRealtimeBookings] = useState("");
    const [RealtimeSpaceAvailability, setRealtimeSpaceAvailability] = useState("");
    const [SupportTickets, setSupportTickets] = useState("");
    const [PrintScanFacilities, setPrintScanFacilities] = useState("");
    const [VisitorManagemet, setVisitorManagemet] = useState("");
    const [MailPackages, setMailPackages] = useState("");
    const [DefineUserTeamroles, setDefineUserTeamroles] = useState("");
    const [AutomatedBillingInvoices , setAutomatedBillingInvoices ] = useState("");
    const [DetailedAnalytics, setDetailedAnalytics] = useState("");
    const [Membersupport , setMembersupport ] = useState("");
    const [DetailedtrackLeadsMembers, setDetailedtrackLeadsMembers] = useState("");
    const [Trackinventory, setTrackinventory] = useState("");
    const [Newsfeeds, setNewsfeeds] = useState("");
    const [Managedifferentevents, setManagedifferentevents] = useState("");
    const [load, setload] = useState(false);


    const directoryListing = {
        numberOfLocations : numberOfLocations, 
        numberOfLeads : numberOfLeads, 
        basicListing : BasicListing, 
        enhancedListing : EnhancedListing, 
        searchRanking : SearchRanking, 
        visibilityDirectory : VisibilityDirectory, 
        addressVisibility : AddressVisibility, 
        basicReporting : BasicReporting, 
        userReviews : UserReviews, 
        advancedAnalytics : AdvancedAnalytics, 
        verifiedSpaceBadge : VerifiedSpaceBadge, 
        competitorsAnalysis : CompetitorsAnalysis, 
        premiumSpaceBadge : PremiumSpaceBadge, 
        multipleTeamUsersAccess : MultipleTeamUsersAccess, 
    }

    const profileListingPage = {
        addressGoogleMaps : AddressGoogleMaps, 
        websiteURL : WebsiteURL, 
        socialMediaLinks : SocialMediaLinks, 
        advertisingProfilePage : AdvertisingProfilePage, 
        advertisingNearbySpaces : AdvertisingNearbySpaces, 
        photosAllowed : PhotosAllowed, 
        integratedContactForm : IntegratedContactForm, 
        contactNumberAllowed : ContactNumberAllowed, 
        videosAllowed : VideosAllowed, 
        virtualTour : VirtualTour, 
        doFollowWebsiteURL : DoFollowWebsiteURL, 
        directWhatsAppMessage : DirectWhatsAppMessage, 
    }

    const spacesManagerTool = {
        realtimeBookings : RealtimeBookings, 
        realtimeSpaceAvailability : RealtimeSpaceAvailability, 
        supportTickets : SupportTickets, 
        printScanFacilities : PrintScanFacilities, 
        visitorManagemet : VisitorManagemet, 
        mailPackages : MailPackages, 
        defineUserTeamroles : DefineUserTeamroles, 
        automatedBillingInvoices : AutomatedBillingInvoices , 
        detailedAnalytics : DetailedAnalytics, 
        membersupport : Membersupport , 
        detailedtrackLeadsMembers : DetailedtrackLeadsMembers, 
        trackinventory : Trackinventory, 
        newsfeeds : Newsfeeds,
        managedifferentevents : Managedifferentevents
    }


    const saveBranch = async (e) => {
        setload(true)
        e.preventDefault();
        try {
            const payload = {
                name : name,
                description : description, 
                freeTrail : freeTrialDays, 
                monthlyPrice : monthlyPrice, 
                yearlyPrice : yearlyPrice, 
                annualPercentage : yearlySavingsPercentage, 
                directoryListing : directoryListing,
                profileListingPage : profileListingPage,
                spacesManagerTool : spacesManagerTool,
            }
            const payloadEdit = {
              id : packageId,
              name : name,
              description : description, 
              freeTrail : freeTrialDays, 
              monthlyPrice : monthlyPrice, 
              yearlyPrice : yearlyPrice, 
              annualPercentage : yearlySavingsPercentage, 
              directoryListing : directoryListing,
              profileListingPage : profileListingPage,
              spacesManagerTool : spacesManagerTool,
          }
            console.log("Payload:", payload);
            console.log("payloadEdit:", payloadEdit);

          if(editPackage){
            await dispatch(editPackages(payloadEdit)).unwrap(); 
            navigate("/packages")  
            setload(false)       
          } else {  
            await dispatch(addPackages(payload)).unwrap();               
            navigate("/packages") 
            setload(false)
          }
        } catch (rejectedValueOrSerializedError) {
          setload(false)
          console.log(rejectedValueOrSerializedError);
        }
      };

      const Packages = async () => {
        try {
          setload(true);
          setpackageList(null);
          const response = await dispatch(getPackages()).unwrap();
          setpackageList(response?.data);
          setload(false);
         
        } catch (rejectedValueOrSerializedError) {
          setload(false);
          console.log(rejectedValueOrSerializedError);
        }
      };

      const urlParts = window.location.pathname.split('/');
      const packageId = urlParts[urlParts.length - 1];
    
      useEffect(() => {
        if (packageId) {
          Packages();
        }
      }, [])

      useEffect(() => {
        if (packageList) {
          const space = packageList.find(data => data?._id === packageId);
          seteditPackage(space)
        }
      }, [packageList])

      useEffect(() => {
        if (editPackage) {
        setname(editPackage?.name)
         setdescription(editPackage?.description)
         setfreeTrialDays(editPackage?.freeTrail)
         setmonthlyPrice(editPackage?.monthlyPrice)
         setyearlyPrice(editPackage?.yearlyPrice)
         setyearlySavingsPercentage(editPackage?.annualPercentage)
         setnumberOfLocations(editPackage?.directoryListing?.numberOfLocations)
         setnumberOfLeads(editPackage?.directoryListing?.numberOfLeads)
         setBasicListing(editPackage?.directoryListing?.basicListing)
         setEnhancedListing(editPackage?.directoryListing?.enhancedListing)
         setSearchRanking(editPackage?.directoryListing?.searchRanking)
         setVisibilityDirectory(editPackage?.directoryListing?.visibilityDirectory)
         setAddressVisibility(editPackage?.directoryListing?.addressVisibility)
         setBasicReporting(editPackage?.directoryListing?.basicReporting)
         setUserReviews(editPackage?.directoryListing?.userReviews)
         setAdvancedAnalytics(editPackage?.directoryListing?.advancedAnalytics)
         setVerifiedSpaceBadge(editPackage?.directoryListing?.verifiedSpaceBadge)
         setCompetitorsAnalysis(editPackage?.directoryListing?.competitorsAnalysis)
         setPremiumSpaceBadge(editPackage?.directoryListing?.premiumSpaceBadge)
         setMultipleTeamUsersAccess(editPackage?.directoryListing?.multipleTeamUsersAccess)
         setAddressGoogleMaps(editPackage?.profileListingPage?.addressGoogleMaps)
         setWebsiteURL(editPackage?.profileListingPage?.websiteURL)
         setSocialMediaLinks(editPackage?.profileListingPage?.socialMediaLinks)
         setAdvertisingProfilePage(editPackage?.profileListingPage?.advertisingProfilePage)
         setAdvertisingNearbySpaces(editPackage?.profileListingPage?.advertisingNearbySpaces)
         setPhotosAllowed(editPackage?.profileListingPage?.photosAllowed)
         setIntegratedContactForm(editPackage?.profileListingPage?.integratedContactForm)
         setContactNumberAllowed(editPackage?.profileListingPage?.contactNumberAllowed)
         setVideosAllowed(editPackage?.profileListingPage?.videosAllowed)
         setVirtualTour(editPackage?.profileListingPage?.virtualTour)
         setDoFollowWebsiteURL(editPackage?.profileListingPage?.doFollowWebsiteURL)
         setDirectWhatsAppMessage(editPackage?.profileListingPage?.directWhatsAppMessage)
         setRealtimeBookings(editPackage?.spacesManagerTool?.realtimeBookings)
         setRealtimeSpaceAvailability(editPackage?.spacesManagerTool?.realtimeSpaceAvailability)
         setSupportTickets(editPackage?.spacesManagerTool?.supportTickets)
         setPrintScanFacilities(editPackage?.spacesManagerTool?.printScanFacilities)
         setVisitorManagemet(editPackage?.spacesManagerTool?.visitorManagemet)
         setMailPackages(editPackage?.spacesManagerTool?.mailPackages)
         setDefineUserTeamroles(editPackage?.spacesManagerTool?.defineUserTeamroles)
         setAutomatedBillingInvoices (editPackage?.spacesManagerTool?.automatedBillingInvoices)
         setDetailedAnalytics(editPackage?.spacesManagerTool?.detailedAnalytics)
         setMembersupport (editPackage?.spacesManagerTool?.membersupport)
         setDetailedtrackLeadsMembers(editPackage?.spacesManagerTool?.detailedtrackLeadsMembers)
         setTrackinventory(editPackage?.spacesManagerTool?.trackinventory)
         setNewsfeeds(editPackage?.spacesManagerTool?.newsfeeds)
         setManagedifferentevents(editPackage?.spacesManagerTool?.managedifferentevents)
        }
      }, [editPackage]);

      useEffect(() => {
        if (editPackage) {
          document.title = editPackage?.name
        }
      }, [editPackage, location.pathname]);

      useEffect(() => {
        if (numberOfLeads !== 'x' && numberOfLeads !== undefined) {
          setotherLead(true);
        }
      }, [editPackage, numberOfLeads]);

      console.log(editPackage, "editPackage");


  return (
    <>
    {load && <Spinner />}
    <div className="cw-attributes-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cw-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Spaces</a></li>
            <li class="breadcrumb-item active" aria-current="page">{editPackage ? "Edit" : "Add"} Package</li>
          </ol>
        </nav>
        <h2 className="font-size-28 fw-bold mb-3">{editPackage ? "Edit" : "Add"} Package</h2>
        <div className="cw-radius-20 white-bg cw-shadow p-3 mb-4">
          <div className="row gy-3 mb-4">
            <div className="col-md-12">
              <div class="form-floating cw-input">
                <input type="text" maxLength={50} class="form-control" id="package-name" placeholder="package Name" value={name} onChange={(e)=>setname(e.target.value)} />
                <label for="package-name">Package Name</label>
              </div>
            </div>
            <div className="col-md-12">
              <div class="form-floating cw-textarea">
                <textarea class="form-control" placeholder="description" id="description" style={{ height: '200px' }} value={description} onChange={(e)=>setdescription(e.target.value)}></textarea>
                <label for="description">Description</label>
              </div>
            </div>
            <div className="col-md-12">
              <div class="form-floating cw-input">
                <input type="number" maxLength={50} class="form-control" id="Free-Trial-days" placeholder="Free Trial (No. of Days)" value={freeTrialDays} onChange={(e)=>setfreeTrialDays(e.target.value)} />
                <label for="Free-Trial-days">Free Trial (No. of Days)</label>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="number" maxLength={50} class="form-control" id="Monthly-Price" placeholder="Monthly Price" value={monthlyPrice} onChange={(e)=>setmonthlyPrice(e.target.value)} />
                <label for="Monthly-Price">Monthly Price</label>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="number" maxLength={50} class="form-control" id="Yearly-Price" placeholder="Yearly Price" value={yearlyPrice} onChange={(e)=>setyearlyPrice(e.target.value)} />
                <label for="Yearly-Price">Yearly Price</label>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-floating cw-input">
                <input type="number" maxLength={50} class="form-control" id="Savings-Percentage" placeholder="Savings Percentage on Annual Subscription" value={yearlySavingsPercentage} onChange={(e)=>setyearlySavingsPercentage(e.target.value)} />
                <label for="Savings-Percentage">Savings Percentage on Annual Subscription</label>
              </div>
            </div>
        </div>
        <p class="font-size-22 black-color fw-semibold mb-2">Directory Listing</p>
        <div className="row gy-3 mb-4">
            <div className="col-md-6">
              <div class="form-floating cw-input">
                <input type="number" maxLength={50} class="form-control" id="No-Locations" placeholder="No. of Locations" value={numberOfLocations} onChange={(e)=>setnumberOfLocations(e.target.value)} />
                <label for="No-Locations">No. of Locations</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">No. of Leads</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="number-of-leads" type="radio" value="Unlimited" id="Unlimited" checked={numberOfLeads?.includes('x')} onChange={()=>{setnumberOfLeads('x');setotherLead(false)}} />
                <label className="btn cw-select-btn" htmlFor="Unlimited">Unlimited</label>

                <input className="btn-check" name="number-of-leads" type="radio" value="Other" id="Other" checked={otherLead ? false : true} onChange={() => {setnumberOfLeads('');setotherLead(true);}} />
                <label className="btn cw-select-btn" htmlFor="Other">Other</label>
              </div>
              {otherLead && <div class="form-floating cw-input">
                <input type="number" maxLength={50} class="form-control" id="No-Leads" placeholder="No. of Leads" value={numberOfLeads} onChange={(e)=>setnumberOfLeads(e.target.value)} />
                <label for="No-Leads">No. of Leads</label>
              </div>}
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Basic Listing</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Basic-Listing" type="radio" value="Yes" id="Yes-1" checked={BasicListing === 1} onChange={(e)=>setBasicListing(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-1">Yes</label>

                <input className="btn-check" name="Basic-Listing" type="radio" value="No" id="No-1" checked={BasicListing === 0} onChange={(e)=>setBasicListing(0)} />
                <label className="btn cw-select-btn" htmlFor="No-1">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Enhanced Listing</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="enhanced-listing" type="radio" value="Yes" id="Yes-2" checked={EnhancedListing === 1} onChange={(e)=>setEnhancedListing(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-2">Yes</label>

                <input className="btn-check" name="enhanced-listing" type="radio" value="No" id="No-2" checked={EnhancedListing === 0} onChange={(e)=>setEnhancedListing(0)} />
                <label className="btn cw-select-btn" htmlFor="No-2">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Search Ranking</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Search-Ranking" type="radio" value="Below Pioneer and Navigator listings" id="Below-Pioneer-Navigator-listings" checked={SearchRanking === 0} onChange={(e)=>setSearchRanking(0)} />
                <label className="btn cw-select-btn" htmlFor="Below-Pioneer-Navigator-listings">Below Pioneer and Navigator listings</label>

                <input className="btn-check" name="Search-Ranking" type="radio" value="Below Pioneer listings" id="Below-Pioneer-listings" checked={SearchRanking === 1} onChange={(e)=>setSearchRanking(1)} />
                <label className="btn cw-select-btn" htmlFor="Below-Pioneer-listings">Below Pioneer listings</label>

                <input className="btn-check" name="Search-Ranking" type="radio" value="Always at the top" id="Always-at-the-top" checked={SearchRanking === 2} onChange={(e)=>setSearchRanking(2)} />
                <label className="btn cw-select-btn" htmlFor="Always-at-the-top">Always at the top</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Full Name Visibility in Directory</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Full-Name-Visibility-in-Directory" type="radio" value="Yes" id="Yes-3" checked={VisibilityDirectory === 1} onChange={(e)=>setVisibilityDirectory(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-3">Yes</label>

                <input className="btn-check" name="Full-Name-Visibility-in-Directory" type="radio" value="No" id="No-3" checked={VisibilityDirectory === 0} onChange={(e)=>setVisibilityDirectory(0)} />
                <label className="btn cw-select-btn" htmlFor="No-3">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Full Address Visibility</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Full-Address-Visibility" type="radio" value="Yes" id="Yes-4" checked={AddressVisibility === 1} onChange={(e)=>setAddressVisibility(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-4">Yes</label>

                <input className="btn-check" name="Full-Address-Visibility" type="radio" value="No" id="No-4" checked={AddressVisibility === 0} onChange={(e)=>setAddressVisibility(0)} />
                <label className="btn cw-select-btn" htmlFor="No-4">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Basic Reporting</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Basic-Reporting" type="radio" value="Yes" id="Yes-5" checked={BasicReporting === 1} onChange={(e)=>setBasicReporting(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-5">Yes</label>

                <input className="btn-check" name="Basic-Reporting" type="radio" value="No" id="No-5" checked={BasicReporting === 0} onChange={(e)=>setBasicReporting(0)} />
                <label className="btn cw-select-btn" htmlFor="No-5">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">User Reviews</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="User-Reviews" type="radio" value="Yes" id="Yes-6" checked={UserReviews === 1} onChange={(e)=>setUserReviews(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-6">Yes</label>

                <input className="btn-check" name="User-Reviews" type="radio" value="No" id="No-6" checked={UserReviews === 0} onChange={(e)=>setUserReviews(0)} />
                <label className="btn cw-select-btn" htmlFor="No-6">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Advanced Analytics</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Advanced-Analytics" type="radio" value="Yes" id="Yes-7" checked={AdvancedAnalytics === 1} onChange={(e)=>setAdvancedAnalytics(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-7">Yes</label>

                <input className="btn-check" name="Advanced-Analytics" type="radio" value="No" id="No-7" checked={AdvancedAnalytics === 0} onChange={(e)=>setAdvancedAnalytics(0)} />
                <label className="btn cw-select-btn" htmlFor="No-7">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Verified Space Badge</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Verified-Space-Badge" type="radio" value="Yes" id="Yes-8" checked={VerifiedSpaceBadge === 1} onChange={(e)=>setVerifiedSpaceBadge(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-8">Yes</label>

                <input className="btn-check" name="Verified-Space-Badge" type="radio" value="No" id="No-8" checked={VerifiedSpaceBadge === 0} onChange={(e)=>setVerifiedSpaceBadge(0)} />
                <label className="btn cw-select-btn" htmlFor="No-8">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Competitors Analysis</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Competitors-Analysis" type="radio" value="Yes" id="Yes-9" checked={CompetitorsAnalysis === 1} onChange={(e)=>setCompetitorsAnalysis(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-9">Yes</label>

                <input className="btn-check" name="Competitors-Analysis" type="radio" value="No" id="No-9" checked={CompetitorsAnalysis === 0} onChange={(e)=>setCompetitorsAnalysis(0)} />
                <label className="btn cw-select-btn" htmlFor="No-9">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Premium Space Badge</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Premium-Space-Badge" type="radio" value="Yes" id="Yes-10" checked={PremiumSpaceBadge === 1} onChange={(e)=>setPremiumSpaceBadge(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-10">Yes</label>

                <input className="btn-check" name="Premium-Space-Badge" type="radio" value="No" id="No-10" checked={PremiumSpaceBadge === 0} onChange={(e)=>setPremiumSpaceBadge(0)} />
                <label className="btn cw-select-btn" htmlFor="No-10">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Multiple Team Users Access</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Multiple-Team-Users-Access" type="radio" value="Yes" id="Yes-12" checked={MultipleTeamUsersAccess === 1} onChange={(e)=>setMultipleTeamUsersAccess(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-12">Yes</label>

                <input className="btn-check" name="Multiple-Team-Users-Access" type="radio" value="No" id="No-12" checked={MultipleTeamUsersAccess === 0} onChange={(e)=>setMultipleTeamUsersAccess(0)} />
                <label className="btn cw-select-btn" htmlFor="No-12">No</label>
              </div>
            </div>
            
        </div>

        <p class="font-size-22 black-color fw-semibold mb-2">Profile Listing Page</p>
        <div className="row gy-3 mb-4">
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Full Address with Integrated Google Maps</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Full-Address-Integrated-Google-Maps" type="radio" value="Yes" id="Yes-13" checked={AddressGoogleMaps === 1} onChange={(e)=>setAddressGoogleMaps(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-13">Yes</label>

                <input className="btn-check" name="Full-Address-Integrated-Google-Maps" type="radio" value="No" id="No-13" checked={AddressGoogleMaps === 0} onChange={(e)=>setAddressGoogleMaps(0)} />
                <label className="btn cw-select-btn" htmlFor="No-13">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Website URL</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Website-URL" type="radio" value="Yes" id="Yes-14" checked={WebsiteURL === 1} onChange={(e)=>setWebsiteURL(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-14">Yes</label>

                <input className="btn-check" name="Website-URL" type="radio" value="No" id="No-14" checked={WebsiteURL === 0} onChange={(e)=>setWebsiteURL(0)} />
                <label className="btn cw-select-btn" htmlFor="No-14">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Social Media Links</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Social-Media-Links" type="radio" value="Yes" id="Yes-15" checked={SocialMediaLinks === 1} onChange={(e)=>setSocialMediaLinks(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-15">Yes</label>

                <input className="btn-check" name="Social-Media-Links" type="radio" value="No" id="No-15" checked={SocialMediaLinks === 0} onChange={(e)=>setSocialMediaLinks(0)} />
                <label className="btn cw-select-btn" htmlFor="No-15">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">No Advertising on Profile Page</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="No-Advertising-Profile-Page" type="radio" value="Yes" id="Yes-16" checked={AdvertisingProfilePage === 1} onChange={(e)=>setAdvertisingProfilePage(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-16">Yes</label>

                <input className="btn-check" name="No-Advertising-Profile-Page" type="radio" value="No" id="No-16" checked={AdvertisingProfilePage === 0} onChange={(e)=>setAdvertisingProfilePage(0)} />
                <label className="btn cw-select-btn" htmlFor="No-16">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">No Advertising of Nearby CoWorking Spaces</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="No-Advertising-Nearby-CoWorking-Spaces" type="radio" value="Yes" id="Yes-17" checked={AdvertisingNearbySpaces === 1} onChange={(e)=>setAdvertisingNearbySpaces(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-17">Yes</label>

                <input className="btn-check" name="No-Advertising-Nearby-CoWorking-Spaces" type="radio" value="No" id="No-17" checked={AdvertisingNearbySpaces === 0} onChange={(e)=>setAdvertisingNearbySpaces(0)} />
                <label className="btn cw-select-btn" htmlFor="No-17">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Photos</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Photos" type="radio" value="Yes" id="Yes-18" checked={PhotosAllowed === 1} onChange={(e)=>setPhotosAllowed(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-18">Yes</label>

                <input className="btn-check" name="Photos" type="radio" value="No" id="No-18" checked={PhotosAllowed === 0} onChange={(e)=>setPhotosAllowed(0)} />
                <label className="btn cw-select-btn" htmlFor="No-18">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Integrated Contact Form</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Integrated-Contact-Form" type="radio" value="Yes" id="Yes-19" checked={IntegratedContactForm === 1} onChange={(e)=>setIntegratedContactForm(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-19">Yes</label>

                <input className="btn-check" name="Integrated-Contact-Form" type="radio" value="No" id="No-19" checked={IntegratedContactForm === 0} onChange={(e)=>setIntegratedContactForm(0)} />
                <label className="btn cw-select-btn" htmlFor="No-19">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Contact Number</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Contact-Number" type="radio" value="Yes" id="Yes-20" checked={ContactNumberAllowed === 1} onChange={(e)=>setContactNumberAllowed(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-20">Yes</label>

                <input className="btn-check" name="Contact-Number" type="radio" value="No" id="No-20" checked={ContactNumberAllowed === 0} onChange={(e)=>setContactNumberAllowed(0)} />
                <label className="btn cw-select-btn" htmlFor="No-20">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Videos</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Videos" type="radio" value="Yes" id="Yes-21" checked={VideosAllowed === 1} onChange={(e)=>setVideosAllowed(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-21">Yes</label>

                <input className="btn-check" name="Videos" type="radio" value="No" id="No-21" checked={VideosAllowed === 0} onChange={(e)=>setVideosAllowed(0)} />
                <label className="btn cw-select-btn" htmlFor="No-21">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">360 Degree Virtual Tour</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="360-Degree-Virtual-Tour" type="radio" value="Yes" id="Yes-22" checked={VirtualTour === 1} onChange={(e)=>setVirtualTour(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-22">Yes</label>

                <input className="btn-check" name="360-Degree-Virtual-Tour" type="radio" value="No" id="No-22" checked={VirtualTour === 0} onChange={(e)=>setVirtualTour(0)} />
                <label className="btn cw-select-btn" htmlFor="No-22">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Do-Follow Website URL</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Do-Follow-Website-URL" type="radio" value="Yes" id="Yes-23" checked={DoFollowWebsiteURL === 1} onChange={(e)=>setDoFollowWebsiteURL(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-23">Yes</label>

                <input className="btn-check" name="Do-Follow-Website-URL" type="radio" value="No" id="No-23" checked={DoFollowWebsiteURL === 0} onChange={(e)=>setDoFollowWebsiteURL(0)} />
                <label className="btn cw-select-btn" htmlFor="No-23">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Direct WhatsApp Message</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Direct-WhatsApp-Message" type="radio" value="Yes" id="Yes-24" checked={DirectWhatsAppMessage === 1} onChange={(e)=>setDirectWhatsAppMessage(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-24">Yes</label>

                <input className="btn-check" name="Direct-WhatsApp-Message" type="radio" value="No" id="No-24" checked={DirectWhatsAppMessage === 0} onChange={(e)=>setDirectWhatsAppMessage(0)} />
                <label className="btn cw-select-btn" htmlFor="No-24">No</label>
              </div>
            </div>
            
        </div>

        <p class="font-size-22 black-color fw-semibold mb-2">CoWorking Space Management Tool (Spaces Manager)</p>
        <div className="row gy-3 mb-3">
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Real-time Bookings</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Real-time-Bookings" type="radio" value="Yes" id="Yes-25" checked={RealtimeBookings === 1} onChange={(e)=>setRealtimeBookings(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-25">Yes</label>

                <input className="btn-check" name="Real-time-Bookings" type="radio" value="No" id="No-25" checked={RealtimeBookings === 0} onChange={(e)=>setRealtimeBookings(0)} />
                <label className="btn cw-select-btn" htmlFor="No-25">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Real-time Space Availability </p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Real-time-Space-Availability" type="radio" value="Yes" id="Yes-26" checked={RealtimeSpaceAvailability === 1} onChange={(e)=>setRealtimeSpaceAvailability(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-26">Yes</label>

                <input className="btn-check" name="Real-time-Space-Availability" type="radio" value="No" id="No-26" checked={RealtimeSpaceAvailability === 0} onChange={(e)=>setRealtimeSpaceAvailability(0)} />
                <label className="btn cw-select-btn" htmlFor="No-26">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Support Tickets</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Support-Tickets" type="radio" value="Yes" id="Yes-27" checked={SupportTickets === 1} onChange={(e)=>setSupportTickets(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-27">Yes</label>

                <input className="btn-check" name="Support-Tickets" type="radio" value="No" id="No-27" checked={SupportTickets === 0} onChange={(e)=>setSupportTickets(0)} />
                <label className="btn cw-select-btn" htmlFor="No-27">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Print and Scan Facilities</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Print-Scan-Facilities" type="radio" value="Yes" id="Yes-28" checked={PrintScanFacilities === 1} onChange={(e)=>setPrintScanFacilities(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-28">Yes</label>

                <input className="btn-check" name="Print-Scan-Facilities" type="radio" value="No" id="No-28" checked={PrintScanFacilities === 0} onChange={(e)=>setPrintScanFacilities(0)} />
                <label className="btn cw-select-btn" htmlFor="No-28">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Visitor Managemet</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Visitor-Managemet" type="radio" value="Yes" id="Yes-29" checked={VisitorManagemet === 1} onChange={(e)=>setVisitorManagemet(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-29">Yes</label>

                <input className="btn-check" name="Visitor-Managemet" type="radio" value="No" id="No-29" checked={VisitorManagemet === 0} onChange={(e)=>setVisitorManagemet(0)} />
                <label className="btn cw-select-btn" htmlFor="No-29">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Mail and Packages booking, forwarding, delivery</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Mail-Packages-booking-forwarding-delivery" type="radio" value="Yes" id="Yes-30" checked={MailPackages === 1} onChange={(e)=>setMailPackages(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-30">Yes</label>

                <input className="btn-check" name="Mail-Packages-booking-forwarding-delivery" type="radio" value="No" id="No-30" checked={MailPackages === 0} onChange={(e)=>setMailPackages(0)} />
                <label className="btn cw-select-btn" htmlFor="No-30">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Define User/Team roles and restrict accessibilty</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Define-User-Team-roles-restrict-accessibilty" type="radio" value="Yes" id="Yes-31" checked={DefineUserTeamroles === 1} onChange={(e)=>setDefineUserTeamroles(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-31">Yes</label>

                <input className="btn-check" name="Define-User-Team-roles-restrict-accessibilty" type="radio" value="No" id="No-31" checked={DefineUserTeamroles === 0} onChange={(e)=>setDefineUserTeamroles(0)} />
                <label className="btn cw-select-btn" htmlFor="No-31">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Automated Billing and Invoices </p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Automated-Billing-Invoices" type="radio" value="Yes" id="Yes-32" checked={AutomatedBillingInvoices === 1} onChange={(e)=>setAutomatedBillingInvoices(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-32">Yes</label>

                <input className="btn-check" name="Automated-Billing-Invoices" type="radio" value="No" id="No-32" checked={AutomatedBillingInvoices === 0} onChange={(e)=>setAutomatedBillingInvoices(0)} />
                <label className="btn cw-select-btn" htmlFor="No-32">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Detailed Analytics</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Detailed-Analytics" type="radio" value="Yes" id="Yes-33" checked={DetailedAnalytics === 1} onChange={(e)=>setDetailedAnalytics(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-33">Yes</label>

                <input className="btn-check" name="Detailed-Analytics" type="radio" value="No" id="No-33" checked={DetailedAnalytics === 0} onChange={(e)=>setDetailedAnalytics(0)} />
                <label className="btn cw-select-btn" htmlFor="No-33">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Different categories on Member support </p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Different-categories-Member-support" type="radio" value="Yes" id="Yes-34" checked={Membersupport === 1} onChange={(e)=>setMembersupport(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-34">Yes</label>

                <input className="btn-check" name="Different-categories-Member-support" type="radio" value="No" id="No-34" checked={Membersupport === 0} onChange={(e)=>setMembersupport(0)} />
                <label className="btn cw-select-btn" htmlFor="No-34">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Detailed track on Leads, Members and Past Members</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Detailed-track-on-Leads-Members-Past-Members" type="radio" value="Yes" id="Yes-35" checked={DetailedtrackLeadsMembers === 1} onChange={(e)=>setDetailedtrackLeadsMembers(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-35">Yes</label>

                <input className="btn-check" name="Detailed-track-on-Leads-Members-Past-Members" type="radio" value="No" id="No-35" checked={DetailedtrackLeadsMembers === 0} onChange={(e)=>setDetailedtrackLeadsMembers(0)} />
                <label className="btn cw-select-btn" htmlFor="No-35">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Track inventory</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Track-inventory" type="radio" value="Yes" id="Yes-36" checked={Trackinventory === 1} onChange={(e)=>setTrackinventory(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-36">Yes</label>

                <input className="btn-check" name="Track-inventory" type="radio" value="No" id="No-36" checked={Trackinventory === 0} onChange={(e)=>setTrackinventory(0)} />
                <label className="btn cw-select-btn" htmlFor="No-36">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Update members on News feeds and announcements on selected locations</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Update-members-News-feeds-announcements-on-selected-locations" type="radio" value="Yes" id="Yes-37" checked={Newsfeeds === 1} onChange={(e)=>setNewsfeeds(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-37">Yes</label>

                <input className="btn-check" name="Update-members-News-feeds-announcements-on-selected-locations" type="radio" value="No" id="No-37" checked={Newsfeeds === 0} onChange={(e)=>setNewsfeeds(0)} />
                <label className="btn cw-select-btn" htmlFor="No-37">No</label>
              </div>
            </div>
            <div className="col-md-6">
            <p className="font-size-16 black-color mb-2">Manage different events and notify members</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                <input className="btn-check" name="Manage-different-events-notify-members" type="radio" value="Yes" id="Yes-38" checked={Managedifferentevents === 1} onChange={(e)=>setManagedifferentevents(1)} />
                <label className="btn cw-select-btn" htmlFor="Yes-38">Yes</label>

                <input className="btn-check" name="Manage-different-events-notify-members" type="radio" value="No" id="No-38" checked={Managedifferentevents === 0} onChange={(e)=>setManagedifferentevents(0)} />
                <label className="btn cw-select-btn" htmlFor="No-38">No</label>
              </div>
            </div>
            
        </div>
        </div>
        <div class="d-flex justify-content-center gap-2">
          <button onClick={() => navigate("/packages")} class="btn cw-btn cw-black-outline-btn py-2 px-4">Cancel</button>
          <button onClick={saveBranch} class="btn cw-btn cw-yellow-btn py-2 px-4" disabled={load}>Submit</button>
        </div>
    </div>
    </>
  )
}

export default AddPackage